export default {
  'imageAlt': 'Garden Affairs',
  // 全局error Code Map
  'errorCode': {
    'default': 'サーバーエラーが発生しました。後でもう一度お試しください。', // 默认值：接口请求错误
    20014: 'ユーザーIDが存在していません。再入力を行なってください', //  游戏用户ID错误
    10002: 'サーバーエラーが発生しました。後でもう一度お試しください。(10002)',// 参数无效
    10008: 'サーバーエラーが発生しました。後でもう一度お試しください。(10008)', // 数据未找到
    30008: 'サーバーエラーが発生しました。後でもう一度お試しください。(30008)', // 商品不存在
    30007: 'サーバーエラーが発生しました。後でもう一度お試しください。(30007)', // 商品币种类型不正确
    10001: 'サーバーエラーが発生しました。後でもう一度お試しください。(10001)', // 服务器开小差
    10000: 'ネットワークエラーです。ネットワーク設定を確認してください。', // 网络异常"
  },
  // code map 下单时 映射弹窗 内容
  'codeMap': {
    // 成功弹窗
    'SuccessTitle': '購入しました！',
    'SuccessInfo': 'ご購入ありがとうございます！<br/>ゲームを再起動し、メールボックスを確認してアイテムを受け取ってください。<br/>(画面右上→メールボックス)',
    'SuccessBtn': 'GREAT',
    // 失败弹窗
    'ErrorTitle': '購入失敗！',
    'ErrorInfo': '購入に失敗しました！<br/>もう一度お試しください',
    'ErrorBtn': '確認'
  },
  // 登录弹窗
  'login': {
    'title1': "アリスのショップ",
    'title2': "ゲーム",
    'Or': 'もしくは',
    'placeholder': 'ユーザーIDを入力してください',
    'LogIn': 'ログイン',
    'SignInWithFacebook': 'Facebookでサインインする',
    'SignInWithGoogle': 'Googleでログインする',
    'LoginSuccess': 'ログインに成功しました。お買い物を始めてください！',
    'LoginFailed': 'ログインに失敗しました！入力したユーザーIDが正しいか確認してください。',
    'LogOut': 'ログアウト成功！',
  },
  // cookies tips
  'cookiesTip': {
    'Content': 'ゲームアカウントと連携したGoogleアカウントを使用して認証してください。',
    'Accept': '認可'
  },
  // header部分
  'header': {
    'Menu': ['店舗', 'ゲーム', 'サポート', 'フォローする'],
    'MenuPath': ['/store','/games', 'Support', '/follow-us'],
    'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
  },
  // 顶部 footer
  'footer': {
    'Menu': ['サポート', '利用規約', 'プライバシーポリシー', 'データ削除'],
    'MenuPath': [
      'Support',
      'https://policy.judiangames.com/termsofservice-jp.html',
      'https://policy.judiangames.com/privacypolicy-jp.html',
      '/deletion'
    ],
    'LanguagePlaceholder': '言語',
  },
  // homePage
  'homePage': {
    'txt': [
      '秘密を解き明かし、夢をデザインし、冒険を始めよう！',
      '何百万人ものプレイヤーを魅了したマッチ3パズルゲーム、<br/>ガーデンアフェアにようこそ！',
    ],
    'GardenAffairs': [
      'ガ一デンアフェア',
      '無料のパズルゲーム「ガーデンアフェアーズ」<br/>で何百万人ものプレイヤーと一緒に楽しもう！<br/>アリスと共に、夢の生活を作りましょう！'
    ],
    'Custom': [
      'ガーデンアフェアーズには、次のようなエキサイティングな新機能が満載です：',
      'あなたのスタイルを表現',
      '自分のスタイルを反映した家具や装飾品を選ぼう。<br/>家の隅々までカスタマイズして、古い屋敷を見事に改装しよう！',
      '興味深い物語',
      '庭に隠された秘密を探りましょう。<br/>ストーリーの展開をすべて追いましょう。新しい友達を作りましょう。',
      '楽しいレベル',
      'ユニークなブースター、ゲーム要素、爆発的な組み合わせでチャレンジングなレベルをマスターしよう。<br/>パズルを解くスリルとマッチの充足感がたまらない！'
    ],
    'GamePictures': 'ゲーム画像'
  },
  'homePageMobile': {
    'txt': [
      '秘密を解き明かし、<br/>夢をデザインし、<br/>冒険を始めよう！',
      '何百万人ものプレイヤー<br/>を魅了したマッチ3パズルゲーム、<br/>ガーデンアフェアにようこそ！',
    ],
    'GardenAffairs': [
      'ガ一デンアフェア',
      '無料のパズルゲーム「ガーデンアフェアーズ」<br/>で何百万人ものプレイヤーと一緒に楽しもう！<br/>アリスと共に、夢の生活を作りましょう！'
    ],
    'Custom':[
      'ガーデンアフェアーズには、<br/>次のようなエキサイティングな新機能が満載です：',
      'あなたのスタイルを表現',
      '自分のスタイルを反映した家具や装飾品を選ぼう。家の隅<br/>々までカスタマイズして、古い屋敷を見事に改装しよう！',
      '興味深い物語',
      '庭に隠された秘密を探りましょう。<br/>ストーリーの展開をすべて追いましょう。<br/>新しい友達を作りましょう。',
      '楽しいレベル',
      'ユニークなブースター、ゲーム要素、爆発的な組み合<br/>わせでチャレンジングなレベルをマスターしよう。<br/>パズルを解くスリルとマッチの充足感がたまらない！'
    ],
    'GamePictures': 'ゲーム画像'
  },
  // GAME页 pc端
  'games': {
    'GardenAffairs': 'games/logo.png',
    'txt1': '秘密を解き明かし、夢をデザインし、<br/>冒険を始めよう！',
    'extra': '追加 5%',
    'store': '店舗',
    'PeopleIcon': 'games/icon.png',
    'txt2': '何百万人ものプレイヤーを魅了<br/>したマッチ3パズルゲーム、<br/>ガーデンアフェアにようこそ！',
    'txt3': '幼なじみで有名なインテリアデザイナー・アリスに、' +
      '<br/>夢のマイホームの改装を依頼。献身的な努力と創造力、' +
      '<br/>そしてあなたのデザインに関する専門知識を駆使して、' +
      '<br/>息をのむような夢の家へ生まれ変わるその瞬間を見届けよう！',
    'txt4': [
      'マッチビューの核心に迫る',
      'マッチビューに活気を与えている地元の人たちと知り合いになろう：'
    ],
    'PeopleName': ['アリス', 'ジョーイ', 'ステファノ', 'ネリー', 'テイラー', 'アラン'],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people2.png',
      'games/people3.png',
      'games/people4.png',
      'games/people5.png',
      'games/people6.png',
    ],
    'PeopleDesc': [
      '夢の住まいを実現<br/>するインテリアデザイナー',
      '花に情熱を注ぐ魅力的<br/>なフローリスト',
      '食材を生まれ変わらせる天才シェフ',
      '思いやりの心を持<br/>ったセレブなデザイナー',
      '町一番の大工であり、<br/>最高の友人',
      '屋敷の歴史をよく知る熟練の庭師'
    ],
    'txt5': [
      '見事なビジュアル',
      'デザインの細部に至るまで精密な設計が行われていることが一目でわかる、見事なオープニング動画を見よう。'
    ],
    'txt6': [
      'あなたのスタイルを表現',
      '自分のスタイルを反映した家具や装飾品を選ぼう。' +
      '<br/>家の隅々までカスタマイズして、古い屋敷を見事に改装しよう！',
      '楽しいレベル',
      'ユニークなブースター、ゲーム要素、爆発的な組み合わせでチャレンジングなレベルをマスターしよう。' +
      '<br/>パズルを解くスリルとマッチの充足感がたまらない！'
    ],
    'txt7': [
      '発見、<br/>そしてつながる',
      '広大な庭園に隠された謎を解き明かそう。' +
      '<br/>近隣の人々との交流を深め、' +
      '<br/>屋敷の壁を越えた永遠の友情を築こう！'
    ],
    'txt8': 'ゲーム画像',
    'SwiperList': [
      'games/ja/wutu_1.jpg',
      'games/ja/wutu_2.jpg',
      'games/ja/wutu_3.jpg',
      'games/ja/wutu_4.jpg',
      'games/ja/wutu_5.jpg',
      'games/ja/wutu_6.jpg',
      'games/ja/wutu_7.jpg',
      'games/ja/wutu_8.jpg',
      'games/ja/wutu_9.jpg',
      'games/ja/wutu_10.jpg',
    ],
    'txt9': 'SNSをぜひチェックしてくださいね',
    'txt10': '今すぐダウンロード！',
  },
  // GAME页 移动端
  'gamesMobile': {
    'GardenAffairs': 'games/logo.png',
    'txt1': '秘密を解き明かし、夢をデザインし、<br/>冒険を始めよう！',
    'extra': '追加 5%',
    'store': '店舗',
    'PeopleIcon': 'games/icon.png',
    'txt2': '何百万人ものプレイヤーを魅了' +
      '<br/>したマッチ3パズルゲーム、' +
      '<br/>ガーデンアフェアにようこそ！',
    'txt3': '幼なじみで有名なインテリアデザイナー・アリスに、' +
      '<br/>夢のマイホームの改装を依頼。' +
      '<br/>献身的な努力と創造力、' +
      '<br/>そしてあなたのデザインに' +
      '<br/>関する専門知識を駆使して、' +
      '<br/>息をのむような夢の家' +
      '<br/>へ生まれ変わるその瞬間を見届けよう！',
    'txt4': [
      'マッチビューの核心に迫る',
      'マッチビューに活気<br/>を与えている地元の人たちと知り合いになろう：'
    ],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people4.png',
      'games/people2.png',
      'games/people5.png',
      'games/people3.png',
      'games/people6.png',
    ],
    'PeopleName': [
      'アリス',
      'ネリー',
      'ジョーイ',
      'テイラー',
      'ステファノ',
      'アラン'
    ],
    'PeopleDesc': [
      '夢の住まいを実現するインテリアデザイナー',
      '思いやりの心を持ったセレブなデザイナー',
      '花に情熱を注ぐ魅力的なフローリスト',
      '町一番の大工であり、<br/>最高の友人',
      '食材を生まれ変わらせる天才シェフ',
      '屋敷の歴史をよく知る熟練の庭師'
    ],
    'txt5': [
      '見事なビジュアル',
      'デザインの細部に至るまで精密な' +
      '<br/>設計が行われていることが一目でわかる、' +
      '<br/>見事なオープニング動画を見よう。'
    ],
    'txt6': [
      'あなたのスタイルを表現',
      '自分のスタイルを反映した家具や装飾品を選ぼう。' +
      '<br/>家の隅々までカスタマイズして、' +
      '<br/>古い屋敷を見事に改装しよう！',
      '楽しいレベル',
      'ユニークなブースター、ゲーム要素、' +
      '<br/>爆発的な組み合わせでチャレンジングな' +
      '<br/>レベルをマスターしよう。' +
      '<br/>パズルを解くスリルとマッチの充足感がたまらない！'
    ],
    'txt7': [
      '発見、そしてつながる',
      '広大な庭園に隠された謎を解き明かそう。' +
      '<br/>近隣の人々との交流を深め、' +
      '<br/>屋敷の壁を越えた永遠の友情を築こう！'
    ],
    'txt8': 'ゲーム画像',
    'SwiperList': [
      'games/ja/wutu_1.jpg',
      'games/ja/wutu_2.jpg',
      'games/ja/wutu_3.jpg',
      'games/ja/wutu_4.jpg',
      'games/ja/wutu_5.jpg',
      'games/ja/wutu_6.jpg',
      'games/ja/wutu_7.jpg',
      'games/ja/wutu_8.jpg',
      'games/ja/wutu_9.jpg',
      'games/ja/wutu_10.jpg'
    ],
    'txt9': 'SNSをぜひ<br/>チェックしてくださいね',
    'txt10': '今すぐダウンロード！',
  },
  // 商品列表
  'product': {
    'Coins': 'コイン',
    'STORE': '店舗',
    'Extra': '追加',
    'Title1': 'あなたより<br/>贈り物がふさわしいのは？',
    'Title2': 'この素晴らしいパッケージをゲットして、<br/>無限の楽しみと無敵の力を手に入れよう！',
    'LOGIN': 'ログイン',
    'LOGOUT': 'ログアウト',
    'UID': 'UID',
    'ShowDetails': '詳細',
    'PaymentMethods': '支払い方法',
    'MyAccount': 'アカウント',
    'UserID': 'UserID',
    'PurchaseProduct': '商品の購入',
    'Price': '価格',
    'total': '合計',
    'BuyNow': '今すぐゲット',
    'ItemInBundle': 'バンドルアイテム',
    'PleaseSelectProduct': '購入したいアイテムを選択してください。',
    'PleaseSelectPay': 'お支払い方法を選択してください。'
  },
  'productMobile': {
    'STORE': '店舗',
    'Extra': '追加',
    'Title1': 'あなたより<br/>贈り物がふさわしいのは？',
    'Title2': 'この素晴らしいパッ<br/>ケージをゲットして、<br/>無限の楽しみと<br/>無敵の力を手に入れよう！',
    'LOGIN': 'ログイン',
    'LOGOUT': 'ログアウト',
    'UID': 'UID',
    'ShowDetails': '詳細',
    'PaymentMethods': '支払い方法',
    'BuyPopTitle': '支払い注文を確認する',
    'MyAccount': 'アカウント',
    'UserID': 'UserID',
    'PurchaseProduct': '商品の購入',
    'Price': '価格',
    'total': '合計',
    'BuyNow': '今すぐゲット',
    'ItemInBundle': 'バンドルアイテム',
    'PleaseSelectProduct': '購入したいアイテムを選択してください。',
    'PleaseSelectPay': 'お支払い方法を選択してください。'
  },
  // deletion 页
  'deletion': {
    'Title': 'ゲームアカウント削除',
    'Logout': 'ログアウト',
    'Content': 'ゲームアカウントと連携したGoogleアカウントを使用して認証してください。',
    'AUTHORIZE': '認可',
  },
  'deletionMobile': {
    'Title': 'ゲームアカウント削除',
    'Logout': 'ログアウト',
    'Content': 'ゲームアカウントと連携したGoogleアカウントを<br/>使用して認証してください。',
    'AUTHORIZE': '認可',
  },
  // authSuccess 页
  'authSuccess': {
    'content': [
      '一般データ保護規則（General Data Protection Regulation）に基づき、お客様は、当社のアプリケーションおよびパートナーのサービス（購入決済、アプリ内広告の閲覧など）で使用されたお客様の個人データをすべて削除する権利を有します。データの削除を行う場合、ユーザーアカウントは削除され、復元できないことにご留意ください。ただし、レベル1からゲームをやり直すことは可能です。',
      'アカウントの削除は、15日以内であればキャンセルできます。',
      '削除をキャンセルするには、ゲームを開き、キャンセルボタンを押してください。15日以上経過した場合、削除されたデータを元に戻すことはできませんのでご注意ください。また、削除申請中の15日間は、ゲームを最初から再開することはできません。'
    ],
    'DeleteGameAccount': 'ゲームアカウント削除',
    'DelTitle': '確認',
    'DelContent': '本当にゲームアカウントを削除しますか？',
    'DelCancel': 'キャンセル',
    'DelConfirm': 'はい',
    'DelCodeLabel': [
      'このユーザーのゲームアカウントが見つかりません。',
      '成功! ',
      'ゲームアカウントは削除のために提出されました。'
    ]
  },
  // Del Success 页
  'delSuccess': [
    'データ削除申請を行いました。',
    'ゲームアカウントは、申請から15日以内に削除されます。'
  ]
}
