import axios from 'axios';
import {ElMessage} from 'element-plus';
import {removePending, addPending} from '@/utils/pending';

import {Decrypt} from "@/tools/crypto/index.js";

const baseURL = import.meta.env.VITE_API_BASE_URL;
// 创建一个Axios实例
const service = axios.create({
  baseURL,
  timeout: 500000,
})

service.interceptors.request.use(
  config => {
    removePending(config);
    addPending(config);

    const appId = config?.AppId;
    if (appId) config.headers['app-id'] = appId;

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// const isDecrypt = storage.getItem('switchBtn');

service.interceptors.response.use(
  response => {
    removePending(response);
    const isDecrypt = response.config.headers.isDecrypt;
    if (response.status === 200) {
      const data = response.data;
      return isDecrypt === 'true' ? Decrypt(data) : data;
    } else {
      Promise.reject(new Error(response.statusText));
    }
  },
  (error) => {
    if (error.response) {
      const data = error.response.data;
      // const errorMessage = i18n.global.t(`errorCode.${data?.code}`, i18n.global.t('errorCode.default'));
      if (data) {
        ElMessage.error({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<p>StatusCode：${error.response.status}</p>
                    <p>Error：${data.error}</p>
                    <p>ErrorDescription：${data.error_description}</p>`
        });
      } else {
        ElMessage.error({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<p>Code：${error.code}</p>
                    <p>${error.name}：${error.message}</p>`
        });
      }
    }
    return Promise.reject(error);
  }
);

export default service;
