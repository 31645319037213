import { Encrypt } from '@/tools/crypto/index.js';
import storage from '@/utils/storage.js';
import { http } from "@/api/http/index.js";

const isDecrypt = storage.getItem('switchBtn');
let headers = {'Content-Type': 'application/json', isDecrypt}
if (!isDecrypt) {
    headers.testgg = 'testgg'
}

// 获取国家地区信息
export const getCountryApi = (data) => {
    return http({
        url: `/account-service/v1/get-country`,
        method: 'post',
        data,
    })
}

// 登录接口
export const loginApi = (data) => {
    return http({
        url: `/billing-service/v1/userid/login`,
        method: 'post',
        data: isDecrypt ? Encrypt(JSON.stringify(data)) : data,
        headers
    })
}

// 查询官网下载链接
export const downloadLinkApi = (data) => {
    return http({
        url: `/billing-service/v1/webiste/link/list`,
        method: 'post',
        data: isDecrypt ? Encrypt(JSON.stringify(data)) : data,
        headers
    })
}

// google 授权
export const getGoogleToken = (data) => {
    return http({
        url: `https://oauth2.googleapis.com/token?${data}`,
        method: 'post',
        noAppId: true,
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded',
            isDecrypt: false
        },
    })
}

// 获取google用户信息
export const getGoogleUserInfo = (access_token) => {
    return http({
        url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        method: 'get',
        noAppId: true,
        headers: {
            isDecrypt: false
        }
    })
}

// google Facebook 登录
// sdk_type：facebook google
export const authLoginApi = (data) => {
    return http({
        url: '/billing-service/v1/webiste/auth/userinfo',
        method: 'post',
        data: isDecrypt ? Encrypt(JSON.stringify(data)) : data,
        headers
    })
}

