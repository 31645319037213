import {defineStore} from 'pinia';
import {downloadLinkApi, loginApi, authLoginApi, getCountryApi} from '@/api/user';
import {setToken} from '@/utils/tokenCookies'
import Cookies from 'js-cookie';
import storage from '@/utils/storage';
import countryMap from '@/lang/countryMap.js'
import languageMap from "@/lang/languageMap.js";

const useUserStore = defineStore('user', {
    state: () => {
        return {
            country: '',
            language: Cookies.get('language') || '',
            token: '',
            user_info: {
                role_id: '',
                user_name: '',
                picture: '',
            },
            downloadLink: [],
        };
    },
    actions: {
        // 设置国家
        setCountry(data) {
            this.country = data || '';
            Cookies.set('country', data);
        },
        // 设置语言
        setLanguage(data) {
            if (data === 'ar') {
                document.body.style.direction = 'rtl'
            } else {
                document.body.style.direction = ''
            }
            this.language = data;
        },
        // 获取国家
        async getCountry() {
            const That = this
            return new Promise((resolve, reject) => {
                getCountryApi().then(async res => {
                    // console.log(res);
                    // res.data.country_code = ''
                    const {data} = res;
                    That.setCountry(data?.country_code === 'HK' ? 'CN' : data?.country_code);
                    resolve(res);
                }).catch(error => {
                    That.setCountry('');
                    reject(error);
                }).finally(() => {
                    // cookies 语言
                    const cookiesLanguage = Cookies.get('language');
                    // 本地浏览器语言 语言-国家 en-us
                    let browserLanguage = (navigator.language || navigator.browserLanguage).toLowerCase()?.split('-')?.[0];
                    // console.log(browserLanguage, '--browserLanguage');
                    if (browserLanguage === 'zh') browserLanguage = 'zh-Hans';

                    let languageArr = languageMap.map(item => item.value);

                    const countryValue = languageArr.includes(countryMap[That.country]) ? countryMap[That.country] : ''
                    const browserValue = languageArr.includes(browserLanguage) ? browserLanguage : ''
                    const value = cookiesLanguage // 缓存
                        || countryValue // 从接口拿国家语言
                        || browserValue // 浏览器语言
                        || 'en'; // 默认语言
                    That.setLanguage(value)

                    console.log('------接口返回country code:', That.country);
                    console.log('counrty code 匹配国家语言码:', countryMap[That.country]);
                    console.log('---本地浏览器语言截取出的国家:', browserLanguage);
                    console.log('---------------得到的语言码:', value);
                    resolve();
                })
            })
        },
        // 登录接口
        async authLogin(data) {
            const That = this
            return new Promise((resolve, reject) => {
                authLoginApi(data).then(async res => {
                    const data = res.data;
                    That.setUserInfo(data);
                    await setToken(JSON.stringify(That.user_info), {
                        expires: 1
                    })
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        // 登录接口
        async loginOn(data) {
            const That = this
            return new Promise((resolve, reject) => {
                loginApi(data).then(async res => {
                    const data = res.data;
                    That.setUserInfo(data);
                    await setToken(JSON.stringify(That.user_info), {
                        expires: 1
                    })
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        // 退出登录
        async logOff() {
            return new Promise((resolve, reject) => {
                const That = this;
                if (Cookies.get('login_type') === 'facebook') {
                    FB.getLoginStatus(function (response) {
                        if (response.status === 'connected') {
                            FB.logout(function (response) {
                                console.log(response, '--response logout');
                                That.resetToken()
                                resolve({code: 0})
                            })
                        }
                    })
                } else {
                    this.resetToken()
                    resolve({code: 0})
                }
            })
        },
        // 清理token
        async resetToken() {
            try {
                this.setCountry('');
                this.setUserInfo({role_id: '', user_name: '', picture: ''})
                Cookies.remove('login_type')
                await setToken('');
            } catch (error) {
                console.log(error);
            }
        },
        // 设置用户信息
        async setUserInfo(data) {
            // 用户信息存入本地
            storage.setItem('user_info', data)
            this.user_info = data;
        },
        // 获取下载链接
        async getDownloadList() {
            const That = this
            return new Promise((resolve, reject) => {
                const params = {
                    link_ids: [
                        'Support',
                        'AndroidApk',
                        'Twitter',
                        'Facebook',
                        'Instagram',
                        'NoxPlayer',
                        'AppStore',
                        'GooglePlay'
                    ]
                }
                downloadLinkApi(params).then(async res => {
                    const data = res.data;
                    That.downloadLink = data.list;
                    resolve(res);
                }).catch(error => {
                    That.downloadLink = []
                    reject(error);
                })
            })
        }
    }
});

export default useUserStore;
