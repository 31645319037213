import {createI18n} from 'vue-i18n'
import Cookies from 'js-cookie'

import arLocale from './ar.js'
import enLocale from './en'
import japaneseLocale from './japanese.js'
import zhLocale from './zh-Hans.js';
import tcLocale from './zh-Hant.js'
import ruLocal from './ru.js';
import frLocal from './fr.js'
import deLocal from './de.js'
import itLocal from './it.js'
import esLocal from './es.js'
import koLocal from './ko.js'
import idLocal from './id.js'
import thLocal from './th.js'
import ptLocal from './pt.js'
import trLocal from './tr.js'
import viLocal from './vi.js'
import nlLocal from './nl.js'

const messages = {
  'ar': arLocale,
  'en': enLocale,
  'ja': japaneseLocale,
  'zh-Hans': zhLocale,
  'zh-Hant': tcLocale,
  'ru': ruLocal,
  'fr': frLocal,
  'de': deLocal,
  'it': itLocal,
  'es': esLocal,
  'ko': koLocal,
  'id': idLocal,
  'th': thLocal,
  'pt': ptLocal,
  'tr': trLocal,
  'vi': viLocal,
  'nl': nlLocal,
}

const getLanguage = () => {
  // const chooseLanguage = Cookies.get('language');
  // if (chooseLanguage) return chooseLanguage;

  // navigator:
  // language：返回当前的浏览器语言（来自 Mozilla Developer Center）
  // userLanguage：返回操作系统设定的自然语言（来自 MSDN）
  // browserLanguage：返回当前的浏览器语言（来自 MSDN）
  // systemLanguage：返回当前操作系统的缺省语言（来自 MSDN）
  const language = Cookies.get('language') || (navigator.language || navigator.browserLanguage).toLowerCase() // 取本地的语言
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = createI18n({
  locale: getLanguage(), // 默认显示的语言
  fallbackLocale: getLanguage(),// 预设语言环境
  globalInjection: true, // 全局生效$t
  legacy: false,
  messages: messages,
  warnHtmlMessage: false
})

export default i18n;
