// 通知 Web 浏览器不要执行与事件关联的默认动作
const touchStart = () => {
  event.preventDefault();
}
// 开启滑动
export const openBodyScroll = () => {
  document.body.classList.remove('overflow-hidden');
  // document.removeEventListener('touchmove', touchStart, {passive: false});
}

// 禁止滑动
export const stopBodyScroll = () => {
  document.body.classList.add('overflow-hidden');
  // document.addEventListener('touchmove', touchStart, {passive: false});
}
