export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Erro do servidor, por favor, tente novamente mais tarde.',
        20014: 'Identificação de usuário não existe, por favor digite novamente.', //  游戏用户ID错误
        10002: 'Erro do servidor, por favor, tente novamente mais tarde. (10002)',// 参数无效
        10008: 'Erro do servidor, por favor, tente novamente mais tarde. (10008)', // 数据未找到
        30008: 'Erro do servidor, por favor, tente novamente mais tarde. (30008)', // 商品不存在
        30007: 'Erro do servidor, por favor, tente novamente mais tarde. (30007)', // 商品币种类型不正确
        10001: 'Erro do servidor, por favor, tente novamente mais tarde. (10001)', // 服务器开小差
        10000: 'Erro de rede, verifique suas configurações de rede.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Compra Efetuada!',
        'SuccessInfo': 'Obrigado por sua compra! <br/>Reinicie o jogo e confira sua caixa de entrada para coletar seu item. <br/>(canto superior direito da tela -> Caixa de Entrada)',
        'SuccessBtn': 'Excelente',
        // 失败弹窗
        'ErrorTitle': 'Compra Falhou!',
        'ErrorInfo': 'Falha na compra! Por favor tente novamente.',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "Loja da Alice",
        'title2': "Jogos",
        'Or': 'ou',
        'placeholder': 'Insira o ID de usuário',
        'LogIn': 'Entrar',
        'SignInWithFacebook': 'Entrar com o Facebook',
        'SignInWithGoogle': 'Entrar com Google',
        'LoginSuccess': 'Login bem-sucedido, você pode começar a comprar agora!',
        'LoginFailed': 'Falha no login! Verifique se o seu ID de usuário está correto.',
        'LogOut': 'Logout bem-sucedido!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Esse site usa cookies para assegurar que você tenha a melhor experiência no nosso site.',
        'Accept': 'Aceitar'
    },
    // header部分
    'header': {
        'Menu': ['LOJA', 'Jogos', 'Apoio', 'Siga-nos'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Apoio',
            'Termos de Serviço',
            'Política de Privacidade',
            'Exclusão de Dados'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-po.html',
            'https://policy.judiangames.com/privacypolicy-pt-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Descubra Segredos, Desenhe Seu Sonho<br/>—Comece a Aventura!',
            'Mergulhe no Garden Affairs, um cativante jogo de combinar 3 <br/>gratuito que encantou milhões.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Junte-se a milhões em Garden Affairs, <br/>o jogo de quebra-cabeças grátis! <br/>Venha com Alice criar a vida dos seus sonhos!'
        ],
        'Custom': [
            'Garden Affairs está repleto de novos recursos emocionantes, incluindo:',
            'Expresse Seu Estilo',
            'Selecione móveis e decorações. Personalize cada canto, <br/>transformando a mansão em uma obra-prima.',
            'Histórias Intrigantes',
            'Explore os segredos escondidos no jardim. <br/>Siga cada reviravolta na trama. Faça novos amigos.',
            'Níveis Divertidos',
            'Conquiste níveis desafiadores com melhorias e combinações explosivas. <br/>Vivencie a emoção dos quebra-cabeças!'
        ],
        'GamePictures': 'Imagens de Jogo'
    },
    'homePageMobile': {
        'txt': [
            'Descubra Segredos, <br/>Desenhe Seu Sonho<br/>—Comece a Aventura!',
            'Mergulhe no Garden Affairs, <br/>um cativante jogo de combinar 3 <br/>gratuito que encantou milhões.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Junte-se a milhões em Garden Affairs, <br/>o jogo de quebra-cabeças grátis! <br/>Venha com Alice criar a vida dos seus sonhos!'
        ],
        'Custom': [
            'Garden Affairs está repleto de novos <br/>recursos emocionantes, incluindo:',
            'Expresse Seu Estilo',
            'Selecione móveis e decorações. <br/>Personalize cada canto, <br/>transformando a mansão em uma obra-prima.',
            'Histórias Intrigantes',
            'Explore os segredos escondidos no jardim. <br/>Siga cada reviravolta na trama. <br/>Faça novos amigos.',
            'Níveis Divertidos',
            'Conquiste níveis desafiadores com melhorias <br/>e combinações explosivas. Vivencie <br/>a emoção dos quebra-cabeças!'
        ],
        'GamePictures': 'Imagens de Jogo'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Descubra Segredos, Desenhe Seu Sonho<br/>—Comece a Aventura!',
        'extra': 'Mais 5%',
        'extraFontSize': '1.3',
        'store': 'LOJA',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Mergulhe no Garden Affairs, <br/>um cativante jogo de combinar 3 <br/>gratuito que encantou milhões.',
        'txt3': 'Você herdou uma mansão delapidada e recrutou Alice, <br/>amiga e designer, para transformá-la no lar dos seus sonhos. <br/>Com dedicação e criatividade, a mansão se tornará uma <br/>estonteante casa dos sonhos!',
        'txt4': [
            'Encontre o Coração de Matchview.',
            'Conheça os moradores vibrantes que trazem Matchview à vida:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'A designer visionária pronta <br/>para realizar sua casa dos sonhos',
            'O charmoso florista com <br/>uma paixão pelas flores',
            'O chef que tem outros segredos <br/>além dos seus ingredientes',
            'A designer celebridade com um <br/>coração cheio de compaixão.',
            'A melhor carpinteira da cidade <br/>e também a melhor amiga',
            'O jardineiro veterano com raízes <br/>profundas na história da mansão'
        ],
        'txt5': [
            'Visuais Estonteantes',
            'Vivencie o vídeo de abertura e maravilhe-se com os meticulosos <br/>detalhes em cada escolha de design.'
        ],
        'txt6': [
            'Expresse Seu Estilo',
            'Selecione móveis e decorações. <br/>Personalize cada canto, transformando a mansão em uma <br/>obra-prima.',
            'Níveis Divertidos',
            'Conquiste níveis desafiadores com melhorias e combinações explosivas. <br/>Vivencie a emoção dos quebra-cabeças!'
        ],
        'txt7': [
            'Descubra e <br/>Conecte-se',
            'Descubra os mistérios dos jardins. <br/>Conecte-se com vizinhos e forje amizades <br/>duradouras.'
        ],
        'txt8': 'Imagens de Jogo',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Confira nossa Mídia Social',
        'txt10': 'Baixe agora!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Descubra Segredos, Desenhe Seu Sonho<br/>—Comece a Aventura!',
        'extra': 'Mais 5%',
        'extraFontSize': '1.6',
        'store': 'LOJA',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Mergulhe no Garden Affairs, <br/>um cativante jogo de combinar 3 <br/>gratuito que encantou milhões.',
        'txt3': 'Você herdou uma mansão delapidada e <br/>recrutou Alice, amiga e designer, para transformá-la <br/>no lar dos seus sonhos. Com dedicação e <br/>criatividade, a mansão se tornará uma estonteante <br/>casa dos sonhos!',
        'txt4': [
            'Encontre o Coração de Matchview.',
            'Conheça os moradores vibrantes que trazem Matchview à vida:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'A designer visionária pronta <br/>para realizar sua casa dos sonhos',
            'A designer celebridade com um <br/>coração cheio de compaixão.',
            'O charmoso florista com <br/>uma paixão pelas flores',
            'A melhor carpinteira da cidade <br/>e também a melhor amiga',
            'O chef que tem outros segredos <br/>além dos seus ingredientes',
            'O jardineiro veterano com raízes <br/>profundas na história da mansão'
        ],
        'txt5': [
            'Visuais Estonteantes',
            'Vivencie o vídeo de abertura e <br/>maravilhe-se com os meticulosos <br/>detalhes em cada escolha de design.'
        ],
        'txt6': [
            'Expresse Seu Estilo',
            'Selecione móveis e decorações. <br/>Personalize cada canto, <br/>transformando a mansão em uma <br/>obra-prima.',
            'Níveis Divertidos',
            'Conquiste níveis desafiadores com <br/>melhorias e combinações explosivas. <br/>Vivencie a emoção dos quebra-cabeças!'
        ],
        'txt7': [
            'Descubra e Conecte-se',
            'Descubra os mistérios dos jardins. <br/>Conecte-se com vizinhos e <br/>forje amizades duradouras.'
        ],
        'txt8': 'Imagens de Jogo',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Confira nossa <br/>Mídia Social',
        'txt10': 'Baixe agora!'
    },
    // 商品列表
    'product': {
        'Coins': 'Moedas',
        'STORE': 'LOJA',
        'Extra': 'Mais',
        'Title1': 'Alguém Merece Um Presente <br/>Mais Do Que Você?',
        'Title2': 'Desfrute de um dos nossos incríveis <br/>pacotes para felicidade suprema <br/>e diversão sem igual!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Sair',
        'UID': 'UID',
        'ShowDetails': 'Mostrar detalhes',
        'PaymentMethods': 'Métodos de Pagamento',
        'MyAccount': 'Minha Conta',
        'UserID': ' UserID',
        'PurchaseProduct': 'Comprar Produto',
        'Price': 'Preço',
        'total': 'Total',
        'BuyNow': 'Comprar agora',
        'ItemInBundle': 'Conjunto de Itens',
        'PleaseSelectProduct': 'Por favor, selecione o item que deseja comprar.',
        'PleaseSelectPay': 'Por favor, escolha seu método de pagamento.'
    },
    'productMobile': {
        'STORE': 'LOJA',
        'Extra': 'Mais',
        'Title1': 'Alguém Merece Um <br/>Presente Mais Do <br/>Que Você?',
        'Title2': 'Desfrute de um dos <br/>nossos incríveis pacotes <br/>para felicidade suprema e <br/>diversão sem igual!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Sair',
        'UID': 'UID',
        'ShowDetails': 'Mostrar detalhes',
        'PaymentMethods': 'Métodos de Pagamento',
        'BuyPopTitle': 'Confirmar pedido de pagamento',
        'MyAccount': 'Minha Conta',
        'UserID': 'UserID',
        'PurchaseProduct': 'Comprar Produto',
        'Price': 'Preço',
        'total': 'Total',
        'BuyNow': 'Comprar agora',
        'ItemInBundle': 'Conjunto de Itens',
        'PleaseSelectProduct': 'Por favor, selecione o item que deseja comprar.',
        'PleaseSelectPay': 'Por favor, escolha seu método de pagamento.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Exclusão da Conta',
        'Logout': 'Sair',
        'Content': 'Por favor, autorize o uso da Conta Google, conectada com sua conta no jogo.',
        'AUTHORIZE': 'Autorizar',
    },
    'deletionMobile': {
        'Title': 'Exclusão da Conta',
        'Logout': 'Sair',
        'Content': 'Por favor, autorize o uso da Conta Google, <br/>conectada com sua conta no jogo.',
        'AUTHORIZE': 'Autorizar',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Sob o Regulamento Geral Sobre a Proteção de Dados, você tem o direito de ter todos os seus dados pessoais usados no nosso aplicativo e nos serviços de nossos parceiros (pagamentos de compras, visualizações dentro do app, etc.) excluídos. Por favor observe que caso você decida excluir seus dados, a conta de usuário será excluída e não poderá ser restaurada. Contudo, será possível recomeçar o jogo do Nível 1.',
            'A exclusão da conta pode ser cancelada dentro de 15 dias.',
            'Para cancelar a exclusão, abra o jogo e pressione o botão Cancelar. Por favor observe que após 15 dias, nós não podemos reverter a exclusão, e seu jogo não poderá ser restaurado. Por favor também considere que dentro dos 15 dias durante o período de exclusão, você não pode reiniciar o jogo do começo.'
        ],
        'DeleteGameAccount': 'Excluir Conta no Jogo',
        'DelTitle': 'Confirmar',
        'DelContent': 'Tem certeza que quer excluir sua conta do jogo?',
        'DelCancel': 'Cancelar',
        'DelConfirm': 'Sim, tenho certeza',
        'DelCodeLabel': [
            'Conta de jogo para este usuário não encontrada.', // 0
            'Success! ', // 1
            'A conta do jogo foi submetida para exclusão.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Seu pedido para exclusão de dados foi registrado com sucesso.',
        'A conta no jogo será excluída 15 dias após o seu pedido.'
    ]
}
