export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Erreur de serveur, veuillez réessayer plus tard.',
        20014: 'L\'identifiant utilisateur n\'existe pas, veuillez le saisir à nouveau.', //  游戏用户ID错误
        10002: 'Erreur de serveur, veuillez réessayer plus tard. (10002)',// 参数无效
        10008: 'Erreur de serveur, veuillez réessayer plus tard. (10008)', // 数据未找到
        30008: 'Erreur de serveur, veuillez réessayer plus tard. (30008)', // 商品不存在
        30007: 'Erreur de serveur, veuillez réessayer plus tard. (30007)', // 商品币种类型不正确
        10001: 'Erreur de serveur, veuillez réessayer plus tard. (10001)', // 服务器开小差
        10000: 'Anomalie réseau, veuillez vérifier vos paramètres réseau.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Achat Réussi !',
        'SuccessInfo': 'Merci pour votre achat !<br/>Redémarrez le jeu et consultez votre boîte aux lettres pour récupérer votre objet.<br/>(Coin supérieur droit de l\'écran -> Boîte aux lettres)',
        'SuccessBtn': 'Super',
        // 失败弹窗
        'ErrorTitle': 'Échec de l\'achat !',
        'ErrorInfo': 'Échec de l’achat ! Veuillez réessayer.',
        'ErrorBtn': 'Confirmer'
    },
    // 登录弹窗
    'login': {
        'title1': "La boutique d'Alice",
        'title2': "Jeux",
        'Or': 'ou',
        'placeholder': 'Entrez l\'ID utilisateur',
        'LogIn': 'Connexion',
        'SignInWithFacebook': 'Se connecter avec Facebook',
        'SignInWithGoogle': 'Se connecter avec Google',
        'LoginSuccess': 'Connexion réussie, vous pouvez commencer à faire des achats !',
        'LoginFailed': 'Échec de la connexion ! Veuillez vérifier si votre ID utilisateur est correct.',
        'LogOut': 'Déconnexion réussie !',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Ce site web utilise des cookies pour garantir que vous obtenez <br/> la meilleure expérience sur notre site web.',
        'Accept': 'Accepter'
    },
    // header部分
    'header': {
        'Menu': ['MAGASIN', 'Jeux', 'Soutien', 'Suivez-nous'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Soutien',
            'Conditions d\'utilisation',
            'Politique de confidentialité',
            'Suppression des données'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-fr.html',
            'https://policy.judiangames.com/privacypolicy-fr-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Découvrez les secrets, concevez votre rêve<br/>... Commencez l\'aventure !',
            'Plongez dans Les Affaires de Jardin, un captivant jeu de puzzle match-3<br/>gratuit qui a enchanté des millions.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Rejoignez des millions de joueurs dans Garden Affairs,<br>le jeu de puzzle gratuit! Venez aider Alice<br/>à créer la vie dont vous avez toujours rêvé!'
        ],
        'Custom': [
            'Garden Affairs est rempli de nouvelles fonctionnalités passionnantes, y compris :',
            'Exprimez Votre Style',
            'Choisissez des meubles et décorations selon votre style.<br/>Personnalisez chaque coin et transformez le manoir en chef-d\'œuvre.',
            'Histoires Intrigantes',
            'Explorez les secrets cachés dans le jardin.<br/>Suivez chaque tournant de l\'intrigue. Faites de nouveaux amis.',
            'Niveaux Amusants',
            'Maîtrisez des niveaux difficiles avec des boosters et combinaisons explosives.<br/>Ressentez le frisson des énigmes et la satisfaction des associations.'
        ],
        'GamePictures': 'Images du jeu'
    },
    'homePageMobile': {
        'txt': [
            'Découvrez les secrets,<br/>oncevez votre rêve<br/>... Commencez l\'aventure !',
            'Plongez dans Les Affaires de Jardin,<br/>un captivant jeu de puzzle match-3<br/>gratuit qui a enchanté des millions.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Rejoignez des millions de joueurs dans Garden<br>Affairs, le jeu de puzzle gratuit! Venez aider Alice<br/>à créer la vie dont vous avez toujours rêvé!'
        ],
        'Custom': [
            'Garden Affairs est rempli de nouvelles fonctionnalités passionnantes, y compris :',
            'Exprimez Votre Style',
            'Choisissez des meubles et décorations selon votre<br/>style. Personnalisez chaque coin et transformez le<br/>manoir en chef-d\'œuvre.',
            'Histoires Intrigantes',
            'Explorez les secrets cachés dans le jardin.<br/>Suivez chaque tournant de l\'intrigue.<br/>Faites de nouveaux amis.',
            'Niveaux Amusants',
            'Maîtrisez des niveaux difficiles avec des boosters et<br/>combinaisons explosives. Ressentez le frisson des<br/>énigmes et la satisfaction des associations.'
        ],
        'GamePictures': 'Images du jeu'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Découvrez les secrets, concevez votre rêve<br/>... Commencez l\'aventure !',
        'extra': '5% en plus',
        'store': 'MAGASIN',
        'storeFontSize': '1.7',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Plongez dans Les Affaires de Jardin,<br/>un captivant jeu de puzzle match-3<br/>gratuit qui a enchanté des millions.',
        'txt3': 'Vous avez hérité d\'un grand domaine délabré et engagez votre amie d\'enfance,<br/>la designer Alice, pour en faire votre maison de rêve.<br/>Avec dévouement et créativité, regardez le manoir devenir une maison de rêve<br/>à couper le souffle !',
        'txt4': [
            'Rencontrez le cœur de Matchville.',
            'Découvrez les habitants dynamiques qui donnent vie à Matchville :'
        ],
        'PeopleName': ['Alice', 'Julien', 'Stefano', 'Valérie', 'Aurore', 'Alain'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'Le designer visionnaire prêt<br/>à créer votre maison de rêve',
            'Le charmant fleuriste<br/>passionné par les fleurs',
            'Le chef qui cache plus que<br/>des ingrédients dans ses manches',
            'Le designer célèbre<br/>au cœur généreux',
            'Le meilleur menuisier<br/>en ville et aussi le meilleur ami',
            'Le jardinier expérimenté avec<br/>des racines profondes dans l\'histoire du manoir'
        ],
        'txt5': [
            'Des visuels époustouflants',
            'Découvrez la magnifique cinématique d\'ouverture et émerveillez-vous<br/>devant chaque détail de conception.'
        ],
        'txt6': [
            'Exprimez Votre Style',
            'Choisissez des meubles et décorations selon votre style.<br/>Personnalisez chaque coin et transformez le manoir en chef-d\'œuvre.',
            'Niveaux Amusants',
            'Maîtrisez des niveaux difficiles avec des boosters et combinaisons explosives.<br/>Ressentez le frisson des énigmes et la satisfaction des associations.'
        ],
        'txt7': [
            'Découvrez et<br/>connectez-vous',
            'Découvrez les mystères des jardins. Connectez-vous<br/>avec les voisins et forgez des amitiés durables.'
        ],
        'txt8': 'Images du jeu',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Consultez nos réseaux sociaux',
        'txt10': 'Téléchargez maintenant !',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Découvrez les secrets, concevez votre rêve<br/>... Commencez l\'aventure !',
        'extra': '5% en plus',
        'store': 'MAGASIN',
        'storeFontSize': '2',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Plongez dans Les Affaires de Jardin,<br/>un captivant jeu de puzzle match-3<br/>gratuit qui a enchanté des millions.',
        'txt3': 'Vous avez hérité d\'un grand domaine délabré et<br/>engagez votre amie d\'enfance, la designer Alice,<br/>pour en faire votre maison de rêve. Avec<br/>dévouement et créativité, regardez le manoir<br/>devenir une maison de rêve à couper le souffle !',
        'txt4': [
            'Rencontrez le cœur de Matchville.',
            'Découvrez les habitants dynamiques<br/>qui donnent vie à Matchville :'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'Alice',
            'Valérie',
            'Julien',
            'Aurore',
            'Stefano',
            'Alain'
        ],
        'PeopleDesc': [
            'Le designer visionnaire prêt<br/>à créer votre maison de rêve',
            'Le designer célèbre<br/>au cœur généreux',
            'Le charmant fleuriste<br/>passionné par les fleurs',
            'Le meilleur menuisier<br/>en ville et aussi le meilleur ami',
            'Le chef qui cache plus que<br/>des ingrédients dans ses manches',
            'Le jardinier expérimenté avec<br/>des racines profondes dans l\'histoire du manoir'
        ],
        'txt5': [
            'Des visuels époustouflants',
            'Découvrez la magnifique cinématique<br/>d\'ouverture et émerveillez-vous<br/>devant chaque détail de conception.'
        ],
        'txt6': [
            'Exprimez Votre Style',
            'Choisissez des meubles et décorations<br/>selon votre style. Personnalisez chaque<br/>coin et transformez le manoir en<br/>chef-d\'œuvre.',
            'Niveaux Amusants',
            'Maîtrisez des niveaux difficiles avec<br/>des boosters et combinaisons explosives.<br/>Ressentez le frisson des énigmes et la<br/>satisfaction des associations.'
        ],
        'txt7': [
            'Découvrez et<br/>connectez-vous',
            'Découvrez les mystères des jardins.<br/>Connectez-vous avec les voisins<br/>et forgez des amitiés durables.'
        ],
        'txt8': 'Images du jeu',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Consultez nos<br/>réseaux sociaux',
        'txt10': 'Téléchargez maintenant !'
    },
    // 商品列表
    'product': {
        'Coins': 'Pièces',
        'STORE': 'MAGASIN',
        'Extra': 'en plus',
        'Title1': 'Personne ne mérite un<br/>cadeau plus que vous!',
        'Title2': 'Laissez-vous tenter par l\'un de nos<br/>super packs pour une joie ultime et un <br/>plaisir inégalé !',
        'LOGIN': 'CONNEXION',
        'LOGOUT': 'Se déconnecter',
        'UID': 'UID',
        'ShowDetails': 'Afficher les détails',
        'PaymentMethods': 'Méthodes de paiement',
        'MyAccount': 'Mon Compte',
        'UserID': 'UserID',
        'PurchaseProduct': 'Acheter un Produit',
        'Price': 'Prix',
        'total': 'Total',
        'BuyNow': 'Acheter maintenant',
        'ItemInBundle': 'Articles groupés',
        'PleaseSelectProduct': 'Veuillez sélectionner l\'article que vous souhaitez acheter.',
        'PleaseSelectPay': 'Veuillez choisir votre mode de paiement.'
    },
    'productMobile': {
        'STORE': 'MAGASIN',
        'Extra': 'en plus',
        'Title1': 'Personne ne mérite un<br/>cadeau plus que vous!',
        'Title2': 'Laissez-vous tenter par <br/>l\'un de nos super packs <br/>pour une joie ultime et un <br/>plaisir inégalé !',
        'LOGIN': 'CONNEXION',
        'LOGOUT': 'Se déconnecter',
        'UID': 'UID',
        'ShowDetails': 'Afficher les détails',
        'PaymentMethods': 'Méthodes de paiement',

        'BuyPopTitle': 'Confirmer la commande <br/>de paiement',
        'MyAccount': 'Mon Compte',
        'UserID': 'UserID',
        'PurchaseProduct': 'Acheter un Produit',
        'Price': 'Prix',
        'total': 'Total',
        'BuyNow': 'Acheter maintenant',
        'ItemInBundle': 'Articles groupés',
        'PleaseSelectProduct': 'Veuillez sélectionner l\'article que vous souhaitez acheter.',
        'PleaseSelectPay': 'Veuillez choisir votre mode de paiement.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Suppression de compte de jeu',
        'Logout': 'Se déconnecter',
        'Content': 'Veuillez autoriser l\'utilisation du compte Google connecté à votre compte de jeu.',
        'AUTHORIZE': 'Autoriser',
    },
    'deletionMobile': {
        'Title': 'Suppression de compte de jeu',
        'Logout': 'Se déconnecter',
        'Content': 'Veuillez autoriser l\'utilisation du compte <br/>Google connecté à votre compte de jeu.',
        'AUTHORIZE': 'Autoriser',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Sous le Règlement général sur la protection des données, vous avez le droit de demander la suppression de toutes vos données personnelles utilisées dans notre application et dans les services de nos partenaires (paiements d\'achat, vues d\'annonces dans l\'application, etc.). Veuillez noter que si vous décidez de supprimer les données, le compte utilisateur sera également supprimé et ne pourra pas être restauré. Cependant, il sera possible de recommencer le jeu à partir du niveau 1.',
            'La suppression du compte peut être annulée dans un délai de 15 jours. ',
            'Pour annuler la suppression, ouvrez le jeu et appuyez sur le bouton Annuler. Veuillez noter qu\'après 15 jours, nous ne pourrons pas annuler la suppression et votre compte ne pourra pas être restauré. Veuillez également noter que pendant cette période de 15 jours, vous ne pourrez pas recommencer le jeu depuis le début. '
        ],
        'DeleteGameAccount': 'Supprimer le compte de jeu',
        'DelTitle': 'Confirmer',
        'DelContent': 'Êtes-vous sûr de vouloir supprimer votre compte de jeu ?',
        'DelCancel': 'Annuler',
        'DelConfirm': 'Oui, je suis sûr.',
        'DelCodeLabel': [
            'Compte de jeu pour cet utilisateur introuvable.', // 0
            'Success! ', // 1
            'Le compte de jeu a été soumis pour suppression.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Votre demande de suppression des données est enregistrée avec succès.',
        'Le compte de jeu sera supprimé dans les 15 jours suivant votre demande.'
    ]
}
