import Cookies from 'js-cookie'
import {Decrypt, Encrypt} from "@/tools/crypto";

const ENV = import.meta.env.VITE_USER_NODE_ENV;
const DOMAIN = import.meta.env.VITE_DOMAIN;

// console.log('---ENV:', ENV);
// console.log('--DOMAIN:', DOMAIN);

export function tokenKey() {
  if (ENV === 'production') {
    return 'website-pay-token'
  } else if (ENV === 'development') {
    return 'website-pay-token-dev'
  } else if (ENV === 'test') {
    return 'website-pay-token-test'
  } else if (ENV === 'preview') {
    return 'website-pay-token-preview'
  }
}

export const setToken = (token, options) => {
  setShareCookies(tokenKey(), Encrypt(token), options)
}

export const getToken = () => {
  const token = Cookies.get(tokenKey())
  return token && Decrypt(token)
}

export const setShareCookies = (key, value, options) => {
  if (options) {
    if (!options.domain) {
      options.domain = ['localhost', '127.0.0.1'].includes(document.domain)
        ? document.domain
        : DOMAIN
    }
  } else {
    options = {
      domain: ['localhost', '127.0.0.1'].includes(document.domain)
        ? document.domain
        : DOMAIN
    }
  }
  Cookies.set(key, value, options)
}

//1、存cookie
// set方法支持的属性有 ：
// expires -> 过期时间
// path    -> 设置为指定页面创建cookie
// domain  -> 设置对指定域名及指定域名的子域名可见
// secure  -> 值有 false 和 true ,表示设置是否只支持https,默认是false
// 删除cookie
export const removeCookie = (key, path) => {
  // Cookies.remove('key');//删除普通的cookie
  // Cookies.remove('name', { path: '' }); // 删除存了指定页面path的cookie
  path ? Cookies.remove(key, {path}) : Cookies.remove(key)
}

export const isLogined = () => {
  const user = getToken();
  return user?.role_id && user?.role_id !== 'undefined';
}
