export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Errore del server, riprova più tardi.',
        20014: 'L\'ID utente non esiste, inseriscilo nuovamente.', //  游戏用户ID错误
        10002: 'Errore del server, riprova più tardi. (10002)',// 参数无效
        10008: 'Errore del server, riprova più tardi. (10008)', // 数据未找到
        30008: 'Errore del server, riprova più tardi. (30008)', // 商品不存在
        30007: 'Errore del server, riprova più tardi. (30007)', // 商品币种类型不正确
        10001: 'Errore del server, riprova più tardi. (10001)', // 服务器开小差
        10000: 'Errore di rete, si prega di controllare le impostazioni di rete.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Acquisto riuscito!',
        'SuccessInfo': 'Grazie per l’acquisto! <br/>Riavvia il gioco e controlla la tua casella di posta per ritirare il tuo oggetto. <br/>(Angolo in alto a destra dello schermo -> Casella di posta)',
        'SuccessBtn': 'Ottimo',
        // 失败弹窗
        'ErrorTitle': 'Acquisto non riuscito!',
        'ErrorInfo': 'Acquisto non riuscito! Riprova.',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "Negozio di Alice",
        'title2': "Giochi",
        'Or': 'oppure',
        'placeholder': 'Inserisci l\'ID utente',
        'LogIn': 'Accesso',
        'SignInWithFacebook': 'Accedi con Facebook',
        'SignInWithGoogle': 'Accedi con Google',
        'LoginSuccess': 'Accesso riuscito, puoi iniziare a fare acquisti!',
        'LoginFailed': 'Accesso fallito! Si prega di verificare se l\'ID utente inserito è corretto.',
        'LogOut': 'Disconnessione riuscita!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Questo sito utilizza i cookie per assicurarti la migliore esperienza sul nostro sito.',
        'Accept': 'Accetta'
    },
    // header部分
    'header': {
        'Menu': ['NEGOZIO', 'Giochi', 'Supporto', 'Seguici'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Supporto',
            'Termini di servizio',
            'Informativa sulla privacy',
            'Eliminazione dati'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-it.html',
            'https://policy.judiangames.com/privacypolicy-it.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Svela i segreti, progetta il tuo sogno: inizia l\'avventura!',
            'Tuffati in Garden Affairs, un avvincente gioco match-3 gratuito <br/>che ha conquistato milioni di persone. '
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Unisciti a milioni di giocatori in Garden Affairs, <br/>il gioco di puzzle gratuito! Vieni ad aiutare Alice <br/>a creare la vita che hai sempre sognato!'
        ],
        'Custom': [
            'Garden Affairs è pieno di nuove funzionalità entusiasmanti, tra cui:',
            'Esprimi il tuo stile',
            'Seleziona mobili e decorazioni che riflettano il tuo stile. <br/>Personalizza ogni angolo e trasforma la villa in uno straordinario capolavoro.',
            'Storie Intriganti',
            'Esplora i segreti nascosti nel giardino. <br/>Segui ogni svolta della trama. Fai nuovi amici.',
            'Livelli divertenti',
            'Padroneggia livelli avvincenti con booster unici e combinazioni esplosive. <br/>Prova l\'emozione di risolvere rompicapi e la soddisfazione di abbinare gli elementi.'
        ],
        'GamePictures': 'Immagini del gioco'
    },
    'homePageMobile': {
        'txt': [
            'Svela i segreti, <br/>progetta il tuo sogno: <br/>inizia l\'avventura!',
            'Tuffati in Garden Affairs, <br/>un avvincente gioco match-3 gratuito <br/>che ha conquistato milioni di persone. '
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Unisciti a milioni di giocatori in Garden Affairs, <br/>il gioco di puzzle gratuito! Vieni ad aiutare Alice <br/>a creare la vita che hai sempre sognato!'
        ],
        'Custom': [
            'Garden Affairs è pieno di nuove funzionalità <br/>entusiasmanti, tra cui:',
            'Esprimi il tuo stile',
            'Seleziona mobili e decorazioni che riflettano il tuo stile. <br/>Personalizza ogni angolo e trasforma la villa in <br/>uno straordinario capolavoro.',
            'Storie Intriganti',
            'Esplora i segreti nascosti nel giardino. <br/>Segui ogni svolta della trama. Fai nuovi amici.',
            'Livelli divertenti',
            'Padroneggia livelli avvincenti con booster unici <br/>e combinazioni esplosive. Prova l\'emozione di <br/>risolvere rompicapi e la soddisfazione di abbinare <br/>gli elementi.'
        ],
        'GamePictures': 'Immagini del gioco'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Svela i segreti, progetta il tuo sogno: <br/>inizia l\'avventura!',
        'extra': 'Extra 5%',
        'store': 'NEGOZIO',
        'storeFontSize': '1.7',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Tuffati in Garden Affairs, <br/>un avvincente gioco match-3 gratuito <br/>che ha conquistato milioni di persone.',
        'txt3': 'Hai ereditato una villa fatiscente e assumi la tua amica d\'infanzia, <br/>la designer Alice, per trasformarla nella casa dei tuoi sogni. <br/>Con dedizione e creatività, guarda la villa diventare una casa da sogno mozzafiato!',
        'txt4': [
            'Scopri la città di Matchview',
            'Incontra la gente del posto che dà vita a Matchview:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'L\'interior designer pronta a <br/>realizzare la casa dei tuoi sogni',
            'L\'affascinante fioraio <br/>con la passione per le piante',
            'Lo chef che nelle tasche nasconde <br/>più segreti che ingredienti',
            'La celebre designer <br/>dal cuore premuroso',
            'La migliore carpentiera <br/>della città nonché una cara amica',
            'Il giardiniere esperto che conosce <br/>la villa come le sue tasche'
        ],
        'txt5': [
            'Grafica mozzafiato',
            'Goditi lo straordinario filmato di apertura e ammira i meticolosi <br/>dettagli in ogni scelta di design.'
        ],
        'txt6': [
            'Esprimi il tuo stile',
            'Seleziona mobili e decorazioni che riflettano il tuo stile. <br/>Personalizza ogni angolo e trasforma la villa in uno straordinario <br/>capolavoro.',
            'Livelli divertenti',
            'Padroneggia livelli avvincenti con booster unici e combinazioni esplosive. <br/>Prova l\'emozione di risolvere rompicapi e la soddisfazione di abbinare gli elementi.'
        ],
        'txt7': [
            'Scopri e crea <br/>legami',
            'Scopri i misteri nascosti negli ampi giardini. <br/>Entra in contatto con i vicini e stringi amicizie durature <br/>oltre le mura della villa.'
        ],
        'txt8': 'Immagini del gioco',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Dai un’occhiata ai nostri social media',
        'txt10': 'Scarica ora!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Svela i segreti, progetta il tuo sogno: <br/>inizia l\'avventura!',
        'extra': 'Extra 5%',
        'store': 'NEGOZIO',
        'storeFontSize': '2',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Tuffati in Garden Affairs, <br/>un avvincente gioco match-3 <br/>gratuito che ha conquistato <br/>milioni di persone.',
        'txt3': 'Hai ereditato una villa fatiscente e assumi la <br/>tua amica d\'infanzia, la designer Alice, per <br/>trasformarla nella casa dei tuoi sogni. Con <br/>dedizione e creatività, guarda la villa diventare <br/>una casa da sogno mozzafiato!',
        'txt4': [
            'Scopri la città di Matchview',
            'Incontra la gente del posto che dà <br/>vita a Matchview:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'L\'interior designer pronta a <br/>realizzare la casa dei tuoi sogni',
            'La celebre designer <br/>dal cuore premuroso',
            'L\'affascinante fioraio <br/>con la passione per le piante',
            'La migliore carpentiera <br/>della città nonché una cara amica',
            'Lo chef che nelle tasche nasconde <br/>più segreti che ingredienti',
            'Il giardiniere esperto che conosce <br/>la villa come le sue tasche'
        ],
        'txt5': [
            'Grafica mozzafiato',
            'Goditi lo straordinario filmato di apertura e <br/>ammira i meticolosi dettagli in ogni scelta di <br/>design.'
        ],
        'txt6': [
            'Esprimi il tuo stile',
            'Seleziona mobili e decorazioni che riflettano <br/>il tuo stile. Personalizza ogni angolo e trasforma <br/>la villa in uno straordinario capolavoro.',
            'Livelli divertenti',
            'Padroneggia livelli avvincenti con booster unici <br/>e combinazioni esplosive. Prova l\'emozione di <br/>risolvere rompicapi e la soddisfazione di abbinare <br/>gli elementi.'
        ],
        'txt7': [
            'Scopri e crea legami',
            'Scopri i misteri nascosti negli ampi giardini. <br/>Entra in contatto con i vicini e stringi amicizie <br/>durature oltre le mura della villa.'
        ],
        'txt8': 'Immagini del gioco',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Dai un’occhiata ai <br/>nostri social media',
        'txt10': 'Scarica ora!'
    },
    // 商品列表
    'product': {
        'Coins': 'Monete',
        'STORE': 'NEGOZIO',
        'Extra': 'Extra',
        'Title1': 'Chi merita un regalo <br/>se non tu?',
        'Title2': 'Concediti uno dei nostri fantastici <br/>pacchetti per il massimo del <br/>divertimento!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Disconnettersi',
        'UID': 'UID',
        'ShowDetails': 'Mostra dettagli',
        'PaymentMethods': 'Metodi di pagamento',
        'MyAccount': 'Il mio account',
        'UserID': 'UserID',
        'PurchaseProduct': 'Acquista prodotto',
        'Price': 'Prezzo',
        'total': 'Totale',
        'BuyNow': 'Acquista ora',
        'ItemInBundle': 'Pacchetti di oggetti',
        'PleaseSelectProduct': 'Seleziona l\'articolo che desideri acquistare.',
        'PleaseSelectPay': 'Si prega di scegliere il metodo di pagamento.'
    },
    'productMobile': {
        'STORE': 'NEGOZIO',
        'Extra': 'Extra',
        'Title1': 'Chi merita un regalo <br/>se non tu?',
        'Title2': 'Concediti uno dei <br/>nostri fantastici pacchetti <br/>per il massimo del <br/>divertimento!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Disconnettersi',
        'UID': 'UID',
        'ShowDetails': 'Mostra dettagli',
        'PaymentMethods': 'Metodi di pagamento',
        'BuyPopTitle': 'Conferma ordine di pagamento',
        'MyAccount': 'Il mio account',
        'UserID': 'UserID',
        'PurchaseProduct': 'Acquista prodotto',
        'Price': 'Prezzo',
        'total': 'Totale',
        'BuyNow': 'Acquista ora',
        'ItemInBundle': 'Pacchetti di oggetti',
        'PleaseSelectProduct': 'Seleziona l\'articolo che desideri acquistare.',
        'PleaseSelectPay': 'Si prega di scegliere il metodo di pagamento.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Eliminazione account di gioco',
        'Logout': 'Disconnettersi',
        'Content': 'Autorizza l\'utilizzo dell\'account Google collegato al tuo account di gioco.',
        'AUTHORIZE': 'Autorizza',
    },
    'deletionMobile': {
        'Title': 'Eliminazione account di gioco',
        'Logout': 'Disconnettersi',
        'Content': 'Autorizza l\'utilizzo dell\'account <br/>Google collegato al tuo account di gioco.',
        'AUTHORIZE': 'Autorizza',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Ai sensi del Regolamento generale sulla protezione dei dati, hai il diritto di far eliminare tutti i tuoi dati personali utilizzati nella nostra applicazione e nei servizi dei nostri partner (pagamenti per acquisti, visualizzazioni di annunci in-app, ecc.). Tieni presente che se decidi di eliminare i dati, l\'account utente verrà eliminato e non sarà ripristinabile. Sarà comunque possibile ricominciare il gioco dal Livello 1.',
            'L’eliminazione dell\'account può essere annullata entro 15 giorni.',
            'Per annullare l\'eliminazione, apri il gioco e premi il pulsante Annulla. Tieni presente che dopo 15 giorni non potremo annullare l\'eliminazione e il tuo gioco non potrà essere ripristinato. Tieni inoltre presente che entro 15 giorni durante il periodo di eliminazione non potrai riavviare il gioco dall\'inizio.'
        ],
        'DeleteGameAccount': 'Elimina account di gioco',
        'DelTitle': 'Conferma',
        'DelContent': 'Vuoi davvero eliminare il tuo account di gioco?',
        'DelCancel': 'Annulla',
        'DelConfirm': 'Sì, sono sicuro',
        'DelCodeLabel': [
            'Account di gioco per questo utente non trovato.', // 0
            'Success! ', // 1
            'L\'account di gioco è stato inviato per la cancellazione.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'La tua richiesta di cancellazione dei dati è stata registrata con successo.',
        'L\'account di gioco verrà cancellato entro 15 giorni dalla tua richiesta.'
    ]
}
