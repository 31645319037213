export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Serverfehler, bitte versuchen Sie es später noch einmal.',
        20014: 'Nutzer-ID existiert nicht. Bitte erneut eingeben.', //  游戏用户ID错误
        10002: 'Serverfehler, bitte versuchen Sie es später noch einmal. (10002)',// 参数无效
        10008: 'Serverfehler, bitte versuchen Sie es später noch einmal. (10008)', // 数据未找到
        30008: 'Serverfehler, bitte versuchen Sie es später noch einmal. (30008)', // 商品不存在
        30007: 'Serverfehler, bitte versuchen Sie es später noch einmal. (30007)', // 商品币种类型不正确
        10001: 'Serverfehler, bitte versuchen Sie es später noch einmal. (10001)', // 服务器开小差
        10000: 'Netzwerkfehler, bitte überprüfen Sie Ihre Netzwerkeinstellungen.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Kauf Erfolgreich!',
        'SuccessInfo': 'Danke für deinen Kauf!<br/>Starte das Spiel neu und überprüfe deinen Posteingang, um dein Item einzusammeln.<br/>(Obere rechte Ecke des Bildschirms -> Posteingang)',
        'SuccessBtn': 'Großartig',
        // 失败弹窗
        'ErrorTitle': 'Kauf fehlgeschlagen!',
        'ErrorInfo': 'Kauf fehlgeschlagen! Bitte erneut versuchen.',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "ALICE'S SHOP",
        'title2': "Spiele",
        'Or': 'oder',
        'placeholder': 'Benutzer-ID eingeben',
        'LogIn': 'Anmelden',
        'SignInWithFacebook': 'Mit Facebook anmelden',
        'SignInWithGoogle': 'Mit Google Anmelden',
        'LoginSuccess': 'Erfolgreich eingeloggt, Sie können jetzt einkaufen!',
        'LoginFailed': 'Anmeldung fehlgeschlagen! Bitte überprüfen Sie, ob Ihre Benutzer-ID korrekt ist.',
        'LogOut': 'Erfolgreich abgemeldet!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Diese Webseite nutzt Cookies, um dir das beste Erlebnis auf unserer Webseite zu bieten.',
        'Accept': 'Annehmen'
    },
    // header部分
    'header': {
        'Menu': ['LADEN', 'Spiele', 'Unterstützung', 'Folge uns'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Unterstützung',
            'Allgemeine Geschäftsbedingungen',
            'Datenschutzrichtlinie',
            'Daten-Löschung'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-de.html',
            'https://policy.judiangames.com/privacypolicy-de.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Entdecke Geheimnisse, erfülle Träume – Starte dein Abenteuer!',
            'Tauche ein in Garden Affairs, ein fesselndes Free-To-Play 3-Gewinnt-Spiel,<br/>das Millionen begeistert. '
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Trete Millionen von Spielern bei in Garden Affairs,<br/>dem kostenlosen Puzzle-Spiel! Hilf Alice, das Leben zu erschaffen,<br/>von dem du immer geträumt hast!'
        ],
        'Custom': [
            'Garden Affairs ist vollgepackt mit aufregenden neuen Funktionen, darunter:',
            'Drück deinen Style aus',
            'Wähle Möbel und Dekor, die deinen Stil widerspiegeln.<br/>Personalisiere jede Ecke und verwandle das Anwesen in ein beeindruckendes Meisterwerk.',
            'Faszinierende Geschichten',
            'Erkunde die im Garten verborgenen Geheimnisse.<br/>Folge jeder Wendung der Handlung. Finde neue Freunde.',
            'Spannende Levels',
            'Meistere Levels mit Boostern und explosiven Kombinationen.<br/>Erlebe den Nervenkitzel des Rätsellösens und die Befriedigung des Kombinierens.'
        ],
        'GamePictures': 'Spiel-Bilder'
    },
    'homePageMobile': {
        'txt': [
            'Entdecke Geheimnisse,<br/>erfülle Träume<br/>– Starte dein Abenteuer!',
            'Tauche ein in Garden Affairs,<br/>ein fesselndes Free-To-Play 3-Gewinnt-Spiel,<br/>das Millionen begeistert.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Trete Millionen von Spielern bei in Garden Affairs,<br/>dem kostenlosen Puzzle-Spiel!<br/>Hilf Alice, das Leben zu erschaffen,<br/>von dem du immer geträumt hast!'
        ],
        'Custom': [
            'Garden Affairs ist vollgepackt mit aufregenden neuen Funktionen, darunter:',
            'Drück deinen Style aus',
            'Wähle Möbel und Dekor, die deinen Stil widerspiegeln.<br/>Personalisiere jede Ecke und verwandle<br/>das Anwesen in ein beeindruckendes Meisterwerk.',
            'Faszinierende Geschichten',
            'Erkunde die im Garten verborgenen Geheimnisse.<br/>Folge jeder Wendung der Handlung. Finde neue Freunde.',
            'Spannende Levels',
            'Meistere Levels mit Boostern und explosiven Kombinationen.<br/>Erlebe den Nervenkitzel des Rätsellösens<br/>und die Befriedigung des Kombinierens.'
        ],
        'GamePictures': 'Spiel-Bilder'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Entdecke Geheimnisse, erfülle Träume<br/>– Starte dein Abenteuer!',
        'extra': '5% Mehr',
        'store': 'LADEN',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Tauche ein in Garden Affairs,<br/>ein fesselndes Free-To-Play 3-Gewinnt-Spiel,<br/>das Millionen begeistert.',
        'txt3': 'Du hast ein großes, heruntergekommenes Anwesen geerbt.<br/>Mit Hilfe deiner Kindheitsfreundin und Designerin Alice verwandelst du es in dein Traumhaus.<br/>Mit Hingabe und Kreativität wird das Anwesen nach und nach atemberaubend!'
        ,
        'txt4': [
            'Triff das Herz von Matchview',
            'Lerne die lebhaften Anwohner kennen, die Matchview zum Leben erwecken:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'Die visionäre Innendesignerin,<br/>die dein Traumhaus verwirklicht',
            'Der charmante Florist<br/>mit Leidenschaft für Blumen',
            'Der Koch<br/>mit mehr als nur Zutaten im Ärmel',
            'Die berühmte Designerin<br/>mit fürsorglichem Herzen',
            'Die beste Tischlerin der Stadt<br/>und zugleich die beste Freundin',
            'Der erfahrene Gärtner<br/>mit tiefen Wurzeln in der Geschichte des Anwesens'
        ],
        'txt5': [
            'Beeindruckende Grafik',
            'Erlebe den beeindruckenden Öffnungsfilm und bestaune die<br/>Details jeder Design-Option.'
        ],
        'txt6': [
            'Drück deinen Style aus',
            'Wähle Möbel und Dekor, die deinen Stil widerspiegeln.<br/>Personalisiere jede Ecke und verwandle das Anwesen in ein<br/>beeindruckendes Meisterwerk.',
            'Spannende Levels',
            'Meistere Levels mit Boostern und explosiven Kombinationen.<br/>Erlebe den Nervenkitzel des Rätsellösens und die Befriedigung des Kombinierens.'
        ],
        'txt7': [
            'Entdecke und<br/>Verbinde',
            'Enthülle die Mysterien in den Gärten.<br/>Tausche dich mit Nachbarn aus<br/>und schließe Freundschaften.'
        ],
        'txt8': 'Spiel-Bilder',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Besuche uns auf unseren sozialen Medien.',
        'txt10': 'Jetzt herunterladen!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Entdecke Geheimnisse, erfülle Träume<br/>– Starte dein Abenteuer!',
        'extra': '5% Mehr',
        'store': 'LADEN',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Tauche ein in Garden Affairs, ein<br/>fesselndes Free-To-Play 3-Gewinnt<br/>-Spiel, das Millionen begeistert.',
        'txt3': 'Du hast ein großes,<br/>heruntergekommenes Anwesen geerbt.<br/>Mit Hilfe deiner Kindheitsfreundin<br/>und Designerin Alice verwandelst du es<br/>in dein Traumhaus. Mit Hingabe<br/>und Kreativität wird das Anwesen nach<br/>und nach atemberaubend!',
        'txt4': [
            'Triff das Herz von Matchview',
            'Lerne die lebhaften Anwohner kennen,<br/>die Matchview zum Leben erwecken:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'Die visionäre Innendesignerin,<br/>die dein Traumhaus verwirklicht',
            'Die berühmte Designerin<br/>mit fürsorglichem Herzen',
            'Der charmante Florist<br/>mit Leidenschaft für Blumen',
            'Die beste Tischlerin der Stadt<br/>und zugleich die beste Freundin',
            'Der Koch<br/>mit mehr als nur Zutaten im Ärmel',
            'Der erfahrene Gärtner<br/>mit tiefen Wurzeln in der Geschichte des Anwesens'
        ],
        'txt5': [
            'Beeindruckende Grafik',
            'Erlebe den beeindruckenden Öffnungsfilm<br/>und bestaune die<br/>Details jeder Design-Option.'
        ],
        'txt6': [
            'Drück deinen Style aus',
            'Wähle Möbel und Dekor, die deinen<br/>Stil widerspiegeln. Personalisiere jede Ecke<br/>und verwandle das Anwesen in ein<br/>beeindruckendes Meisterwerk.',
            'Spannende Levels',
            'Meistere Levels mit Boostern<br/>und explosiven Kombinationen.<br/>Erlebe den Nervenkitzel des Rätsellösens<br/>und die Befriedigung des Kombinierens.'
        ],
        'txt7': [
            'Entdecke und Verbinde',
            'Enthülle die Mysterien in den Gärten.<br/>Tausche dich mit Nachbarn aus<br/>und schließe Freundschaften.'
        ],
        'txt8': 'Spiel-Bilder',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Besuche uns auf<br/>unseren sozialen Medien.',
        'txt10': 'Jetzt herunterladen!'
    },
    // 商品列表
    'product': {
        'Coins': 'Münzen',
        'STORE': 'LADEN',
        'Extra': 'Mehr',
        'Title1': 'Wer verdient ein<br/>Geschenk mehr als du?',
        'Title2': 'Gönn dir eines unserer' +
            '<br/>fantastischen Pakete für ultimative' +
            '<br/>Freude und unschlagbaren Spaß!',
        'LOGIN': 'ANMELDEN',
        'LOGOUT': 'Abmelden',
        'UID': 'UID',
        'ShowDetails': 'Details anzeigen',
        'PaymentMethods': 'Zahlungsmethoden',
        'MyAccount': 'Mein Konto',
        'UserID': 'UserID',
        'PurchaseProduct': 'Produkt',
        'Price': 'Preis',
        'total': 'Gesamt',
        'BuyNow': 'Jetzt kaufen',
        'ItemInBundle': 'Bündel-Items',
        'PleaseSelectProduct': 'Bitte wählen Sie den Artikel aus, den Sie kaufen möchten.',
        'PleaseSelectPay': 'Bitte wählen Sie Ihre Zahlungsmethode.'
    },
    'productMobile': {
        'STORE': 'LADEN',
        'Extra': 'Mehr',
        'Title1': 'Wer verdient ein<br/>Geschenk mehr als du?',
        'Title2': 'Gönn dir eines unserer<br/>fantastischen Pakete für<br/>ultimative Freude und<br/>unschlagbaren Spaß!',
        'LOGIN': 'ANMELDEN',
        'LOGOUT': 'Abmelden',
        'UID': 'UID',
        'ShowDetails': 'Details anzeigen',
        'PaymentMethods': 'Zahlungsmethoden',

        'BuyPopTitle': 'Zahlungsauftrag bestätigen',
        'MyAccount': 'Mein Konto',
        'UserID': 'UserID',
        'PurchaseProduct': 'Produkt',
        'Price': 'Preis',
        'total': 'Gesamt',
        'BuyNow': 'Jetzt kaufen',
        'ItemInBundle': 'Bündel-Items',
        'PleaseSelectProduct': 'Bitte wählen Sie den Artikel aus, den Sie kaufen möchten.',
        'PleaseSelectPay': 'Bitte wählen Sie Ihre Zahlungsmethode.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Spiel-Konto löschen',
        'Logout': 'Abmelden',
        'Content': 'Bitte autorisiere mit dem Google-Konto, das mit deinem Spiel-Konto verbunden ist.',
        'AUTHORIZE': 'Autorisieren',
    },
    'deletionMobile': {
        'Title': 'Spiel-Konto löschen',
        'Logout': 'Abmelden',
        'Content': 'Bitte autorisiere mit dem Google-Konto,<br/>das mit deinem Spiel-Konto verbunden ist.',
        'AUTHORIZE': 'Autorisieren',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Die Löschung des Kontos kann innerhalb von 15 Tagen abgebrochen werden. Um die Löschung abzubrechen, öffnen Sie das Spiel und drücken Sie die Abbrechen-Schaltfläche.',
            'Bitte beachten Sie, dass wir die Löschung nach 15 Tagen nicht rückgängig machen können und Ihr Spiel nicht wiederhergestellt werden kann. ',
            'Bitte beachten Sie auch, dass Sie innerhalb von 15 Tagen während des Löschungszeitraums das Spiel nicht von vorne beginnen können.'
        ],
        'DeleteGameAccount': 'Spiel-Konto löschen',
        'DelTitle': 'Bestätigen',
        'DelContent': 'Bist du sicher, dass du dein Spiel-Konto löschen möchtest?',
        'DelCancel': 'Abbrechen',
        'DelConfirm': 'Ja, ich bin sicher',
        'DelCodeLabel': [
            'Spielkonto für diesen Benutzer nicht gefunden.', // 0
            'Success! ', // 1
            'Das Spielkonto wurde zur Löschung eingereicht.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Deine Anfrage zur Daten-Löschung wurde erfolgreich registriert.',
        'Spiel-Konto wird innerhalb von 15 Tagen ab deiner Anfrage gelöscht.'
    ]
}
