// 国家us 映射 语言en
export default {
  'US': 'en',
  'CN': 'zh-Hans',
  'JP': 'ja',
  'RU': 'ru',
  'DE': 'de',
  'FR': 'fr',
  'HK': 'zh-HK',
  'IT': 'it',
  'ES': 'es',
  'KR': 'ko',
  'SA': 'ar',
  'ID': 'id',
  'TH': 'th',
  'PT': 'pt',
  'TR': 'tr',
  'VN': 'vi',
  'NL': 'nl'
}
