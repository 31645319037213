<script setup>
import {computed, inject, ref, onMounted, onBeforeUnmount} from 'vue';
import Cookies from 'js-cookie';

const $t = inject('$t');
const show = ref(false);
const isShowCookies = ref('');
const windowWidth = ref(document.documentElement.clientWidth);

const updateWindowWidth = () => {
  windowWidth.value = document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth;
};

const isPc = computed(() => {
  return windowWidth.value > 750;
})

const submit = () => {
  Cookies.set('isShowCookies', true, {expires: 7});
  close();
}
const open = () => {
  show.value = true
}
const close = () => {
  show.value = false
}

const init = () => {
  isShowCookies.value = Cookies.get('isShowCookies');
  if (!isShowCookies.value) open();
}

init()

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth);
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowWidth);
})
</script>

<template>
  <div v-if="show" :class="isPc?'cookies_tips': 'cookies_tips_mobile'">
    <span class="content" v-dompurify-html="$t('cookiesTip.Content')"></span>
    <span class="accept cs" @click="submit">{{ $t('cookiesTip.Accept') }}</span>
  </div>
</template>

<style scoped lang="less">
@import 'index.less';
</style>
