export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Lỗi máy chủ, vui lòng thử lại sau.',
        20014: 'ID người dùng không tồn tại, hãy nhập lại.', //  游戏用户ID错误
        10002: 'Lỗi máy chủ, vui lòng thử lại sau. (10002)',// 参数无效
        10008: 'Lỗi máy chủ, vui lòng thử lại sau. (10008)', // 数据未找到
        30008: 'Lỗi máy chủ, vui lòng thử lại sau. (30008)', // 商品不存在
        30007: 'Lỗi máy chủ, vui lòng thử lại sau. (30007)', // 商品币种类型不正确
        10001: 'Lỗi máy chủ, vui lòng thử lại sau. (10001)', // 服务器开小差
        10000: 'Lỗi mạng, vui lòng kiểm tra cài đặt mạng của bạn.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Mua hàng thành công!',
        'SuccessInfo': 'Cảm ơn bạn đã mua hàng! <br/>Hãy gửi động lại trò chơi và kiểm tra hộp thư để nhận vật phẩm của bạn. <br/>(Góc trên cùng bên phải của màn hình -> Hộp thư)',
        'SuccessBtn': 'Tuyệt vời',
        // 失败弹窗
        'ErrorTitle': 'Giao Dịch Mua Không Thành Công!',
        'ErrorInfo': 'Giao dịch mua không thành công! Vui lòng thử lại.',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "Cửa hàng của Alice",
        'title2': "Trò chơi",
        'Or': 'hoặc',
        'placeholder': 'Nhập ID người dùng',
        'LogIn': 'Đăng nhập',
        'SignInWithFacebook': 'Đăng nhập bằng Facebook',
        'SignInWithGoogle': 'Đăng nhập bằng Google',
        'LoginSuccess': 'Đăng nhập thành công, bạn có thể bắt đầu mua sắm ngay bây giờ!',
        'LoginFailed': 'Đăng nhập thất bại! Vui lòng kiểm tra xem ID người dùng bạn nhập có chính xác không.',
        'LogOut': 'Đăng xuất thành công!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Trang web này sử dụng các cookie để đảm bảo rằng bạn nhận được<br/> trải nghiệm tốt nhất trên trang web của chúng tôi.',
        'Accept': 'Chấp nhận'
    },
    // header部分
    'header': {
        'Menu': ['CỬA HÀNG', 'Trò chơi', 'Hỗ trợ', 'Theo dõi chúng tôi'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Hỗ trợ',
            'Điều khoản Dịch vụ',
            'Chính sách Bảo mật',
            'Xóa dữ liệu'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-vi.html',
            'https://policy.judiangames.com/privacypolicy-vi-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Hé mở Bí mật, Thiết kế Giấc mơ - Bắt đầu phiêu lưu!',
            'Đắm chìm trong Garden Affairs, trò chơi match-3 miễn phí mê hoặc <br/>triệu người.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Tham gia hàng triệu người chơi trong trò chơi <br/>xếp hình miễn phí Garden Affairs! <br/>Cùng Alice tạo ra cuộc sống bạn luôn mơ ước!'
        ],
        'Custom': [
            'Garden Affairs được trang bị các tính năng mới thú vị, bao gồm:',
            'Thể hiện phong cách của bạn',
            'Chọn đồ nội thất và trang trí theo phong cách của bạn. <br/>Tùy chỉnh mọi góc nhà, biến dinh thự cũ thành kiệt tác.',
            'Câu Chuyện Hấp Dẫn',
            'Khám phá những bí mật ẩn giấu trong khu vườn. <br/>Theo dõi mọi khúc ngoặt trong cốt truyện. Kết bạn mới.',
            'Giải trí',
            'Chinh phục các cấp độ khó với tăng tốc độc đáo và kết hợp bùng nổ. <br/>Trải nghiệm hưng phấn khi giải đố và hài lòng khi ghép nối.'
        ],
        'GamePictures': 'Hình ảnh của trò chơi'
    },
    'homePageMobile': {
        'txt': [
            'Hé mở Bí mật, <br/>Thiết kế Giấc mơ <br/>- Bắt đầu phiêu lưu!',
            'Đắm chìm trong Garden Affairs, <br/>trò chơi match-3 miễn phí mê hoặc <br/>triệu người.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Tham gia hàng triệu người chơi trong trò chơi <br/>xếp hình miễn phí Garden Affairs! <br/>Cùng Alice tạo ra cuộc sống bạn luôn mơ ước!'
        ],
        'Custom': [
            'Garden Affairs được trang bị các tính năng <br/>mới thú vị, bao gồm:',
            'Thể hiện phong cách <br/>của bạn',
            'Chọn đồ nội thất và trang trí theo phong cách <br/>của bạn. Tùy chỉnh mọi góc nhà, biến dinh <br/>thự cũ thành kiệt tác.',
            'Câu Chuyện Hấp Dẫn',
            'Khám phá những bí mật ẩn giấu trong khu vườn. <br/>Theo dõi mọi khúc ngoặt trong cốt truyện. Kết bạn mới.',
            'Giải trí',
            'Chinh phục các cấp độ khó với tăng tốc độc đáo <br/>và kết hợp bùng nổ. Trải nghiệm hưng phấn <br/>khi giải đố và hài lòng khi ghép nối.'
        ],
        'GamePictures': 'Hình ảnh của trò chơi'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Hé mở Bí mật, Thiết kế Giấc mơ <br/>- Bắt đầu phiêu lưu!',
        'extra': '5% Thêm',
        'extraFontSize': '1.3',
        'store': 'CỬA HÀNG',
        'storeFontSize': '1.5',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Đắm chìm trong Garden Affairs, <br/>trò chơi match-3 miễn phí mê <br/>hoặc triệu người.',
        'txt3': 'Bạn thừa kế một bất động sản đổ nát và nhờ bạn thời thơ ấu, <br/>nhà thiết kế nổi tiếng Alice, biến nó thành ngôi nhà mơ ước. <br/>Với sự tận tâm và sáng tạo, dinh thự sẽ trở thành ngôi nhà đẹp như mơ!',
        'txt4': [
            'Gặp gỡ Trái tim của Matchview.',
            'Làm quen với những người dân sôi nổi mang đến sức sống cho Matchview:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'Nhà thiết kế nội thất sẵn sàng <br/>hiện thực hóa ngôi nhà mơ ước <br/>của bạn.',
            'Người bán hoa quyến rũ, <br/>đam mê hoa.',
            'Đầu bếp có nhiều bí mật <br/>hơn cả nguyên liệu.',
            'Nhà thiết kế nổi tiếng <br/>với trái tim nhân ái.',
            'Cô thợ mộc giỏi nhất thị <br/>trấn và là bạn thân nhất.',
            'Người làm vườn dày dạn kinh <br/>nghiệm và hiểu biết sâu về lịch <br/>sử dinh thự.'
        ],
        'txt5': [
            'Hình ảnh tuyệt đẹp',
            'Trải nghiệm đoạn phim mở đầu tuyệt đẹp và choáng ngợp trước sự <br/>tỉ mỉ trong từng chi tiết thiết kế.'
        ],
        'txt6': [
            'Thể hiện phong <br/>cách của bạn',
            'Chọn đồ nội thất và trang trí theo phong cách của bạn. <br/>Tùy chỉnh mọi góc nhà, biến dinh thự cũ thành kiệt tác.',
            'Giải trí',
            'Chinh phục các cấp độ khó với tăng tốc độc đáo và kết hợp bùng nổ. <br/>Trải nghiệm hưng phấn khi giải đố và hài lòng khi ghép nối.'
        ],
        'txt7': [
            'Khám phá và <br/>Kết nối',
            'Hé lộ bí mật trong các khu vườn rộng. <br/>Kết nối với hàng xóm và xây dựng tình bạn bền <br/>vững ngoài dinh thự.'
        ],
        'txt8': 'Hình ảnh của trò chơi',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Xem các trang mạng xã hội của chúng tôi.',
        'txt10': 'Tải xuống ngay!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Hé mở Bí mật, Thiết kế Giấc mơ <br/>- Bắt đầu phiêu lưu!',
        'extra': '5% Thêm',
        'extraFontSize': '1.5',
        'store': 'CỬA HÀNG',
        'storeFontSize': '1.6',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Đắm chìm trong Garden Affairs, <br/>trò chơi match-3 miễn phí mê <br/>hoặc triệu người.',
        'txt3': 'Bạn thừa kế một bất động sản đổ nát và <br/>nhờ bạn thời thơ ấu, nhà thiết kế nổi tiếng <br/>Alice, biến nó thành ngôi nhà mơ ước. <br/>Với sự tận tâm và sáng tạo, dinh thự sẽ trở <br/>thành ngôi nhà đẹp như mơ!',
        'txt4': [
            'Gặp gỡ Trái tim của Matchview.',
            'Làm quen với những người dân sôi <br/>nổi mang đến sức sống cho Matchview:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'Nhà thiết kế nội thất sẵn sàng hiện <br/>thực hóa ngôi nhà mơ ước của bạn.',
            'Nhà thiết kế nổi tiếng <br/>với trái tim nhân ái.',
            'Người bán hoa quyến rũ, <br/>đam mê hoa.',
            'Cô thợ mộc giỏi nhất thị <br/>trấn và là bạn thân nhất.',
            'Đầu bếp có nhiều bí mật <br/>hơn cả nguyên liệu.',
            'Người làm vườn dày dạn kinh nghiệm và <br/>hiểu biết sâu về lịch sử dinh thự.'
        ],
        'txt5': [
            'Hình ảnh tuyệt đẹp',
            'Trải nghiệm đoạn phim mở đầu tuyệt đẹp <br/>và choáng ngợp trước sự tỉ mỉ trong từng <br/>chi tiết thiết kế.'
        ],
        'txt6': [
            'Thể hiện phong cách của bạn',
            'Chọn đồ nội thất và trang trí theo <br/>phong cách của bạn. Tùy chỉnh mọi <br/>góc nhà, biến dinh thự cũ thành kiệt tác.',
            'Giải trí',
            'Chinh phục các cấp độ khó với tăng tốc <br/>độc đáo và kết hợp bùng nổ. Trải nghiệm <br/>hưng phấn khi giải đố và hài lòng khi ghép nối.'
        ],
        'txt7': [
            'Khám phá và Kết nối',
            'Hé lộ bí mật trong các khu vườn rộng. <br/>Kết nối với hàng xóm và xây dựng tình bạn <br/>bền vững ngoài dinh thự.'
        ],
        'txt8': 'Hình ảnh của trò chơi',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Xem các trang mạng <br/>xã hội của chúng tôi.',
        'txt10': 'Tải xuống ngay!',
    },
    // 商品列表
    'product': {
        'Coins': 'Xu',
        'STORE': 'CỬA HÀNG',
        'Extra': 'Thêm',
        'Title1': 'Ai xứng đáng nhận quà <br/>tặng hơn bạn chứ?',
        'Title2': 'Tận hưởng một trong các gói tuyệt <br/>vời để hạnh phúc khôn tả và niềm vui <br/>không gì sánh bằng!',
        'LOGIN': 'ĐĂNG NHẬP',
        'LOGOUT': 'Đăng xuất',
        'UID': 'UID',
        'ShowDetails': 'Hiện chi tiết',
        'PaymentMethods': 'Phương thức thanh toán',
        'MyAccount': 'Tài khoản',
        'UserID': 'UserID',
        'PurchaseProduct': 'Mua sản phẩm',
        'Price': 'Giá',
        'total': 'Tổng tiền',
        'BuyNow': 'Mua ngay',
        'ItemInBundle': 'Vật phẩm theo gói',
        'PleaseSelectProduct': 'Vui lòng chọn mặt hàng bạn muốn mua.',
        'PleaseSelectPay': 'Vui lòng chọn phương thức thanh toán của bạn.'
    },
    'productMobile': {
        'STORE': 'CỬA HÀNG',
        'Extra': 'Thêm',
        'Title1': 'Ai xứng đáng nhận quà <br/>tặng hơn bạn chứ?',
        'Title2': 'Tận hưởng một trong <br/>các gói tuyệt vời để hạnh <br/>phúc khôn tả và niềm vui <br/>không gì sánh bằng!',
        'LOGIN': 'ĐĂNG NHẬP',
        'LOGOUT': 'Đăng xuất',
        'UID': 'UID',
        'ShowDetails': 'Hiện chi tiết',
        'PaymentMethods': 'Phương thức thanh toán',
        'BuyPopTitle': 'Xác nhận đơn thanh toán',
        'MyAccount': 'Tài khoản',
        'UserID': 'UserID',
        'PurchaseProduct': 'Mua sản phẩm',
        'Price': 'Giá',
        'total': 'Tổng tiền',
        'BuyNow': 'Mua ngay',
        'ItemInBundle': 'Vật phẩm theo gói',
        'PleaseSelectProduct': 'Vui lòng chọn mặt hàng bạn muốn mua.',
        'PleaseSelectPay': 'Vui lòng chọn phương thức thanh toán của bạn.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Xóa tài khoản trò chơi',
        'Logout': 'Đăng xuất',
        'Content': 'Vui lòng cấp phép bằng Tài khoản Google, được kết nối với tài khoản trò chơi.',
        'AUTHORIZE': 'Cấp phép',
    },
    'deletionMobile': {
        'Title': 'Xóa tài khoản trò chơi',
        'Logout': 'Đăng xuất',
        'Content': 'Vui lòng cấp phép bằng Tài khoản Google, <br/>được kết nối với tài khoản trò chơi.',
        'AUTHORIZE': 'Cấp phép',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Theo Quy định Bảo vệ Dữ liệu Chung, bạn có quyền xóa tất cả dữ liệu cá nhân được sử dụng trong ứng dụng của chúng tôi và các dịch vụ đối tác của chúng tôi (thanh toán mua hàng, xem quảng cáo trong ứng dụng v.v). Vui lòng ghi nhớ rằng nếu bạn quyết định xóa dữ liệu, tài khoản người dùng sẽ bị xóa và không thể khôi phục lại được. Tuy nhiên sẽ có thể bắt đầu trò chơi từ Cấp độ 1.',
            'Việc xóa tài khoản có thể bị hủy trong vòng 15 ngày.',
            'Để hủy việc xóa, hãy mở trò chơi và nhấn nút Xóa. Lưu ý rằng sau 15 ngày, chúng tôi không thể bảo lưu việc xóa, và trò chơi của bạn sẽ không thể được khôi phục. Cũng hãy lưu ý rằng trong vòng 15 ngày thuộc thời gian xóa, bạn không thể khởi đông trò chơi từ thời điểm bắt đầu.'
        ],
        'DeleteGameAccount': 'Xóa Tài khoản trò chơi',
        'DelTitle': 'Xác nhận',
        'DelContent': 'Bạn có chắc bạn muốn xóa tài khoản trò chơi của mình?',
        'DelCancel': 'Hủy',
        'DelConfirm': 'Có, tôi chắc chắn',
        'DelCodeLabel': [
            'Không tìm thấy tài khoản trò chơi cho người dùng này.', // 0
            'Success! ', // 1
            'Tài khoản trò chơi đã được gửi để xóa.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Yêu cầu xóa dữ liệu của bạn đã được đăng ký thành công.',
        'Tài khoản trò chơi sẽ bị xóa trong vòng 15 ngày kể từ ngày bạn yêu cầu.'
    ]
}
