export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Sunucu hatası, lütfen daha sonra tekrar deneyin.',
        20014: 'Kullanıcı Kimliği mevcut değil, lütfen yeniden gir.', //  游戏用户ID错误
        10002: 'Sunucu hatası, lütfen daha sonra tekrar deneyin. (10002)',// 参数无效
        10008: 'Sunucu hatası, lütfen daha sonra tekrar deneyin. (10008)', // 数据未找到
        30008: 'Sunucu hatası, lütfen daha sonra tekrar deneyin. (30008)', // 商品不存在
        30007: 'Sunucu hatası, lütfen daha sonra tekrar deneyin. (30007)', // 商品币种类型不正确
        10001: 'Sunucu hatası, lütfen daha sonra tekrar deneyin. (10001)', // 服务器开小差
        10000: 'Ağ hatası, lütfen ağ ayarlarınızı kontrol edin.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Satın Alma Başarılı!',
        'SuccessInfo': 'Satın alımın için teşekkürler! <br/>Eşyanı almak için oyunu yeniden başlat ve posta kutunu kontrol et. <br/>(Ekranın sağ üst köşesi -> Posta Kutusu)',
        'SuccessBtn': 'Harika',
        // 失败弹窗
        'ErrorTitle': 'Satın Alma Başarısız!',
        'ErrorInfo': 'Satın alınamadı! Lütfen tekrar dene.',
        'ErrorBtn': 'Onayla'
    },
    // 登录弹窗
    'login': {
        'title1': "Alice'in Dükkânı",
        'title2': "Oyunlar",
        'Or': 'veya',
        'placeholder': 'Kullanıcı kimliğini girin',
        'LogIn': 'Giriş yap',
        'SignInWithFacebook': 'Facebook ile bağlan',
        'SignInWithGoogle': 'Google ile oturum aç',
        'LoginSuccess': 'Giriş başarılı, alışverişe başlayabilirsiniz!',
        'LoginFailed': 'Giriş başarısız! Lütfen girdiğiniz kullanıcı kimliğinin doğru olup olmadığını kontrol edin.',
        'LogOut': 'Başarıyla çıkış yapıldı!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Bu web sitesinde, internet sitemizi kullanırken en iyi deneyimi <br/>yaşamanı sağlamak için çerez kullanılmaktadır.',
        'Accept': 'Kabul et'
    },
    // header部分
    'header': {
        'Menu': ['MAĞAZA', 'Oyunlar', 'Destek', 'Bizi Takip Edin'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Destek',
            'Hizmet Şartları',
            'Gizlilik Politikası',
            'Verilerin Silinmesi'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-tu.html',
            'https://policy.judiangames.com/privacypolicy-tr-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Sırları Çöz, Hayalini Tasarla <br/>- Macera Bekliyor!',
            'Ücretsiz 3\'lü eşleştirme oyunu Garden Affairs\'e katıl, <br/>milyonlarca oyuncuya katıl.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Ücretsiz bulmaca oyunu Garden Affairs\'te <br/>milyonlarca oyuncuya katılın! Hayalinizdeki <br/>yaşamı yaratmak için Alice\'e katılın!'
        ],
        'Custom': [
            'Garden Affairs, aşağıdakiler de dahil olmak üzere heyecan verici yeni özelliklerle dolu:',
            'Tarzını Ortaya Koy',
            'Kişisel tarzını yansıtan mobilya ve dekorlar seç. <br/>Her köşeyi özelleştir, eski köşkü dönüştür.',
            'İlginç Hikayeler',
            'Bahçede gizlenmiş sırları keşfedin. <br/>Hikayenin her dönüşünü takip edin. Yeni arkadaşlar edinin.',
            'Eğlenceli Seviyeler',
            'Eşsiz güçlendiriciler, oyun elementleri ve patlayıcı birleşimlerle zorlu seviyeleri geç. <br/>ulmaca çözme ve eşleştirme heyecanının tadını çıkar.'
        ],
        'GamePictures': 'Oyundan Görüntüler'
    },
    'homePageMobile': {
        'txt': [
            'Sırları Çöz, Hayalini Tasarla <br/>- Macera Bekliyor!',
            'Ücretsiz 3\'lü eşleştirme oyunu <br/>Garden Affairs\'e katıl, <br/>milyonlarca oyuncuya katıl.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Ücretsiz bulmaca oyunu Garden Affairs\'te <br/>milyonlarca oyuncuya katılın! Hayalinizdeki <br/>yaşamı yaratmak için Alice\'e katılın!'
        ],
        'Custom': [
            'Garden Affairs, aşağıdakiler de dahil olmak üzere <br/>heyecan verici yeni özelliklerle dolu:',
            'Tarzını Ortaya Koy',
            'Kişisel tarzını yansıtan mobilya ve dekorlar seç. <br/>Her köşeyi özelleştir, eski köşkü dönüştür.',
            'İlginç Hikayeler',
            'Bahçede gizlenmiş sırları keşfedin. <br/>Hikayenin her dönüşünü takip edin. <br/>Yeni arkadaşlar edinin.',
            'Eğlenceli Seviyeler',
            'Eşsiz güçlendiriciler, oyun elementleri <br/>ve patlayıcı birleşimlerle zorlu seviyeleri geç. <br/>ulmaca çözme ve eşleştirme heyecanının tadını çıkar.'
        ],
        'GamePictures': 'Oyundan Görüntüler'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Sırları Çöz, Hayalini Tasarla <br/>- Macera Bekliyor!',
        'extra': 'Ekstra %5',
        'extraFontSize': '1.3',
        'store': 'MAĞAZA',
        'storeFontSize': '1.8',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Ücretsiz 3\'lü eşleştirme oyunu <br/>Garden Affairs\'e katıl, <br/>milyonlarca oyuncuya katıl.',
        'txt3': 'Miras kalan harabe mülkünü çocukluk arkadaşın ve <br/>ünlü tasarımcı Alice ile hayalindeki yuvaya dönüştür. <br/>Köşkün nefes kesici bir eve dönüşmesini izle!',
        'txt4': [
            'Eşseyir\'in Merkezi ile Tanış',
            'Eşseyir\'e hayat veren kıpır kıpır mahalle sakinlerine merhaba de:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'Hayallerindeki evi gerçeğe <br/>dönüştürmeye hazır ileri görüşlü <br/>iç mimar',
            'Bitki tutkunu, <br/>sevimli çiçek yetiştiricisi',
            'Mutfak hünerlerinin yanı <br/>sıra pek çok sır saklayan aşçı',
            'Yumuşak bir kalbe <br/>sahip ünlü tasarımcı',
            'Kasabanın en iyi marangozu <br/>ve en yakın dostun',
            'Köşke uzun süredir derinden <br/>bağlı, tecrübeli bahçıvan'
        ],
        'txt5': [
            'Baş Döndürücü Çizimler',
            'Baş döndürücü açılış sinematiğini keşfet ve tasarım <br/>ayrıntılarına hayran kal.'
        ],
        'txt6': [
            'Tarzını Ortaya Koy',
            'Kişisel tarzını yansıtan mobilya ve dekorlar seç. <br/>Her köşeyi özelleştir, eski köşkü dönüştür.',
            'Eğlenceli Seviyeler',
            'Eşsiz güçlendiriciler, oyun elementleri ve patlayıcı birleşimlerle zorlu seviyeleri geç. <br/>Bulmaca çözme ve eşleştirme heyecanının tadını çıkar.'
        ],
        'txt7': [
            'Keşfet ve <br/>Arkadaşlıklar Kur',
            'Geniş bahçelerdeki sırları keşfet. <br/>Komşularla bağ kur ve uzun süreli <br/>arkadaşlıklara adım at.'
        ],
        'txt8': 'Oyundan Görüntüler',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Sosyal Medya Kanallarımıza göz at',
        'txt10': 'Şimdi indir!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Sırları Çöz, Hayalini Tasarla <br/>- Macera Bekliyor!',
        'extra': 'Ekstra %5',
        'extraFontSize': '1.5',
        'store': 'MAĞAZA',
        'storeFontSize': '2',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Ücretsiz 3\'lü eşleştirme oyunu <br/>Garden Affairs\'e katıl, <br/>milyonlarca oyuncuya katıl.',
        'txt3': 'Miras kalan harabe mülkünü çocukluk <br/>arkadaşın ve ünlü tasarımcı Alice ile hayalindeki <br/>yuvaya dönüştür. Köşkün nefes kesici bir eve <br/>dönüşmesini izle!',
        'txt4': [
            'Eşseyir\'in Merkezi ile Tanış',
            'Eşseyir\'e hayat veren kıpır kıpır <br/>mahalle sakinlerine merhaba de:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'Hayallerindeki evi gerçeğe <br/>dönüştürmeye hazır ileri görüşlü <br/>iç mimar',
            'Yumuşak bir kalbe <br/>sahip ünlü tasarımcı',
            'Bitki tutkunu, <br/>sevimli çiçek yetiştiricisi',
            'Kasabanın en iyi marangozu <br/>ve en yakın dostun',
            'Mutfak hünerlerinin yanı <br/>sıra pek çok sır saklayan aşçı',
            'Köşke uzun süredir derinden <br/>bağlı, tecrübeli bahçıvan'
        ],
        'txt5': [
            'Baş Döndürücü Çizimler',
            'Baş döndürücü açılış sinematiğini keşfet <br/ve tasarım >ayrıntılarına hayran kal.'
        ],
        'txt6': [
            'Tarzını Ortaya Koy',
            'Kişisel tarzını yansıtan mobilya <br/>ve dekorlar seç. Her köşeyi <br/>özelleştir, eski köşkü dönüştür.',
            'Eğlenceli Seviyeler',
            'Eşsiz güçlendiriciler, oyun elementleri <br/>ve patlayıcı birleşimlerle zorlu seviyeleri geç. <br/>Bulmaca çözme ve eşleştirme heyecanının <br/>tadını çıkar.'
        ],
        'txt7': [
            'Keşfet ve <br/>Arkadaşlıklar Kur',
            'Geniş bahçelerdeki sırları keşfet. <br/>Komşularla bağ kur ve uzun süreli <br/>arkadaşlıklara adım at.'
        ],
        'txt8': 'Oyundan Görüntüler',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Sosyal Medya <br/>Kanallarımıza göz at',
        'txt10': 'Şimdi indir!',
    },
    // 商品列表
    'product': {
        'Coins': 'Sikke',
        'STORE': 'MAĞAZA',
        'Extra': 'Ekstra',
        'Title1': 'Hediyeleri Senden Daha <br/>Çok Hak Eden Biri Var Mı?',
        'Title2': 'Mutluluk ve eğlence kaynağı <br/>muhteşem paketlerimizden biriyle <br/>kendini şımart!',
        'LOGIN': 'GİRİŞ YAP',
        'LOGOUT': 'Çıkış yap',
        'UID': 'UID',
        'ShowDetails': 'Ayrıntıları göster',
        'PaymentMethods': 'Ödeme Yöntemleri',
        'MyAccount': 'Hesabım',
        'UserID': 'UserID',
        'PurchaseProduct': 'Eşya Satın Al',
        'Price': 'Fiyat',
        'total': 'Toplam',
        'BuyNow': 'Hemen satın al',
        'ItemInBundle': 'Paket Eşyaları',
        'PleaseSelectProduct': 'Satın almak istediğiniz ürünü seçin lütfen.',
        'PleaseSelectPay': 'Lütfen ödeme yönteminizi seçin.'
    },
    'productMobile': {
        'STORE': 'MAĞAZA',
        'Extra': 'Ekstra',
        'Title1': 'Hediyeleri Senden Daha <br/>Çok Hak Eden Biri Var Mı?',
        'Title2': 'Mutluluk ve eğlence <br/>kaynağı muhteşem <br/>paketlerimizden biriyle <br/>kendini şımart!',
        'LOGIN': 'GİRİŞ YAP',
        'LOGOUT': 'Çıkış yap',
        'UID': 'UID',
        'ShowDetails': 'Ayrıntıları göster',
        'PaymentMethods': 'Ödeme Yöntemleri',
        'BuyPopTitle': 'Ödeme siparişini onayla',
        'MyAccount': 'Hesabım',
        'UserID': 'UserID',
        'PurchaseProduct': 'Eşya Satın Al',
        'Price': 'Fiyat',
        'total': 'Toplam',
        'BuyNow': 'Hemen satın al',
        'ItemInBundle': 'Paket Eşyaları',
        'PleaseSelectProduct': 'Satın almak istediğiniz ürünü seçin lütfen.',
        'PleaseSelectPay': 'Lütfen ödeme yönteminizi seçin.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Oyun Hesabı Silinmesi',
        'Logout': 'Çıkış yap',
        'Content': 'Lütfen oyun hesabına bağlı Google Hesabını kullanarak yetkilendir.',
        'AUTHORIZE': 'Yetkilendir',
    },
    'deletionMobile': {
        'Title': 'Oyun Hesabı Silinmesi',
        'Logout': 'Çıkış yap',
        'Content': 'Lütfen oyun hesabına bağlı Google <br/>Hesabını kullanarak yetkilendir.',
        'AUTHORIZE': 'Yetkilendir',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Genel Veri Koruma Yönetmeliği uyarınca, uygulamamızda ve ortaklarımız tarafından sunulan hizmetlerde (satın alma ödemeleri, uygulama içi reklam görüntülemeleri vb.) kullanılan tüm kişisel verilerinin silinmesini sağlama hakkına sahipsin. Verilerinin silinmesine karar vermen durumunda lütfen kullanıcı hesabının silineceğini ve bu eylemin geri alınamayacağını lütfen unutma; yine de oyuna 1. seviyeden başlayabilirsin.',
            'Hesap silme eylemi 15 gün içerisinde iptal edilebilir.',
            'Silme eylemini iptal etmek için oyunu aç ve "İptal et" düğmesine dokun. Lütfen 15 gün sonra silme eylemini geri alamayacağımızı ve oyununun geri yüklenemeyeceğini unutma, ayrıca veri silme eylemi gerçekleştirilirken 15 gün boyunca oyuna en baştan başlayamazsın.'
        ],
        'DeleteGameAccount': 'Oyun Hesabını Sil',
        'DelTitle': 'Onayla',
        'DelContent': 'Oyun hesabını silmek istediğinden emin misin?',
        'DelCancel': 'İptal et',
        'DelConfirm': 'Evet, eminim',
        'DelCodeLabel': [
            'Bu kullanıcı için oyun hesabı bulunamadı.', // 0
            'Success! ', // 1
            'Oyun hesabı silinmek üzere gönderildi.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Veri silinmesi talebi başarıyla kaydedildi.',
        'Oyun hesabı, talebin oluşturulduğu tarihten itibaren 15 gün içerisinde silinir.'
    ]
}
