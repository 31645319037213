import Layout from "@/layout/index.vue";
import LandingPage from './landingPage/index.js';

export default [
  {
    name: 'Layout',
    component: Layout,
    path: '/',
    redirect: '/index',
    children: [
      // demo
      {
        path: '/index',
        name: 'HomePage',
        component: () => import('@/views/homePage/index.vue'),
        meta: {
          title: '首页'
        }
      },
      // 正式
      {
        name: 'Store',
        path: '/store',
        component: () => import('@/views/store/index.vue'),
        meta: {
          title: '商店页'
        }
      },
      // game
      {
        name: 'games',
        path: '/games',
        component: () => import( '@/views/games/index.vue'),
        meta: {
          title: 'Games'
        }
      },
      {
        name: 'deletion',
        path: '/deletion',
        component: () => import( '@/views/deletion/index.vue'),
        meta: {
          title: 'Deletion'
        }
      },
      {
        name: 'AuthSuccess',
        path: '/auth-success',
        component: () => import( '@/views/deletion/authSuccess.vue'),
        meta: {
          title: 'AuthSuccess'
        }
      },
      {
        name: 'DelSuccess',
        path: '/del-success',
        component: () => import( '@/views/deletion/delSuccess.vue'),
        meta: {
          title: '删除成功'
        }
      },
    ]
  },
  ...LandingPage,
  {
    name: '404',
    path: '/:pathMatch',
    hidden: true,
    component: () => import('@/components/exception/404.vue'),
    meta: {
      title: '404'
    }
  },
]
