export default {
  'imageAlt': 'Garden Affairs',
  // 全局error Code Map
  'errorCode': {
    'default': 'Server exception, please try again later.',
    20014: 'User ID doesn\'t exist, please re-enter.', //  游戏用户ID错误
    10002: 'Server exception, please try again later. (10002)',// 参数无效
    10008: 'Server exception, please try again later. (10008)', // 数据未找到
    30008: 'Server exception, please try again later. (30008)', // 商品不存在
    30007: 'Server exception, please try again later. (30007)', // 商品币种类型不正确
    10001: 'Server exception, please try again later. (10001)', // 服务器开小差
    10000: 'Network abnormality, please check your network settings.', // 网络异常"
  },
  // code map 下单时 映射弹窗 内容
  'codeMap': {
    // 成功弹窗
    'SuccessTitle': 'Purchase Successful! ',
    'SuccessInfo': 'Thanks for your purchase!<br/>Restart the game and check your mailbox to collect your item. <br/>(Upper right corner of the screen -> Mailbox)',
    'SuccessBtn': 'Great!',
    // 失败弹窗
    'ErrorTitle': 'Purchase Failed!',
    'ErrorInfo': 'Purchase failed! Try again later.',
    'ErrorBtn': 'OK'
  },
  // 登录弹窗
  'login': {
    'title1': "ALICE'S SHOP",
    'title2': "G A M E S",
    'Or': 'OR',
    'placeholder': 'Enter user ID',
    'LogIn': 'Login',
    'SignInWithFacebook': 'Sign in with Facebook',
    'SignInWithGoogle': 'Sign in with Google',
    'LoginSuccess': 'Login successfully, you can start shopping now!',
    'LoginFailed': 'Login failed! Please check if the user ID you entered is incorrect.',
    'LogOut': 'Log out successfully!',
  },
  // cookies tips
  'cookiesTip': {
    'Content': 'This website uses cookies to ensure you get the best experience on our website.',
    'Accept': 'Accept'
  },
  // header部分
  'header': {
    'Menu': ['STORE', 'Games', 'Support', 'Follow Us'],
    'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
    'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
  },
  // 底部 footer
  'footer': {
    'Menu': [
      'Support',
      'Terms of Service',
      'Privacy Policy',
      'Data Deletion'
    ],
    'MenuPath': [
      'Support',
      'https://policy.judiangames.com/termsofservice.html',
      'https://policy.judiangames.com/privacypolicy.html',
      '/deletion'
    ],
    'LanguagePlaceholder': 'language',
  },
  // homePage
  'homePage': {
    'txt': [
      'Uncover the Secrets, Design Your Dream —Start Your Adventure!',
      'Dive into Garden Affairs, a captivating free-to-play match-3 puzzle game <br/>that has enchanted millions. '
    ],
    'GardenAffairs': [
      'Garden Affairs',
      'Join millions of players in Garden Affairs,<br/>the free-to-play puzzle game! Come and join Alice in <br/>creating the life you\'ve always dreamed of!'
    ],
    'Custom': [
      'Garden Affairs is packed with exciting new features, including:',
      'Express Your Style',
      'Select furniture and decorations that reflect your personal style. <br/>Customize every corner of your house, turning the old manor into a stunning masterpiece.',
      'Intriguing Stories:',
      'Explore the secrets hidden in the garden. <br/>Follow every turn in the plot. Make new friends.',
      'Fun Levels:',
      'Master challenging levels with unique boosters, game elements, and explosive combinations. <br/>Experience the thrill of solving puzzles and the satisfaction of matching.'
    ],
    'GamePictures': 'Game Pictures'
  },
  'homePageMobile': {
    'txt': [
      'Uncover the Secrets,<br/>Design Your Dream<br/>—Start Your Adventure!',
      'Dive into Garden Affairs,<br/> a captivating free-to-play match-3 <br/>puzzle game that has enchanted millions. '
    ],
    'GardenAffairs': [
      'Garden Affairs',
      'Join millions of players in Garden Affairs,<br/>the free-to-play puzzle game! Come and join Alice<br/> in creating the life you\'ve always dreamed of!'
    ],
    'Custom': [
      'Garden Affairs is packed with exciting <br/>new features, including:',
      'Express Your Style',
      'Select furniture and decorations that reflect your <br/>personal style. Customize every corner of your house, <br/>turning the old manor into a stunning masterpiece.',
      'Intriguing Stories:',
      'Explore the secrets hidden in the garden.<br/>Follow every turn in the plot. Make new friends.',
      'Fun Levels:',
      'Master challenging levels with unique boosters, <br/>game elements, and explosive combinations. <br/>Experience the thrill of solving puzzles and the <br/>satisfaction of matching.'
    ],
    'GamePictures': 'Game Pictures'
  },
  // GAME页 pc端
  'games': {
    'GardenAffairs': 'games/logo.png',
    'txt1': 'Uncover the Secrets, Design Your Dream<br/>—Start Your Adventure!',
    'extra': '5% EXTRA',
    'store': 'STORE',
    'PeopleIcon': 'games/icon.png',
    'txt2': 'Dive into Garden Affairs, a captivating' +
      '<br/>free-to-play match-3 puzzle game<br/>that has enchanted millions. ',
    'txt3': 'You’ve just inherited a grand but dilapidated estate, so you enlist your' +
      '<br/>childhood friend and renowned interior designer, Alice, to transform it into the' +
      '<br/>home of your dreams. With dedication, creativity, and a dash of your design' +
      '<br/>expertise, watch as the manor turns into a breathtaking dream house!'
    ,
    'txt4': [
      'Meet the Heart of Matchview',
      'Get acquainted with the vibrant locals who bring Matchview to life:'
    ],
    'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people2.png',
      'games/people3.png',
      'games/people4.png',
      'games/people5.png',
      'games/people6.png',
    ],
    'PeopleDesc': [
      'The visionary interior designer <br/>ready to realize your dream home',
      'The charming florist with <br/>a passion for blossoming flowers',
      'The chef hiding more up<br/>his sleeves than ingredients',
      'The celebrity designer<br/>with a caring heart',
      'The best carpenter in town<br/>and also best friend',
      'The seasoned gardener with <br/>deep roots in the manor’s history'
    ],
    'txt5': [
      'Stunning Visuals',
      'Experience the stunning opening cinematic and marvel at the meticulous' +
      '<br/>detail in every design choice.'
    ],
    'txt6': [
      'Express Your Style',
      'Select furniture and decorations that reflect your personal style.' +
      '<br/>Customize every corner of your house, turning the old manor' +
      '<br/>into a stunning masterpiece.',
      'Fun Levels:',
      'Master challenging levels with unique boosters, game elements, and' +
      '<br/>explosive combinations.' +
      '<br/>Experience the thrill of solving puzzles and the satisfaction of matching.'
    ],
    'txt7': [
      'Discover and<br/>Connect',
      'Uncover the mysteries hidden within the expansive' +
      '<br/>gardens.' +
      '<br/>Connect with neighbors and forge lasting friendships' +
      '<br/>beyond the estate walls.'
    ],
    'txt8': 'Game Pictures',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': 'Check out our Social Media',
    'txt10': 'Download now!',
  },
  // GAME页 移动端
  'gamesMobile': {
    'GardenAffairs': 'games/logo.png',
    'txt1': 'Uncover the Secrets, Design Your Dream<br/>—Start Your Adventure!',
    'extra': '5% EXTRA',
    'store': 'STORE',
    'PeopleIcon': 'games/icon.png',
    'txt2': 'Dive into Garden Affairs, a captivating<br/>free-to-play match-3 puzzle game<br/>that has enchanted millions.',
    'txt3': 'You’ve just inherited an estate, ' +
      '<br/>so you ask your childhood friend and interior' +
      '<br/>designer Alice to help renovate the manor.' +
      '<br/>Although the manor is in a bad condition,' +
      '<br/>with a little bit of hardwork and your expert' +
      '<br/>designer opinion, the estate can soon become' +
      '<br/>your dreamhouse!',
    'txt4': [
      'Meet the Heart of Matchview',
      'Become friends with all the<br/>interesting people of Matchview  '
    ],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people4.png',
      'games/people2.png',
      'games/people5.png',
      'games/people3.png',
      'games/people6.png',
    ],
    'PeopleName': [
      'ALICE',
      'NELLIE',
      'JOEY',
      'TAYLOR',
      'STEFANO',
      'ALAN'
    ],
    'PeopleDesc': [
      'The visionary interior designer <br/>ready to realize your dream home',
      'The celebrity designer<br/>with a caring heart',
      'The charming florist with <br/>a passion for blossoming flowers',
      'The best carpenter in town<br/>and also best friend',
      'The chef hiding more up<br/>his sleeves than ingredients',
      'The seasoned gardener with <br/>deep roots in the manor’s history'
    ],
    'txt5': [
      'Stunning Visuals',
      'Experience the stunning opening cinematic and' +
      '<br/>marvel at the meticulous' +
      '<br/>detail in every design choice.'
    ],
    'txt6': [
      'Express Your Style',
      'Select furniture and decorations that reflect' +
      '<br/>your personal style.Customize every' +
      '<br/>corner of your house, turning the old manor' +
      '<br/>into a stunning masterpiece.',
      'Fun Levels',
      'Master challenging levels with unique boosters,' +
      '<br/>game elements, and explosive combinations.' +
      '<br/>Experience the thrill of solving puzzles and the' +
      '<br/>satisfaction of matching.'
    ],
    'txt7': [
      'Discover and Connect',
      'Uncover the mysteries hidden within' +
      '<br/>the expansive gardens.' +
      '<br/>Connect with neighbors and forge lasting' +
      '<br/>friendships beyond the estate walls.'
    ],
    'txt8': 'Game Pictures',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': 'Check out our <br/>Social Media',
    'txt10': 'Download now!'
  },
  // 商品列表
  'product': {
    'Coins': 'Coins',
    'STORE': 'STORE',
    'Extra': 'EXTRA',
    'Title1': 'Who Deserves a Gift<br/>More Than You?',
    'Title2': 'Indulge in one of our awesome' +
      '<br/>packs for ultimate happiness and' +
      '<br/>unbeatable fun!',
    'LOGIN': 'LOG IN',
    'LOGOUT': 'LOG OUT',
    'UID': 'UID',
    'ShowDetails': 'Show details',
    'PaymentMethods': 'Payment Methods',
    'MyAccount': 'My Account',
    'UserID': 'UserID',
    'PurchaseProduct': 'Purchase Product',
    'Price': 'Price',
    'total': 'total',
    'BuyNow': 'Buy now',
    'ItemInBundle': 'Bundle Items',
    'PleaseSelectProduct': 'Please select the item you wish to purchase.',
    'PleaseSelectPay': 'Please choose your payment method.'
  },
  'productMobile': {
    'STORE': 'STORE',
    'Extra': 'EXTRA',
    'Title1': 'Who Deserves a Gift<br/>More Than You?',
    'Title2': 'Indulge in one of<br/>our awesome packs for<br/>ultimate happiness and<br/>unbeatable fun!',
    'LOGIN': 'LOG IN',
    'LOGOUT': 'LOG OUT',
    'UID': 'UID',
    'ShowDetails': 'Show details',
    'PaymentMethods': 'Payment Methods',

    'BuyPopTitle': 'CONFIRM PAYMENT ORDER',
    'MyAccount': 'My Account',
    'UserID': 'UserID',
    'PurchaseProduct': 'Purchase Product',
    'Price': 'Price',
    'total': 'total',
    'BuyNow': 'Buy now',
    'ItemInBundle': 'Bundle Items',
    'PleaseSelectProduct': 'Please select the item you wish to purchase.',
    'PleaseSelectPay': 'Please choose your payment method.'
  },
  // deletion 页
  'deletion': {
    'Title': 'Game Account Deletion',
    'Logout': 'LOG OUT',
    'Content': 'Please, authorize using Google Account, connected with your game account.',
    'AUTHORIZE': 'AUTHORIZE',
  },
  'deletionMobile': {
    'Title': 'Game Account Deletion',
    'Logout': 'LOG OUT',
    'Content': 'Please, authorize using Google Account,<br/> connected with your game account.',
    'AUTHORIZE': 'AUTHORIZE',
  },
  // authSuccess 页
  'authSuccess': {
    'content': [
      'Under the General Data Protection Regulation, you have the right to have all of your personal data used in our application and our partners\' services (purchase payments, in-app ad views, etc.) deleted. Please keep in mind that should you decide to delete the data, the user account will be deleted and will not be restorable. However, it will be possible to start the game over from Level 1.',
      'The account deletion may be canceled within 15 days. ',
      'To cancel the deletion, open the game and press the Cancel button. Kindly note that after 15 days, we cannot reverse the deletion, and your game cannot be restored. Please also consider that within 15 days during the deletion period, you cannot restart the game from the beginning.'
    ],
    'DeleteGameAccount': 'Delete Game Account',
    'DelTitle': 'Confirm',
    'DelContent': 'Are you sure you want to delete your game account?',
    'DelCancel': 'Cancel',
    'DelConfirm': 'Yes, I’m sure',
    'DelCodeLabel': [
      'Game account for this user is not found.', // 0
      'Success! ', // 1
      'The game account has been submitted for deletion.' // 2
    ]
  },
  // Del Success 页
  'delSuccess': [
    'Your request for data deletion is successfully registered.',
    'Game account will be deleted within 15 days from your request.'
  ]
}
