export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Serverfout, probeer het later opnieuw.',
        20014: 'Gebruikers-ID bestaat niet, voer opnieuw in.', //  游戏用户ID错误
        10002: 'Serverfout, probeer het later opnieuw. (10002)',// 参数无效
        10008: 'Serverfout, probeer het later opnieuw. (10008)', // 数据未找到
        30008: 'Serverfout, probeer het later opnieuw. (30008)', // 商品不存在
        30007: 'Serverfout, probeer het later opnieuw. (30007)', // 商品币种类型不正确
        10001: 'Serverfout, probeer het later opnieuw. (10001)', // 服务器开小差
        10000: 'Netwerkfout, controleer uw netwerkinstellingen.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Aankoop Voltooid!',
        'SuccessInfo': 'Bedankt voor je aankoop! <br/>Start het spel opnieuw en controleer je postvak om je item op te halen. <br/>(Rechterbovenhoek van het scherm -> Mailbox)',
        'SuccessBtn': 'Goed',
        // 失败弹窗
        'ErrorTitle': 'Aankoop mislukt!',
        'ErrorInfo': 'Aankoop mislukt! Probeer het nogmaals',
        'ErrorBtn': 'Oké'
    },
    // 登录弹窗
    'login': {
        'title1': "Alices Winkel",
        'title2': "Spellen",
        'Or': 'of',
        'placeholder': 'Voer gebruikers-ID in',
        'LogIn': 'Meld Aan',
        'SignInWithFacebook': 'Aanmelden met Facebook',
        'SignInWithGoogle': 'Aanmelden met Google',
        'LoginSuccess': 'Succesvol ingelogd, u kunt nu beginnen met winkelen!',
        'LoginFailed': 'Inloggen mislukt! Controleer of uw gebruikers-ID correct is.',
        'LogOut': 'Succesvol uitgelogd!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Deze website maakt gebruik van cookies om ervoor te zorgen <br/>dat je de beste ervaring krijgt op onze website.',
        'Accept': 'Accepteer'
    },
    // header部分
    'header': {
        'Menu': ['WINKEL', 'Spellen', 'Ondersteuning', 'Volg ons'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Ondersteuning',
            'Gebruikersvoorwaarden',
            'Privacybeleid',
            'Data Verwijderen'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-nl.html',
            'https://policy.judiangames.com/privacypolicy-nl-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Ontdek geheimen, ontwerp je droom - begin je avontuur!',
            'Duik in Tuin Trubbels, een gratis match-3 puzzelspel dat miljoenen betoverde.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Doe mee met miljoenen in Garden Affairs, <br/>het gratis puzzelspel! <br/>Creëer met Alice het leven van je dromen!'
        ],
        'Custom': [
            'Garden Affairs zit boordevol spannende nieuwe functies, waaronder:',
            'Laat je stijl zien',
            'Kies meubels en decoraties die jouw stijl weerspiegelen. <br/>Transformeer elk hoekje van het landhuis tot een prachtig meesterwerk.',
            'Intrigerende Verhalen',
            'Ontdek de geheimen die in de tuin verborgen zijn. <br/>Volg elke wending in het plot. Maak nieuwe vrienden.',
            'Giải trí',
            'Meester uitdagende levels met unieke boosters. <br/>Geniet van het oplossen van puzzels en maken van explosieve combinaties.'
        ],
        'GamePictures': 'Spel Plaatjes'
    },
    'homePageMobile': {
        'txt': [
            'Ontdek geheimen, ontwerp je <br/>droom - begin je avontuur!',
            'Duik in Tuin Trubbels, <br/>een gratis match-3 puzzelspel <br/>dat miljoenen betoverde.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Doe mee met miljoenen in Garden Affairs, <br/>het gratis puzzelspel! <br/>Creëer met Alice het leven van je dromen!'
        ],
        'Custom': [
            'Garden Affairs zit boordevol spannende <br/>nieuwe functies, waaronder:',
            'Laat je stijl zien',
            'Kies meubels en decoraties die jouw stijl <br/>weerspiegelen. Transformeer elk hoekje van <br/>het landhuis tot een prachtig meesterwerk.',
            'Intrigerende Verhalen',
            'Ontdek de geheimen die in de tuin verborgen zijn. <br/>Volg elke wending in het plot. Maak nieuwe vrienden.',
            'Giải trí',
            'Meester uitdagende levels met unieke boosters. <br/>Geniet van het oplossen van puzzels en maken <br/>van explosieve combinaties.'
        ],
        'GamePictures': 'Spel Plaatjes'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Ontdek geheimen, ontwerp je droom <br/>- begin je avontuur!',
        'extra': '5% Extra',
        'store': 'WINKEL',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Duik in Tuin Trubbels, een gratis <br/>match-3 puzzelspel dat miljoenen <br/>betoverde.',
        'txt3': 'Je hebt een vervallen landgoed geërfd en vraagt je jeugdvriendin <br/>Alice om het om te toveren tot je droomhuis. <br/>Met toewijding en creativiteit wordt het een adembenemend huis!',
        'txt4': [
            'Ontmoet het hart van Matchview.',
            'Ontmoet de levendige bewoners die Matchview tot leven brengen:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'Ontwerpers maken uw <br/> dromen waar',
            'De charmante bloemist met <br/>een passie voor bloemen',
            'De chef-kok verbergt meer <br/>dan alleen ingrediënten',
            'De beroemde ontwerper met <br/>een zorgzaam karakter',
            'De beste timmerman in de stad <br/>tevens de beste vriend',
            'De ervaren tuinier met <br/>diepe wortels in de historie <br/>van het landhuis'
        ],
        'txt5': [
            'Verbluffende beelden',
            'Ervaar het verbluffende openingsfilmpje en bewonder <br/>het minutieuze detail in elke ontwerpkeuze.'
        ],
        'txt6': [
            'Laat je stijl zien',
            'Kies meubels en decoraties die jouw stijl weerspiegelen. <br/>Transformeer elk hoekje van het landhuis tot een prachtig <br/>meesterwerk.',
            'Leuke Levels',
            'Meester uitdagende levels met unieke boosters. <br/>Geniet van het oplossen van puzzels en maken van explosieve combinaties.'
        ],
        'txt7': [
            'Ontdekken en <br/>verbinden',
            'Ontdek verborgen mysteries in de <br/>uitgestrekte tuinen en smeed vriendschappen <br/>buiten het landgoed.'
        ],
        'txt8': 'Spel Plaatjes',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Bekijk ons social media',
        'txt10': 'Download nu!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Ontdek geheimen, ontwerp je droom <br/>- begin je avontuur!',
        'extra': '5% Extra',
        'store': 'WINKEL',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Duik in Tuin Trubbels, een <br/>gratis match-3 puzzelspel dat <br/>miljoenen betoverde.',
        'txt3': 'Je hebt een vervallen landgoed geërfd en <br/>vraagt je jeugdvriendin Alice om het om te <br/>toveren tot je droomhuis. Met toewijding en <br/>creativiteit wordt het een adembenemend huis!',
        'txt4': [
            'Ontmoet het hart van Matchview.',
            'Ontmoet de levendige bewoners die <br/>Matchview tot leven brengen:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'Ontwerpers maken uw dromen waar',
            'De beroemde ontwerper met <br/>een zorgzaam karakter',
            'De charmante bloemist met <br/>een passie voor bloemen',
            'De beste timmerman in de stad <br/>tevens de beste vriend',
            'De chef-kok verbergt meer <br/>dan alleen ingrediënten',
            'De ervaren tuinier met <br/>diepe wortels in de historie <br/>van het landhuis'
        ],
        'txt5': [
            'Verbluffende beelden',
            'Ervaar het verbluffende openingsfilmpje en <br/>bewonder het minutieuze detail in elke <br/>ontwerpkeuze.'
        ],
        'txt6': [
            'Laat je stijl zien',
            'Kies meubels en decoraties die jouw stijl <br/>weerspiegelen. Transformeer elk hoekje van <br/>het landhuis tot een prachtig meesterwerk.',
            'Leuke Levels',
            'Meester uitdagende levels met unieke <br/>boosters. Geniet van het oplossen van puzzels <br/>en maken van explosieve combinaties.'
        ],
        'txt7': [
            'Ontdekken en verbinden',
            'Ontdek verborgen mysteries in de <br/>uitgestrekte tuinen en smeed <br/>vriendschappen buiten het landgoed.'
        ],
        'txt8': 'Spel Plaatjes',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Bekijk ons <br/>social media',
        'txt10': 'Download nu!'
    },
    // 商品列表
    'product': {
        'Coins': 'Munten',
        'STORE': 'WINKEL',
        'Extra': 'Extra',
        'Title1': 'Wie verdient een cadeau <br/>meer dan jij?',
        'Title2': 'Verwen jezelf met een van onze <br/>geweldige pakketten voor ultiem <br/>geluk en onverslaanbaar plezier!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Uitloggen',
        'UID': 'UID',
        'ShowDetails': 'Bekijk details',
        'PaymentMethods': 'Betaal Methodes',
        'MyAccount': 'Mijn Account',
        'UserID': 'UserID',
        'PurchaseProduct': 'Koop Product',
        'Price': 'Prijs',
        'total': 'Totaal',
        'BuyNow': 'Koop nu',
        'ItemInBundle': 'Bundle items',
        'PleaseSelectProduct': 'Selecteer het item dat u wilt kopen.',
        'PleaseSelectPay': 'Kies uw betaalmethode alstublieft.'
    },
    'productMobile': {
        'STORE': 'WINKEL',
        'Extra': 'Extra',
        'Title1': 'Wie verdient een cadeau <br/>meer dan jij?',
        'Title2': 'Verwen jezelf met een <br/>van onze geweldige <br/>pakketten voor ultiem <br/>geluk en onverslaanbaar <br/>plezier!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Uitloggen',
        'UID': 'UID',
        'ShowDetails': 'Bekijk details',
        'PaymentMethods': 'Betaal Methodes',
        'BuyPopTitle': 'Bevestig betalingsopdracht',
        'MyAccount': 'Mijn Account',
        'UserID': 'UserID',
        'PurchaseProduct': 'Koop Product',
        'Price': 'Prijs',
        'total': 'Totaal',
        'BuyNow': 'Koop nu',
        'ItemInBundle': 'Bundle items',
        'PleaseSelectProduct': 'Selecteer het item dat u wilt kopen.',
        'PleaseSelectPay': 'Kies uw betaalmethode alstublieft.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Spelaccount verwijderen',
        'Logout': 'Uitloggen',
        'Content': 'Autoriseer met een Google-account dat verbonden is met je spelaccount.',
        'AUTHORIZE': 'Autoriseer',
    },
    'deletionMobile': {
        'Title': 'Spelaccount verwijderen',
        'Logout': 'Uitloggen',
        'Content': 'Autoriseer met een Google-account <br/>dat verbonden is met je spelaccount.',
        'AUTHORIZE': 'Autoriseer',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Onder de Algemene Verordening Gegevensbescherming heb je het recht om al je persoonlijke gegevens die worden gebruikt in onze applicatie en de diensten van onze partners (aankoopbetalingen, in-app advertentieweergaven, etc.) te laten verwijderen. Houd er rekening mee dat als je besluit de gegevens te verwijderen, het gebruikersaccount wordt verwijderd en niet meer kan worden hersteld. Het is echter wel mogelijk om het spel opnieuw te beginnen vanaf level 1.',
            'De accountverwijdering kan binnen 15 dagen worden geannuleerd.',
            'Om de verwijdering ongedaan te maken, open je het spel en druk je op de knop Annuleren. Houd er rekening mee dat we de verwijdering na 15 dagen niet ongedaan kunnen maken en dat je spel niet kan worden hersteld. Houd er ook rekening mee dat je binnen 15 dagen tijdens de verwijderingsperiode het spel niet vanaf het begin opnieuw kunt starten.'
        ],
        'DeleteGameAccount': 'Verwijder Spel Account',
        'DelTitle': 'Bevestig',
        'DelContent': 'Weet je zeker dat je je spelaccount wilt verwijderen?',
        'DelCancel': 'Annuleer',
        'DelConfirm': 'Ja, zeker',
        'DelCodeLabel': [
            'Spelaccount voor deze gebruiker niet gevonden.', // 0
            'Success! ', // 1
            'Het spelaccount is ter verwijdering ingediend.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Je verzoek om gegevens te verwijderen is succesvol geregistreerd.',
        'Spelaccount wordt binnen 15 dagen na je verzoek verwijderd.'
    ]
}
