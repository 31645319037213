export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'ข้อผิดพลาดของเซิร์ฟเวอร์ กรุณาลองใหม่ภายหลัง',
        20014: 'ไม่มี ID ผู้ใช้นี้ โปรดระบุใหม่อีกครั้ง', //  游戏用户ID错误
        10002: '้อผิดพลาดของเซิร์ฟเวอร์ กรุณาลองใหม่ภายหลัง (10002)',// 参数无效
        10008: '้อผิดพลาดของเซิร์ฟเวอร์ กรุณาลองใหม่ภายหลัง (10008)', // 数据未找到
        30008: '้อผิดพลาดของเซิร์ฟเวอร์ กรุณาลองใหม่ภายหลัง (30008)', // 商品不存在
        30007: '้อผิดพลาดของเซิร์ฟเวอร์ กรุณาลองใหม่ภายหลัง (30007)', // 商品币种类型不正确
        10001: '้อผิดพลาดของเซิร์ฟเวอร์ กรุณาลองใหม่ภายหลัง (10001)', // 服务器开小差
        10000: 'ข้อผิดพลาดของเครือข่าย โปรดตรวจสอบการตั้งค่าเครือข่ายของคุณ', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'การซื้อสำเร็จ!',
        'SuccessInfo': 'ขอบคุณสำหรับการซื้อ! <br/>รีสตาร์ทเกมและตรวจดูกล่องจดหมายเพื่อรับไอเท็มของคุณ <br/>(มุมบนขวาของหน้าจอ -> กล่องจดหมาย)',
        'SuccessBtn': 'ยอดเยี่ยม',
        // 失败弹窗
        'ErrorTitle': 'ซื้อไม่สำเร็จ!',
        'ErrorInfo': 'การสั่งซื้อล้มเหลว โปรดลองอีกครั้ง',
        'ErrorBtn': 'ตกลง'
    },
    // 登录弹窗
    'login': {
        'title1': "ร้านของอลิซ",
        'title2': "เกม",
        'Or': 'หรือ',
        'placeholder': 'ใส่รหัสผู้ใช้',
        'LogIn': 'เข้าสู่ระบบ',
        'SignInWithFacebook': 'ลงชื่อเข้าใช้ด้วย Facebook',
        'SignInWithGoogle': 'ลงชื่อเข้าใช้ด้วย Google',
        'LoginSuccess': 'เข้าสู่ระบบสำเร็จ คุณสามารถเริ่มช้อปปิ้งได้เลย!',
        'LoginFailed': 'เข้าสู่ระบบล้มเหลว! โปรดตรวจสอบว่าคุณได้ป้อนรหัสผู้ใช้ถูกต้องหรือไม่',
        'LogOut': 'ออกจากระบบสำเร็จ!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'เว็บไซต์นี้ใช้คุกกี้เพื่อรับรองว่าคุณจะได้รับประสบการณ์ที่ดีที่สุดบนเว็บไซต์ของเรา',
        'Accept': 'ยอมรับ'
    },
    // header部分
    'header': {
        'Menu': ['ร้านค้า', 'เกม', 'สนับสนุน', 'ติดตามเรา'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'สนับสนุน',
            'ข้อกำหนดการใช้บริการ',
            'นโยบายความเป็นส่วนตัว',
            'การลบข้อมูล'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-th.html',
            'https://policy.judiangames.com/privacypolicy-th-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'เปิดเผยความลับ ออกแบบความฝัน—มาเริ่มการผจญภัยกันเถอะ!',
            'ร่วมสนุกกับ Garden Affairs เกมจับคู่แบบเรียง 3 ฟรีที่น่าหลงใหลและเป็นที่ชื่นชอบของผู้เล่นล้านชาติ!'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'เข้าร่วมกับผู้เล่นนับล้านในเกม Garden Affairs เกมพัซเซิลฟรี! <br/>มาช่วยอลิซสร้างชีวิตที่คุณฝันถึงกันเถอะ!'
        ],
        'Custom': [
            'Garden Affairs เต็มไปด้วยคุณสมบัติใหม่ที่น่าตื่นเต้น รวมถึง:',
            'แสดงสไตล์ของคุณ',
            'เลือกเฟอร์นิเจอร์และของตกแต่งที่สะท้อนสไตล์เฉพาะตัวของคุณ <br/>ปรับแต่งมุมต่างๆ ของบ้าน เปลี่ยนโฉมคฤหาสน์เก่าให้กลายเป็นผลงานชิ้นเอกสุดน่าทึ่ง',
            'เรื่องราวที่น่าดึงดูด',
            'สำรวจความลับที่ซ่อนอยู่ในสวน ติดตามทุกการหักมุมในเรื่องราว สร้างเพื่อนใหม่',
            'ด่านแสนสนุก',
            'ปราบด่านเลเวลต่างๆ สุดท้าทายด้วยบูสเตอร์พิเศษ สิ่งของในด่าน และการผสมผสานพลังระเบิด <br/>พบกับความตื่นเต้นของการไขปริศนาและความสนุกของเกมจับคู่เรียงสาม'
        ],
        'GamePictures': 'รูปภาพเกม'
    },
    'homePageMobile': {
        'txt': [
            'เปิดเผยความลับ ออกแบบความฝัน<br/>—มาเริ่มการผจญภัยกันเถอะ!',
            'ร่วมสนุกกับ Garden Affairs เกมจับคู่แบบเรียง 3 <br/>ฟรีที่น่าหลงใหลและเป็นที่ชื่นชอบของผู้เล่นล้านชาติ!'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'เข้าร่วมกับผู้เล่นนับล้านในเกม <br/>Garden Affairs เกมพัซเซิลฟรี! <br/>มาช่วยอลิซสร้างชีวิตที่คุณฝันถึงกันเถอะ!'
        ],
        'Custom': [
            'Garden Affairs เต็มไปด้วยคุณสมบัติใหม่ที่น่าตื่นเต้น <br/>รวมถึง:',
            'แสดงสไตล์ของคุณ',
            'เลือกเฟอร์นิเจอร์และของตกแต่งที่สะท้อนสไตล์เฉพาะตัวของคุณ <br/>ปรับแต่งมุมต่างๆ ของบ้าน <br/>เปลี่ยนโฉมคฤหาสน์เก่าให้กลายเป็นผลงานชิ้นเอกสุดน่าทึ่ง',
            'เรื่องราวที่น่าดึงดูด',
            'สำรวจความลับที่ซ่อนอยู่ในสวน <br/>ติดตามทุกการหักมุมในเรื่องราว สร้างเพื่อนใหม่',
            'ด่านแสนสนุก',
            'ปราบด่านเลเวลต่างๆ สุดท้าทายด้วยบูสเตอร์พิเศษ <br/>สิ่งของในด่าน และการผสมผสานพลังระเบิด <br/>พบกับความตื่นเต้นของการไขปริศนาและความสนุกขอ<br/>งเกมจับคู่เรียงสาม'
        ],
        'GamePictures': 'รูปภาพเกม'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'เปิดเผยความลับ ออกแบบความฝัน<br/>—มาเริ่มการผจญภัยกันเถอะ!',
        'extra': 'เพิ่มอีก 5%',
        'store': 'ร้านค้า',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'ร่วมสนุกกับ Garden Affairs เกมจับคู่แบบ<br/>เรียง 3 ฟรีที่น่าหลงใหลและเป็นที่ชื่นชอบของผู้เ<br/>ล่นล้านชาติ!',
        'txt3': 'คุณได้รับสืบทอดที่ใหญ่โตแต่สภาพทรุดโทรม ขอให้อลิซเพื่อนเด็<br/>กและนักออกแบบภายในชื่อดังช่วยแปลงบ้านให้กลายเป็นฝัน <br/>ด้วยความมุ่งมั่น ความสร้างสรรค์ และฝีมือในการออกแบบของคุณ <br/>มาเห็นการเปลี่ยนแปลงที่น่าทึ่งได้เลย!',
        'txt4': [
            'พบกับหัวใจของเมืองแมตช์วิว',
            'ทำความรู้จักกับชาวเมืองผู้ร่าเริงที่นำชีวิตชีวามาสู่เมืองแมตช์วิว:'
        ],
        'PeopleName': ['อลิซ', 'โจอี้', 'สเตฟาโน่', 'เนลลี่', 'เทย์เลอร์', 'อลัน'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'นักออกแบบตกแต่งภายในวิสัยทัศน์ไกล <br/>พร้อมที่จะทำให้บ้านในฝันของคุณกลายเป็นจริง',
            'หนุ่มขายดอกไม้ผู้มีเสน่ห์ <br/>เปี่ยมด้วยความหลงใหลในมวลหมู่ดอกไม้ผลิบาน',
            'เชฟที่มีทั้งวัตถุดิบทำอาหารและมีความลับบางอย่างซ่อนไว้',
            'นักออกแบบผู้มีชื่อเสียงโด่งดังและจิตใจที่ห่วงใยผู้อื่น',
            'ช่างไม้ที่เก่งที่สุดในเมืองแถมยังเป็นเพื่อนซี้ที่แสนดี',
            'นักดูแลสวนผู้เชี่ยวชาญที่มีความเกี่ยวพันแน่นแฟ้นกับอดีตของคฤหาสน์'
        ],
        'txt5': [
            'งานภาพที่สวยน่าทึ่ง',
            'พบกับงานภาพอารมณ์แบบภาพยนตร์ที่เปิดกว้างสวยงาม <br/>และตะลึงไปกับรายละเอียดพิถีพิถันในทุกๆ ตัวเลือกการออกแบบ'
        ],
        'txt6': [
            'แสดงสไตล์ของคุณ',
            'เลือกเฟอร์นิเจอร์และของตกแต่งที่สะท้อนสไตล์เฉพาะตัวของคุณ <br/>ปรับแต่งมุมต่างๆ ของบ้าน เปลี่ยนโฉมคฤหาสน์เก่าให้กลายเป็นผลงา<br/>นชิ้นเอกสุดน่าทึ่ง',
            'ด่านแสนสนุก',
            'ปราบด่านเลเวลต่างๆ สุดท้าทายด้วยบูสเตอร์พิเศษ สิ่งของในด่าน <br/>และการผสมผสานพลังระเบิด พบกับความตื่นเต้นของการไขปริศนาและควา<br/>มสนุกของเกมจับคู่เรียงสาม'
        ],
        'txt7': [
            'ค้นพบและเ<br/>ชื่อมต่อ',
            'เปิดเผยความลึกลับที่ซ่อนอยู่ภายในสวนกว้างใหญ่ <br/>เชื่อมต่อกับเพื่อนบ้านและหล่อหลอมมิตรภาพที่ยั่งยืน<br/>นอกเหนือกำแพงคฤหาสน์'
        ],
        'txt8': 'รูปภาพเกม',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'เข้าดูที่โซเชียลมีเดียของเรา',
        'txt10': 'ดาวน์โหลดตอนนี้!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'เปิดเผยความลับ ออกแบบความฝัน<br/>—มาเริ่มการผจญภัยกันเถอะ!',
        'extra': 'เพิ่มอีก 5%',
        'store': 'ร้านค้า',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'ร่วมสนุกกับ Garden Affairs <br/>เกมจับคู่แบบเรียง 3 ฟรีที่น่าหลงใ<br/>หลและเป็นที่ชื่นชอบของผู้เล่นล้านชาติ!',
        'txt3': 'คุณได้รับสืบทอดที่ใหญ่โตแต่สภาพทรุดโทรม <br/>ขอให้อลิซเพื่อนเด็กและนักออกแบบภายในชื่อดัง<br/>ช่วยแปลงบ้านให้กลายเป็นฝัน ด้วยความมุ่งมั่น <br/>ความสร้างสรรค์ และฝีมือในการออกแบบของคุณ <br/>มาเห็นการเปลี่ยนแปลงที่น่าทึ่งได้เลย!',
        'txt4': [
            'พบกับหัวใจของเมืองแมตช์วิว',
            'ทำความรู้จักกับชาวเมืองผู้ร่าเริงที่นำชีวิตชีวา<br/>มาสู่เมืองแมตช์วิว:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'อลิซ',
            'เนลลี่',
            'โจอี้',
            'เทย์เลอร์',
            'สเตฟาโน่',
            'อลัน'
        ],
        'PeopleDesc': [
            'นักออกแบบตกแต่งภายในวิสัยทัศน์ไกล <br/>พร้อมที่จะทำให้บ้านในฝันของคุณกลายเป็นจริง',
            'นักออกแบบผู้มีชื่อเสียงโด่งดังและจิ<br/>ตใจที่ห่วงใยผู้อื่น',
            'หนุ่มขายดอกไม้ผู้มีเสน่ห์ <br/>เปี่ยมด้วยความหลงใหลในมวลหมู่ดอกไม้ผลิบาน',
            'ช่างไม้ที่เก่งที่สุดในเมืองแถมยังเป็<br/>นเพื่อนซี้ที่แสนดี',
            'เชฟที่มีทั้งวัตถุดิบทำอาหารและมีคว<br/>ามลับบางอย่างซ่อนไว้',
            'นักดูแลสวนผู้เชี่ยวชาญที่มีความเกี่ยว<br/>พันแน่นแฟ้นกับอดีตของคฤหาสน์'
        ],
        'txt5': [
            'งานภาพที่สวยน่าทึ่ง',
            'พบกับงานภาพอารมณ์แบบภาพยนตร์ที่เปิดกว้างส<br/>วยงาม และตะลึงไปกับรายละเอียดพิถีพิถันในทุกๆ <br/>ตัวเลือกการออกแบบ'
        ],
        'txt6': [
            'แสดงสไตล์ของคุณ',
            'เลือกเฟอร์นิเจอร์และของตกแต่งที่สะท้อนสไตล์เฉพ<br/>าะตัวของคุณ ปรับแต่งมุมต่างๆ ของบ้าน เปลี่ยนโฉม<br/>คฤหาสน์เก่าให้กลายเป็นผลงานชิ้นเอกสุดน่าทึ่ง',
            'ด่านแสนสนุก',
            'ปราบด่านเลเวลต่างๆ สุดท้าทายด้วยบูสเตอร์พิเศษ <br/>สิ่งของในด่าน และการผสมผสานพลังระเบิด <br/>พบกับความตื่นเต้นของการไขปริศนาและความ<br/>สนุกของเกมจับคู่เรียงสาม'
        ],
        'txt7': [
            'ค้นพบและเชื่อมต่อ',
            'เปิดเผยความลึกลับที่ซ่อนอยู่ภายในสวนกว้างใหญ่ <br/>เชื่อมต่อกับเพื่อนบ้านและหล่อหลอมมิตรภาพที่ยั่ง<br/>ยืนนอกเหนือกำแพงคฤหาสน์'
        ],
        'txt8': 'รูปภาพเกม',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'เข้าดูที่โซเชียลมีเดียของเรา',
        'txt10': 'ดาวน์โหลดตอนนี้!'
    },
    // 商品列表
    'product': {
        'Coins': 'เหรียญ',
        'STORE': 'ร้านค้า',
        'Extra': 'เพิ่มอีก',
        'Title1': 'ผู้ที่ควรได้รับของขวัญมาก<br/>ที่สุดก็คือคุณ',
        'Title2': 'ตามใจตัวเองด้วยแพ็กต่างๆ <br/>ที่ยอดเยี่ยมของเรา เพื่อความบันเทิงขั้น<br/>สุดและความสนุกที่ไม่มีอะไรเทียบได้!',
        'LOGIN': 'เข้าสู่ระบบ',
        'LOGOUT': 'ออกจากระบบ',
        'UID': 'UID',
        'ShowDetails': 'แสดงรายละเอียด',
        'PaymentMethods': 'วิธีการชำระเงิน',
        'MyAccount': 'บัญชีของฉัน',
        'UserID': 'UserID',
        'PurchaseProduct': 'ซื้อผลิตภัณฑ์',
        'Price': 'ราคา',
        'total': 'รวม',
        'BuyNow': 'ซื้อตอนนี้',
        'ItemInBundle': 'เซ็ตไอเท็ม',
        'PleaseSelectProduct': 'กรุณาเลือกสินค้าที่คุณต้องการซื้อ',
        'PleaseSelectPay': 'กรุณาเลือกวิธีการชำระเงินของคุณ'
    },
    'productMobile': {
        'STORE': 'ร้านค้า',
        'Extra': 'เพิ่มอีก',
        'Title1': 'ผู้ที่ควรได้รับของขวัญมาก<br/>ที่สุดก็คือคุณ',
        'Title2': 'ตามใจตัวเองด้วยแพ็กต่างๆ <br/>ที่ยอดเยี่ยมของเรา เพื่อความบัน<br/>เทิงขั้นสุดและความสนุกที่ไม่มีอะ<br/>ไรเทียบได้!',
        'LOGIN': 'เข้าสู่ระบบ',
        'LOGOUT': 'ออกจากระบบ',
        'UID': 'UID',
        'ShowDetails': 'แสดงรายละเอียด',
        'PaymentMethods': 'วิธีการชำระเงิน',
        'BuyPopTitle': 'ยืนยันคำสั่งชำระเงิน',
        'MyAccount': 'บัญชีของฉัน',
        'UserID': 'UserID',
        'PurchaseProduct': 'ซื้อผลิตภัณฑ์',
        'Price': 'ราคา',
        'total': 'รวม',
        'BuyNow': 'ซื้อตอนนี้',
        'ItemInBundle': 'เซ็ตไอเท็ม',
        'PleaseSelectProduct': 'กรุณาเลือกสินค้าที่คุณต้องการซื้อ',
        'PleaseSelectPay': 'กรุณาเลือกวิธีการชำระเงินของคุณ'
    },
    // deletion 页
    'deletion': {
        'Title': 'ลบบัญชีเกม',
        'Logout': 'ออกจากระบบ',
        'Content': 'โปรดอนุญาตการใช้บัญชี Google เชื่อมต่อกับบัญชีเกมของคุณ',
        'AUTHORIZE': 'อนุญาต',
    },
    'deletionMobile': {
        'Title': 'ลบบัญชีเกม',
        'Logout': 'ออกจากระบบ',
        'Content': 'โปรดอนุญาตการใช้บัญชี Google <br/>เชื่อมต่อกับบัญชีเกมของคุณ',
        'AUTHORIZE': 'อนุญาต',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'ภายใต้ระเบียบการคุ้มครองข้อมูลทั่วไป คุณมีสิทธิที่จะขอให้ข้อมูลส่วนบุคคลทั้งหมดของคุณที่ใช้บนแอปพลิเคชันของเราและบริการของคู่ค้าของเรา (การชำระเงินในการซื้อ การดูโฆษณาในแอป และอื่นๆ) ถูกลบ โปรดรับทราบว่าเมื่อคุณตัดสินใจที่จะลบข้อมูล บัญชีผู้ใช้จะถูกลบและไม่สามารถกู้คืนได้ แต่อาจเป็นไปได้ที่จะเริ่มต้นเกมใหม่อีกครั้งจากเลเวล 1',
            'การลบบัญชีอาจถูกยกเลิกได้ภายใน 15 วัน',
            'หากต้องการยกเลิกการลบ ให้เปิดเกมและกดปุ่มยกเลิก โปรดรับทราบว่าหลังจาก 15 วัน เราจะไม่สามารถย้อนกลับการลบได้ และเกมของคุณจะไม่สามารถกู้คืนได้ โปรดรับทราบเพิ่มเติมว่าภายในช่วงเวลา 15 วันระหว่างการลบ คุณจะไม่สามารถเริ่มเกมใหม่จากจุดเริ่มต้นได้'
        ],
        'DeleteGameAccount': 'ลบบัญชีเกม',
        'DelTitle': 'ยืนยัน',
        'DelContent': 'คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีเกมของคุณ',
        'DelCancel': 'ยกเลิก',
        'DelConfirm': 'ใช่ ฉันแน่ใจ',
        'DelCodeLabel': [
            'ไม่พบบัญชีเกมสำหรับผู้ใช้นี้', // 0
            'Success! ', // 1
            'บัญชีเกมถูกส่งเพื่อการลบ' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'คำขอให้ลบข้อมูลของคุณถูกบันทึกสำเร็จเรียบร้อยแล้ว',
        'บัญชีเกมจะถูกลบภายใน 15 วันนับจากที่คุณส่งคำขอ'
    ]
}
