export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': '서버 오류가 발생했습니다. 나중에 다시 시도하십시오.',
        20014: '없는 사용자 ID입니다. 다시 입력해주세요.', //  游戏用户ID错误
        10002: '서버 오류가 발생했습니다. 나중에 다시 시도하십시오. (10002)',// 参数无效
        10008: '서버 오류가 발생했습니다. 나중에 다시 시도하십시오. (10008)', // 数据未找到
        30008: '서버 오류가 발생했습니다. 나중에 다시 시도하십시오. (30008)', // 商品不存在
        30007: '서버 오류가 발생했습니다. 나중에 다시 시도하십시오. (30007)', // 商品币种类型不正确
        10001: '서버 오류가 발생했습니다. 나중에 다시 시도하십시오. (10001)', // 服务器开小差
        10000: '네트워크 오류, 네트워크 설정을 확인하십시오.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': '구매 성공!',
        'SuccessInfo': '구매 감사합니다! <br/>게임을 다시 실행하고 우편함을 확인해서 상품을 수령하세요. <br/>(화면 우상단 -> 우편함)',
        'SuccessBtn': '좋아요',
        // 失败弹窗
        'ErrorTitle': '구매 실패!',
        'ErrorInfo': '구매에 실패했습니다! 다시 시도해 주세요.',
        'ErrorBtn': '확인'
    },
    // 登录弹窗
    'login': {
        'title1': "앨리스의 상점",
        'title2': "게임",
        'Or': '또는',
        'placeholder': '사용자 ID 입력',
        'LogIn': '로그인',
        'SignInWithFacebook': 'Facebook으로 로그인',
        'SignInWithGoogle': 'Google로 로그인',
        'LoginSuccess': '로그인 성공, 쇼핑을 시작하세요!',
        'LoginFailed': '로그인 실패! 입력한 사용자 ID가 올바른지 확인하십시오.',
        'LogOut': '로그아웃 성공!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': '이 웹사이트는 쾌적한 경험을 보장하기 위해 쿠키를 사용합니다.',
        'Accept': '수락'
    },
    // header部分
    'header': {
        'Menu': ['상점', '게임', '지원', '팔로우하기'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            '지원',
            '서비스 약관',
            '개인정보 보호정책',
            '데이터 삭제'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-ko.html',
            'https://policy.judiangames.com/privacypolicy-ko-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            '비밀을 밝히세요. 꿈을 디자인하세요. 모험을 시작하세요!',
            '수백만 명이 빠진 중독성 있는 매치3 무료 퍼즐 게임 Garden Affairs에 빠져드세요.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            '무료 퍼즐 게임 Garden Affairs에서 수백만 플레이어와 함께하세요! <br/>항상 꿈꿔왔던 삶을 만들기 위해 앨리스와 함께해 보세요!'
        ],
        'Custom': [
            'Garden Affairs는 다음을 포함하여 흥미로운 새로운 기능으로 가득합니다:',
            '개성을 표출하세요',
            '나만의 스타일을 반영하는 가구와 장식을 선택하세요. <br/>집 안 구석구석을 커스터마이징해서 낡은 저택을 굉장한 예술 작품으로 만드세요.',
            '흥미로운 이야기',
            '정원에 숨겨진 비밀을 탐험하세요. 줄거리의 모든 전개를 따라가세요. <br/>새로운 친구를 사귀세요.',
            '재밌는 레벨',
            '만만치 않은 레벨을 마스터하세요. 독특한 부스터와 폭발적 조합이 기다립니다. <br/>매치의 즐거움과 퍼즐 해결의 스릴을 경험하세요.'
        ],
        'GamePictures': '게임 이미지'
    },
    'homePageMobile': {
        'txt': [
            '비밀을 밝히세요. <br/>꿈을 디자인하세요. <br/>모험을 시작하세요!',
            '수백만 명이 빠진 중독성 있는 매치3 <br/>무료 퍼즐 게임 Garden Affairs에 빠져드세요.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            '무료 퍼즐 게임 Garden Affairs에서 수백만 <br/>플레이어와 함께하세요! 항상 꿈꿔왔던 삶을 만들기 <br/>위해 앨리스와 함께해 보세요!'
        ],
        'Custom': [
            'Garden Affairs는 다음을 포함하여 흥미로운 새로운 <br/>기능으로 가득합니다:',
            '개성을 표출하세요',
            '나만의 스타일을 반영하는 가구와 장식을 선택하세요. <br/>집 안 구석구석을 커스터마이징해서 낡은 저택을 <br/>굉장한 예술 작품으로 만드세요.',
            '흥미로운 이야기',
            '정원에 숨겨진 비밀을 탐험하세요. 줄거리의 모든 전개를 <br/>따라가세요. 새로운 친구를 사귀세요.',
            '재밌는 레벨',
            '만만치 않은 레벨을 마스터하세요. 독특한 부스터와 폭발적 <br/>조합이 기다립니다. 매치의 즐거움과 퍼즐 해결의 <br/>스릴을 경험하세요.'
        ],
        'GamePictures': '게임 이미지'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': '비밀을 밝히세요. 꿈을 디자인하세요. <br/>모험을 시작하세요!',
        'extra': '5% 추가',
        'store': '상점',
        'PeopleIcon': 'games/icon.png',
        'txt2': '수백만 명이 빠진 중독성 있는 <br/>매치3 무료 퍼즐 게임 <br/>Garden Affairs에 빠져드세요.',
        'txt3': '유명한 인테리어 디자이너가 된 소꿉친구 앨리스와 함께 <br/>황폐한 저택을 꿈의 집으로 리모델링합니다. 끈기와 창의성으로 <br/>저택이 눈부신 꿈의 집으로 변하는 과정을 지켜봅니다!',
        'txt4': [
            '매치뷰의 중심을 만나보세요',
            '매치뷰에 생기를 불어넣는 활기찬 현지인들을 알아가세요.'
        ],
        'PeopleName': ['앨리스', '조이', '스테파노', '넬리', '테일러', '앨런'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            '꿈의 집을 현실화할 준비가 된 <br/>선구적 인테리어 디자이너',
            '활짝 피는 꽃을 사랑하는 <br/>매력적 플로리스트',
            '음식 재료 말고 다른 것을 <br/>숨기고 있는 셰프',
            '마음이 따뜻한 유명 <br/>디자이너',
            '마을 최고의 목수이자 <br/>절친한 친구',
            '저택의 역사에 깊이 <br/>얽혀있는 원숙한 정원사'
        ],
        'txt5': [
            '눈부신 비주얼',
            '환상적인 오프닝 시네마틱 영상을 감상하고 모든 디자인의 꼼꼼한 디테일에 감탄하세요.'
        ],
        'txt6': [
            '개성을 표출하세요',
            '나만의 스타일을 반영하는 가구와 장식을 선택하세요. <br/>집 안 구석구석을 커스터마이징해서 낡은 저택을 굉장한 예술 <br/>작품으로 만드세요.',
            '재밌는 레벨',
            '만만치 않은 레벨을 마스터하세요. <br/>독특한 부스터와 폭발적 조합이 기다립니다. <br/>매치의 즐거움과 퍼즐 해결의 스릴을 경험하세요.'
        ],
        'txt7': [
            '밝혀내고 사귀세요',
            '광활한 정원에 숨은 미스터리를 파헤치세요. <br/>이웃과 연을 맺고 저택 담장 너머에서 변치 않는 <br/>우정을 쌓으세요.'
        ],
        'txt8': '게임 이미지',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'SNS에서 만나요',
        'txt10': '지금 다운로드하세요!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': '비밀을 밝히세요. 꿈을 디자인하세요. <br/>모험을 시작하세요!',
        'extra': '5% 추가',
        'store': '상점',
        'PeopleIcon': 'games/icon.png',
        'txt2': '수백만 명이 빠진 중독성 있는 <br/>매치3 무료 퍼즐 게임 <br/>Garden Affairs에 빠져드세요.',
        'txt3': '유명한 인테리어 디자이너가 된 소꿉친구 앨리스와 <br/>함께 황폐한 저택을 꿈의 집으로 리모델링합니다. <br/>끈기와 창의성으로 저택이 눈부신 꿈의 <br/>집으로 변하는 과정을 지켜봅니다!',
        'txt4': [
            '매치뷰의 중심을 만나보세요',
            '매치뷰에 생기를 불어넣는 활기찬 <br/>현지인들을 알아가세요.'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            '앨리스',
            '넬리',
            '조이',
            '테일러',
            '스테파노',
            '앨런'
        ],
        'PeopleDesc': [
            '꿈의 집을 현실화할 준비가 된 <br/>선구적 인테리어 디자이너',
            '마음이 따뜻한 유명 <br/>디자이너',
            '활짝 피는 꽃을 사랑하는 <br/>매력적 플로리스트',
            '마을 최고의 목수이자 <br/>절친한 친구',
            '음식 재료 말고 다른 것을 <br/>숨기고 있는 셰프',
            '저택의 역사에 깊이 <br/>얽혀있는 원숙한 정원사'
        ],
        'txt5': [
            '눈부신 비주얼',
            '환상적인 오프닝 시네마틱 영상을 감상하고 <br/>모든 디자인의 꼼꼼한 디테일에 감탄하세요.'
        ],
        'txt6': [
            '개성을 표출하세요',
            '나만의 스타일을 반영하는 가구와 장식을 <br/>선택하세요. 집 안 구석구석을 커스터마이징해서 낡은 <br/>저택을 굉장한 예술 작품으로 만드세요.',
            '재밌는 레벨',
            '만만치 않은 레벨을 마스터하세요. <br/>독특한 부스터와 폭발적 조합이 기다립니다. <br/>매치의 즐거움과 퍼즐 해결의 스릴을 경험하세요.'
        ],
        'txt7': [
            '밝혀내고 사귀세요',
            '광활한 정원에 숨은 미스터리를 파헤치세요. <br/>이웃과 연을 맺고 저택 담장 너머에서 변치 않는 <br/>우정을 쌓으세요.'
        ],
        'txt8': '게임 이미지',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'SNS에서 만나요',
        'txt10': '지금 다운로드하세요!'
    },
    // 商品列表
    'product': {
        'Coins': '코인',
        'STORE': '상점',
        'Extra': '추가',
        'Title1': '누구보다 선물을 받을 <br/>자격이 있는 당신!',
        'Title2': '엄청난 팩 중 하나를 챙겨서 궁극의 <br/>행복과 절대적 재미를 맛보세요!',
        'LOGIN': '로그인',
        'LOGOUT': '로그 아웃',
        'UID': 'UID',
        'ShowDetails': '자세히 보기',
        'PaymentMethods': '결제 수단',
        'MyAccount': '계정',
        'UserID': 'UserID',
        'PurchaseProduct': '상품 구매',
        'Price': '가격',
        'total': '총',
        'BuyNow': '지금 구매',
        'ItemInBundle': '세트 아이템',
        'PleaseSelectProduct': '구매하려는 항목을 선택하세요.',
        'PleaseSelectPay': '결제 방법을 선택하십시오.'
    },
    'productMobile': {
        'STORE': '상점',
        'Extra': '추가',
        'Title1': '누구보다 선물을 받을 <br/>자격이 있는 당신!',
        'Title2': '엄청난 팩 중 하나를 <br/>챙겨서 궁극의 행복과 <br/>절대적 재미를 맛보세요!',
        'LOGIN': '로그인',
        'LOGOUT': '로그 아웃',
        'UID': 'UID',
        'ShowDetails': '자세히 보기',
        'PaymentMethods': '결제 수단',
        'BuyPopTitle': '결제 주문 확인',
        'MyAccount': '계정',
        'UserID': 'UserID',
        'PurchaseProduct': '상품 구매',
        'Price': '가격',
        'total': '총',
        'BuyNow': '지금 구매',
        'ItemInBundle': '세트 아이템',
        'PleaseSelectProduct': '구매하려는 항목을 선택하세요.',
        'PleaseSelectPay': '결제 방법을 선택하십시오.'
    },
    // deletion 页
    'deletion': {
        'Title': '게임 계정 삭제',
        'Logout': '로그 아웃',
        'Content': '게임 계정과 연결된 Google Account를 써서 인증해주세요.',
        'AUTHORIZE': '인증',
    },
    'deletionMobile': {
        'Title': '게임 계정 삭제',
        'Logout': '로그 아웃',
        'Content': '게임 계정과 연결된 Google Account를 <br/>써서 인증해주세요.',
        'AUTHORIZE': '인증',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            '일반 개인정보 보호법(General Data Protection Regulation)에 따라, 귀하는 자사 애플리케이션 및 협력사 서비스(구매 상품 결제, 앱 내 광고 시청 등)에 쓰이는 개인정보 일체를 삭제할 권한이 있습니다. 개인정보를 삭제하기로 결정하시면 사용자 계정이 삭제되며 복구가 불가능하다는 점을 유념해주세요. 게임을 1레벨부터 다시 시작할 수는 있습니다.',
            '계정 삭제는 15일 안에 취소할 수 있습니다.',
            '삭제를 취소하려면 게임을 열어서 취소 버튼을 누르세요. 15일 후에는 삭제를 돌이킬 수 없으며 게임 진행 상황을 복구할 수 없다는 데 유의해주세요. 또한, 삭제 기간 중 15일 안에는 게임을 처음부터 다시 시작할 수 없습니다.'
        ],
        'DeleteGameAccount': '게임 계정 삭제',
        'DelTitle': '확인',
        'DelContent': '정말 게임 계정을 삭제하시겠습니까?',
        'DelCancel': '취소',
        'DelConfirm': '네, 삭제할게요',
        'DelCodeLabel': [
            '이 사용자의 게임 계정을 찾을 수 없습니다.', // 0
            'Success! ', // 1
            '게임 계정이 삭제 요청되었습니다.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        '데이터 삭제 요청이 정상 접수되었습니다.',
        '요청 후 15일 안에 게임 계정이 삭제됩니다.'
    ]
}
