import request from "@/utils/request.js";
import {ElMessage} from "element-plus";
import i18n from "@/lang/index.js";

export const http = (params, decrypt) => {
  // const ENV = import.meta.env.VITE_USER_NODE_ENV;

  const AppId = 100004
  // console.log(ENV, AppId);
  const config = params.noAppId ? {...params} : {...params, AppId};
  return request(config).then(async (response) => {
    const codes = [0, 1, 2, 20100, 200];
    const isAllow = response?.access_token || response?.email;
    if (!response) {
      return ElMessage.error({
        showClose: true,
        message: 'response not found！'
      });
    } else if (codes.includes(response.code) || isAllow) {
      return response;
    } else {
      const errorMessage = i18n.global.t(`errorCode.${response?.code}`, i18n.global.t('errorCode.default'));
      ElMessage.error({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: errorMessage
      })
      return Promise.reject(response);
    }
  });
};
