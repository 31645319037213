export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Error del servidor, por favor inténtelo de nuevo más tarde.',
        20014: 'La (ID) de usuario no existe, por favor, introdúcela de nuevo.', //  游戏用户ID错误
        10002: 'Error del servidor, por favor inténtelo de nuevo más tarde. (10002)',// 参数无效
        10008: 'Error del servidor, por favor inténtelo de nuevo más tarde. (10008)', // 数据未找到
        30008: 'Error del servidor, por favor inténtelo de nuevo más tarde. (30008)', // 商品不存在
        30007: 'Error del servidor, por favor inténtelo de nuevo más tarde. (30007)', // 商品币种类型不正确
        10001: 'Error del servidor, por favor inténtelo de nuevo más tarde. (10001)', // 服务器开小差
        10000: 'Error de red, por favor verifique su configuración de red.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': '¡Compra Exitosa!',
        'SuccessInfo': '¡Gracias por tu compra! <br/>Reinicia el juego y comprueba tu buzón para recoger tu ítem. <br/>(Esquina superior derecha de la pantalla -> Buzón)',
        'SuccessBtn': '¡Genial!',
        // 失败弹窗
        'ErrorTitle': 'Falla en la compra!',
        'ErrorInfo': 'Compra fallida! Por favor intenta de nuevo.',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "Tienda de Alice",
        'title2': "Juegos",
        'Or': 'o',
        'placeholder': 'Ingrese ID de usuario',
        'LogIn': 'Conectarse',
        'SignInWithFacebook': 'Acceder con Facebook',
        'SignInWithGoogle': 'Iniciar sesión con Google',
        'LoginSuccess': 'Inicio de sesión exitoso, ¡puedes empezar a comprar!',
        'LoginFailed': '¡Inicio de sesión fallido! Verifique si su ID de usuario es correcto.',
        'LogOut': '¡Cierre de sesión exitoso!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Este sitio web utiliza cookies para que tengas la mejor experiencia en nuestro sitio.',
        'Accept': 'Aceptar'
    },
    // header部分
    'header': {
        'Menu': ['TIEND', 'Juegos', 'Apoyo', 'Síguenos'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Apoyo',
            'Términos de Servicio',
            'Política de Privacidad',
            'Eliminación de Datos'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-es.html',
            'https://policy.judiangames.com/privacypolicy-es-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Descubre los secretos, diseña tus sueños. ¡Empieza la aventura!',
            'Sumérgete en Garden Affairs, un cautivador juego match-3 gratuito <br/>que ha cautivado a millones.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Únete a millones en Garden Affairs, <br/>el juego de rompecabezas gratis. <br/>Ven y ayuda a Alice a crear la vida de tus sueños.'
        ],
        'Custom': [
            'Garden Affairs está lleno de nuevas y emocionantes funciones, incluyendo:',
            'Expresa tu Estilo',
            'Selecciona muebles y decoraciones que reflejen tu estilo. <br/>Personaliza cada rincón, convirtiendo la mansión en una impresionante obra maestra.',
            'Historias Intrigantes',
            'Explora los secretos escondidos en el jardín. <br/>Sigue cada giro de la trama. Haz nuevos amigos.',
            'Niveles Divertidos',
            'Domina niveles desafiantes con elementos únicos y combinaciones explosivas. <br/>Disfruta la satisfacción de resolver acertijos y hacer combinaciones.'
        ],
        'GamePictures': 'Imágenes del Juego'
    },
    'homePageMobile': {
        'txt': [
            'Descubre los secretos, <br/>diseña tus sueños. <br/>¡Empieza la aventura!',
            'Sumérgete en Garden Affairs, <br/>un cautivador juego match-3 gratuito <br/>que ha cautivado a millones.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Únete a millones en Garden Affairs, <br/>el juego de rompecabezas gratis. <br/>Ven y ayuda a Alice a crear la vida de tus sueños.'
        ],
        'Custom': [
            'Garden Affairs está lleno de nuevas y emocionantes <br/>funciones, incluyendo:',
            'Expresa tu Estilo',
            'Selecciona muebles y decoraciones que reflejen tu <br/>estilo. Personaliza cada rincón, convirtiendo la <br/>mansión en una impresionante obra maestra.',
            'Historias Intrigantes',
            'Explora los secretos escondidos en el jardín. <br/>Sigue cada giro de la trama. Haz nuevos amigos.',
            'Niveles Divertidos',
            'Domina niveles desafiantes con elementos  <br/>únicos y combinaciones explosivas. <br/>Disfruta la satisfacción de resolver acertijos <br/> y hacer combinaciones.'
        ],
        'GamePictures': 'Imágenes del Juego'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Descubre los secretos, diseña tus sueños. <br/>¡Empieza la aventura!',
        'extra': '5% Extra',
        'extraFontSize': '1.3',
        'store': 'TIEND',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Sumérgete en Garden Affairs, <br/>un cautivador juego match-3 gratuito <br/>que ha cautivado a millones.',
        'txt3': 'Has heredado una gran propiedad en ruinas y pides a tu amiga, <br/>la diseñadora Alice, que la transforme en la casa de tus sueños. <br/>Con dedicación y creatividad, verás cómo la mansión se convierte en <br/>una impresionante casa de ensueño.',
        'txt4': [
            'Conoce el corazón de Matchview',
            'Descubre a los vibrantes habitantes que dan vida a Matchview:'
        ],
        'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'La diseñadora visionaria que<br/> realizará tu casa de ensueño',
            'El florista apasionado por<br/> las flores en su esplendor',
            'El chef que oculta más que<br/> ingredientes bajo la manga',
            'La diseñadora famosa<br/> y bondadosa',
            'La mejor carpintera<br/> y amiga de la ciudad',
            'Jardineros veteranos con fuertes<br/>lazos históricos con la hacienda'
        ],
        'txt5': [
            'Impresionantes efectos visuales',
            'Disfruta de la impresionante apertura cinematográfica <br/>y sorpréndete con el meticuloso detalle en cada elección de diseño.'
        ],
        'txt6': [
            'Expresa tu Estilo',
            'Selecciona muebles y decoraciones que reflejen tu estilo. <br/>Personaliza cada rincón, convirtiendo la mansión en una <br/>impresionante obra maestra.',
            'Niveles Divertidos',
            'Padroneggia livelli con booster unici e combinazioni esplosive. <br/>Prova l\'emozione di risolvere rompicapi e la soddisfazione degli abbinamenti.'
        ],
        'txt7': [
            'Descubre y <br/>Conecta',
            'Descubre los misterios ocultos en los jardines. <br/>Relaciónate con los vecinos y forja amistades <br/>duraderas.'
        ],
        'txt8': 'Imágenes del Juego',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Consulta Nuestras Redes Sociales',
        'txt10': '¡Descargar ahora!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Descubre los secretos, diseña tus sueños. <br/>¡Empieza la aventura!',
        'extra': '5% Extra',
        'store': 'TIEND',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Sumérgete en Garden Affairs, <br/>un cautivador juego match-3 <br/>gratuito que ha cautivado a <br/>millones.',
        'txt3': 'Has heredado una gran propiedad en ruinas y <br/>pides a tu amiga, la diseñadora Alice, que la <br/>transforme en la casa de tus sueños. Con <br/>dedicación y creatividad, verás cómo la mansión <br/>se convierte en una impresionante casa de <br/>ensueño.',
        'txt4': [
            'Conoce el corazón de Matchview',
            'Descubre a los vibrantes habitantes <br/>que dan vida a Matchview:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'ALICE',
            'NELLIE',
            'JOEY',
            'TAYLOR',
            'STEFANO',
            'ALAN'
        ],
        'PeopleDesc': [
            'La diseñadora visionaria que <br/>realizará tu casa de ensueño',
            'La diseñadora famosa y bondadosa',
            'El florista apasionado por<br/> las flores en su esplendor',
            'La mejor carpintera y amiga de la ciudad',
            'El chef que oculta más que<br/> ingredientes bajo la manga',
            'Jardineros veteranos con fuertes <br/>lazos históricos con la hacienda'
        ],
        'txt5': [
            'Impresionantes<br/> efectos visuales',
            'Disfruta de la impresionante apertura <br/>cinematográfica y sorpréndete con el meticuloso <br/>detalle en cada elección de diseño.'
        ],
        'txt6': [
            'Expresa tu Estilo',
            'Selecciona muebles y decoraciones que reflejen <br/>tu estilo. Personaliza cada rincón, convirtiendo <br/>la mansión en una impresionante obra maestra.',
            'Niveles Divertidos',
            'Padroneggia livelli con booster unici e <br/>combinazioni esplosive. Prova l\'emozione di <br/>risolvere rompicapi e la soddisfazione degli <br/>abbinamenti.'
        ],
        'txt7': [
            'Descubre y Conecta',
            'Descubre los misterios ocultos en los <br/>jardines. Relaciónate con los vecinos y forja <br/>amistades duraderas.'
        ],
        'txt8': 'Imágenes del Juego',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Consulta Nuestras <br/>Redes Sociales',
        'txt10': '¡Descargar ahora!'
    },
    // 商品列表
    'product': {
        'Coins': '¡Monedas',
        'STORE': 'TIEND',
        'Extra': 'Extra',
        'Title1': '¿Quién Se Merece un <br/>Regalo Más que Tú?',
        'Title2': 'Date un capricho con nuestros <br/>increíbles paquetes. ¡Máxima felicidad <br/>y diversión insuperable!',
        'LOGIN': 'Iniciar Sesión',
        'LOGOUT': 'Cerrar sesión',
        'UID': 'UID',
        'ShowDetails': 'Mostrar detalles',
        'PaymentMethods': 'Métodos de Pago',
        'MyAccount': 'Mi Cuenta',
        'UserID': 'UserID',
        'PurchaseProduct': 'Comprar Producto',
        'Price': 'Precio',
        'total': 'Total',
        'BuyNow': 'Compra ahora',
        'ItemInBundle': 'Paquete de ítems',
        'PleaseSelectProduct': 'Por favor, seleccione el artículo que desea comprar.',
        'PleaseSelectPay': 'Por favor, elija su método de pago.'
    },
    'productMobile': {
        'STORE': 'TIEND',
        'Extra': 'Extra',
        'Title1': '¿Quién Se Merece un <br/>Regalo Más que Tú?',
        'Title2': 'Date un capricho con <br/>nuestros increíbles paquetes. <br/>¡Máxima felicidad y diversión<br/> insuperable!',
        'LOGIN': 'Iniciar Sesión',
        'LOGOUT': 'Cerrar sesión',
        'UID': 'UID',
        'ShowDetails': 'Mostrar detalles',
        'PaymentMethods': 'Métodos de Pago',
        'BuyPopTitle': 'Confirmar orden de pago',
        'MyAccount': 'Mi Cuenta',
        'UserID': 'UserID',
        'PurchaseProduct': 'Comprar Producto',
        'Price': 'Precio',
        'total': 'Total',
        'BuyNow': 'Compra ahora',
        'ItemInBundle': 'Paquete de ítems',
        'PleaseSelectProduct': 'Por favor, seleccione el artículo que desea comprar.',
        'PleaseSelectPay': 'Por favor, elija su método de pago.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Eliminación de cuentas de juego',
        'Logout': 'Cerrar sesión',
        'Content': 'Por favor, autoriza usando la cuenta de Google conectada a tu cuenta del juego.',
        'AUTHORIZE': 'Autorizar',
    },
    'deletionMobile': {
        'Title': 'Eliminación de cuentas de juego',
        'Logout': 'Cerrar sesión',
        'Content': 'Por favor, autoriza usando la cuenta de <br/>Google conectada a tu cuenta del juego.',
        'AUTHORIZE': 'Autorizar',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'En virtud del Reglamento General de Protección de Datos, tienes derecho a que se eliminen todos tus datos personales utilizados en nuestra aplicación y en los servicios de nuestros socios (pagos de compras, visualizaciones de anuncios en la aplicación, etc.). Ten en cuenta que, si decides eliminar los datos, la cuenta de usuario se borrará y no se podrá restaurar. Sin embargo, será posible volver a empezar el juego desde el nivel 1.',
            'La eliminación de la cuenta puede cancelarse en un plazo de 15 días.',
            'Para cancelar la eliminación, abre el juego y pulsa el botón Cancelar. Ten en cuenta que, transcurridos 15 días, no podremos anular la eliminación y tu partida no podrá restaurarse. Por favor, ten en cuenta también que durante los 15 días que dura el periodo de borrado, no podrás reiniciar el juego desde el principio.'
        ],
        'DeleteGameAccount': 'Eliminar cuenta de juego',
        'DelTitle': 'Confirmar',
        'DelContent': '¿Seguro que quieres borrar tu cuenta del juego?',
        'DelCancel': 'Cancelar',
        'DelConfirm': 'Sí',
        'DelCodeLabel': [
            'Cuenta de juego para este usuario no encontrada.', // 0
            'Success! ', // 1
            'La cuenta de juego ha sido enviada para su eliminación.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Tu solicitud de eliminación de datos se ha registrado correctamente.',
        'La cuenta de juego se eliminará en un plazo de 15 días a partir de tu solicitud.'
    ]
}
