import {defineStore} from 'pinia';
import {getLinkApi, loadPageEventApi} from "@/api/common"

const useCommonStore = defineStore('Common', {
    state: () => {
        return {
            hostname: window.location.hostname,
            download_url: '',
        }
    },
    getters: {
        // isHostNameWDHY() {
        //     // const value = '10.10.20.41'
        //     const value = 'www.wodehuayuan.cn'
        //     console.log(this.hostname, this.hostname === value, '-this.hostname');
        //     return this.hostname === value;
        // },
    },
    actions: {
        async getLink(params) {
            const That = this;
            return new Promise((resolve, reject) => {
                getLinkApi(params).then(async res => {
                    That.download_url = res.data;
                    resolve(res);
                }).catch(error => {
                    That.downloadLink = '';
                    reject(error);
                })
            })
        },
        async loadPageEvent(params) {
            // const That = this;
            return new Promise((resolve, reject) => {
                loadPageEventApi(params).then(async res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
})

export default useCommonStore
