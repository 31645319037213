export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': '伺服器異常，請稍後再試。',
        20014: '使用者ID不存在，請重新輸入。', //  游戏用户ID错误
        10002: '伺服器異常，請稍後再試。(10002)',// 参数无效
        10008: '伺服器異常，請稍後再試。(10008)', // 数据未找到
        30008: '伺服器異常，請稍後再試。(30008)', // 商品不存在
        30007: '伺服器異常，請稍後再試。(30007)', // 商品币种类型不正确
        10001: '伺服器異常，請稍後再試。(10001)', // 服务器开小差
        10000: '網絡異常，請檢查您的網絡設置。', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': '購買成功！',
        'SuccessInfo': '感謝你的購買！<br/>重新啟動遊戲並檢查你的信箱，<br/>以領取你的物品。<br/>（畫面右上角->信箱）',
        'SuccessBtn': '讚',
        // 失败弹窗
        'ErrorTitle': '購買失敗！',
        'ErrorInfo': '購買失敗！請再試試看哦。',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "艾莉絲的商店",
        'title2': "遊戲",
        'Or': '或者',
        'placeholder': '輸入用戶ID',
        'LogIn': '登入',
        'SignInWithFacebook': '用臉書登入',
        'SignInWithGoogle': '用谷歌登入',
        'LoginSuccess': '登錄成功，您可以開始購物啦！',
        'LoginFailed': '登錄失敗！請檢查您輸入的用戶ID是否有誤。',
        'LogOut': '登出成功！',
    },
    // cookies tips
    'cookiesTip': {
        'Content': '本網站使用cookie來確保你在我們的網站上獲得最佳體驗。',
        'Accept': '接受'
    },
    // header部分
    'header': {
        'Menu': ['商店', '遊戲', '支持', '追蹤我們'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            '支持',
            '服務條款',
            '隱私權政策',
            '資料刪除'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-tc.html',
            'https://policy.judiangames.com/privacypolicy-tc-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            '揭開秘密，設計你的夢想－開始你的冒險吧！',
            '深入探索《我的花園》，這是一款引人入勝的免費三消益智遊戲，<br/>並且令數千萬人著迷喔。'
        ],
        'GardenAffairs': [
            '我的花園',
            '加入數千萬玩家的行列，<br/>參加免費的拼圖遊戲《花園事務》！<br/>來和愛麗絲一起創造你一直夢寐以求的生活吧！'
        ],
        'Custom': [
            '《花園事務》充滿了令人興奮的新功能，包括：',
            '表達你的風格',
            '選擇反映你個人風格的家俱和裝飾品。<br/>訂製你房子的每個角落，將古老的莊園變成令人驚嘆的傑作吧。',
            '引人入勝的故事',
            '探索花園中隱藏的秘密。<br/>追隨情節的每一個轉折。結交新朋友。',
            '趣味關卡',
            '透過獨特的強化道具、遊戲元素和爆炸性組合來掌握具有挑戰性的關卡。<br/>體驗解決謎題的快感和匹配的滿足感。'
        ],
        'GamePictures': '遊戲圖片'
    },
    'homePageMobile': {
        'txt': [
            '揭開秘密，設計你的夢想<br/>－開始你的冒險吧！',
            '深入探索《我的花園》，<br/>這是一款引人入勝的免費三消益智遊戲，<br/>並且令數千萬人著迷喔。 '
        ],
        'GardenAffairs': [
            '我的花園',
            '加入數千萬玩家的行列，<br/>參加免費的拼圖遊戲《花園事務》！<br/>來和愛麗絲一起創造你一直夢寐以求的生活吧！'
        ],
        'Custom': [
            '《花園事務》充滿了令人興奮的新功能，包括：',
            '表達你的風格',
            '選擇反映你個人風格的家俱和裝飾品。<br/>訂製你房子的每個角落，<br/>將古老的莊園變成令人驚嘆的傑作吧。',
            '引人入勝的故事',
            '探索花園中隱藏的秘密。<br/>追隨情節的每一個轉折。結交新朋友。',
            '趣味關卡',
            '透過獨特的強化道具、遊戲元素<br/>和爆炸性組合來掌握具有挑戰性的關卡。<br/>體驗解決謎題的快感和匹配的滿足感。'
        ],
        'GamePictures': '遊戲圖片'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': '揭開秘密，設計你的夢想<br/>－開始你的冒險吧！',
        'extra': '額外5%',
        'store': '商店',
        'PeopleIcon': 'games/icon.png',
        'txt2': '深入探索《我的花園》，<br/>這是一款引人入勝的免費三消益智遊戲，<br/>並且令數千萬人著迷喔！',
        'txt3': '你剛剛繼承了一處宏偉但破舊的莊園，' +
            '<br/>所以你邀請你兒時的朋友、著名的室內設計師艾莉絲，將其改造成你夢想的家，' +
            '<br/>憑藉著奉獻精神、創造力和你的一些設計專業知識，看著莊園變成一座令人驚嘆的夢想之家吧！'
        ,
        'txt4': [
            '認識麻雀鎮的核心',
            '認識充滿活力的當地人，他們可是讓麻雀鎮變得很有朝氣呢：'
        ],
        'PeopleName': ['艾莉絲', '喬伊', '史黛凡諾', '奈莉', '泰勒', '阿朗'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            '富有遠見的室內設計師<br/>準備好實現你的夢想之家',
            '迷人的花藝師<br/>對綻放的花朵充滿熱情',
            '厨師所隱藏的秘密<br/>比食材還多',
            '富有愛心的明星設計師<br/>時尚與善心兼備',
            '鎮上最好的木匠<br/>同時也是最好的朋友',
            '經驗豐富的園丁<br/>有著深植於莊園的歷史'
        ],
        'txt5': [
            '令人驚嘆的視覺效果',
            '體驗令人驚嘆的開場電影，<br/>讚嘆於每個設計所選擇的細緻細節。'
        ],
        'txt6': [
            '表達你的風格',
            '選擇反映你個人風格的家俱和裝飾品。<br/>訂製你房子的每個角落，將古老的莊園變成令人驚嘆的傑作吧。',
            '趣味關卡',
            '透過獨特的強化道具、遊戲元素和爆炸性組合來掌握具有挑戰性的關卡。<br/>體驗解決謎題的快感和匹配的滿足感。'
        ],
        'txt7': [
            '發現並聯繫',
            '揭開隱藏在廣闊花園中的秘密。' +
            '<br/>和鄰居保持聯繫，' +
            '<br/>在莊園之外建立持久的友誼。'
        ],
        'txt8': '遊戲圖片',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': '查看我們的社群媒體',
        'txt10': '立即下載！',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': '揭開秘密，設計你的夢想<br/>－開始你的冒險吧！',
        'extra': '額外5%',
        'store': '商店',
        'PeopleIcon': 'games/icon.png',
        'txt2': '深入探索《我的花園》，<br/>這是一款引人入勝的免費三消益智遊戲，<br/>並且令數千萬人著迷喔。',
        'txt3': '你剛剛繼承了一處宏偉但破舊的莊園，' +
            '<br/>所以你邀請你兒時的朋友、'+
            '<br/>著名的室內設計師艾莉絲，將其改造成你夢想的家，' +
            '<br/>憑藉著奉獻精神、創造力和你的一些設計專業知識，' +
            '<br/>看著莊園變成一座令人驚嘆的夢想之家吧！',
        'txt4': [
            '認識麻雀鎮的核心',
            '認識充滿活力的當地人，<br/>他們可是讓麻雀鎮變得很有朝氣呢：'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            '艾莉絲',
            '奈莉',
            '喬伊',
            '泰勒',
            '史黛凡諾',
            '阿朗'
        ],
        'PeopleDesc': [
            '富有遠見的室內設計師<br/>準備好實現你的夢想之家',
            '富有愛心的明星設計師<br/>時尚與善心兼備',
            '迷人的花藝師<br/>對綻放的花朵充滿熱情',
            '鎮上最好的木匠<br/>同時也是最好的朋友',
            '厨師所隱藏的秘密<br/>比食材還多',
            '經驗豐富的園丁<br/>有著深植於莊園的歷史'
        ],
        'txt5': [
            '令人驚嘆的視覺效果',
            '體驗令人驚嘆的開場電影，<br/>讚嘆於每個設計所選擇的細緻細節。'
        ],
        'txt6': [
            '表達你的風格',
            '選擇反映你個人風格的家俱和裝飾品。<br/>訂製你房子的每個角落，<br/>將古老的莊園變成令人驚嘆的傑作吧。',
            '趣味關卡',
            '透過獨特的強化道具、遊戲元素<br/>和爆炸性組合來掌握具有挑戰性的關卡。<br/>體驗解決謎題的快感和匹配的滿足感。'
        ],
        'txt7': [
            '發現並聯繫',
            '揭開隱藏在廣闊花園中的秘密。' +
            '<br/>和鄰居保持聯繫，' +
            '<br/>在莊園之外建立持久的友誼。'
        ],
        'txt8': '遊戲圖片',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': '查看我們的<br/>社群媒體',
        'txt10': '立即下載！'
    },
    // 商品列表
    'product': {
        'Coins': '金幣',
        'STORE': '商店',
        'Extra': '額外',
        'Title1': '誰比你更值得<br/>一份禮物呢？',
        'Title2': '盡情享受我們超棒的禮包之一，' +
            '<br/>享受終極的幸福和無與倫比的樂趣吧！',
        'LOGIN': '登入',
        'LOGOUT': '登出',
        'UID': 'UID',
        'ShowDetails': '顯示詳細資料',
        'PaymentMethods': '支付方式',
        'MyAccount': '我的帳號',
        'UserID': 'UserID',
        'PurchaseProduct': '購買產品',
        'Price': '價格',
        'total': '全部的',
        'BuyNow': '立即購買',
        'ItemInBundle': '禮包物品',
        'PleaseSelectProduct': '請選擇您想購買的商品。',
        'PleaseSelectPay': '請選擇您的付款方式。'
    },
    'productMobile': {
        'STORE': '商店',
        'Extra': '額外',
        'Title1': '誰比你更值得<br/>一份禮物呢？',
        'Title2': '盡情享受我們超棒的<br/>禮包之一，享受終極的<br/>幸福和無與倫比的樂趣吧！',
        'LOGIN': '登入',
        'LOGOUT': '登出',
        'UID': 'UID',
        'ShowDetails': '顯示詳細資料',
        'PaymentMethods': '支付方式',

        'BuyPopTitle': '確認付款訂單',
        'MyAccount': '我的帳號',
        'UserID': 'UserID',
        'PurchaseProduct': '購買產品',
        'Price': '價格',
        'total': '全部的',
        'BuyNow': '立即購買',
        'ItemInBundle': '禮包物品',
        'PleaseSelectProduct': '請選擇您想購買的商品。',
        'PleaseSelectPay': '請選擇您的付款方式。'
    },
    // deletion 页
    'deletion': {
        'Title': '刪除遊戲帳號',
        'Logout': '登出',
        'Content': '請授權使用和你的遊戲帳號所關聯的Google帳號。',
        'AUTHORIZE': '授權',
    },
    'deletionMobile': {
        'Title': '刪除遊戲帳號',
        'Logout': '登出',
        'Content': '請授權使用和你的遊戲帳號所關聯的Google帳號。',
        'AUTHORIZE': '授權',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            '根據《歐盟一般資料保護規則》，你有權刪除我們的應用程式和我們合作夥伴的服務（購買付款、應用程式內廣告瀏覽等）中所使用的你的所有個人資料，請記住，如果你決定刪除資料，使用者帳號將被刪除且不可恢復，然而，可以從第1個關卡重新開始遊戲。',
            '帳號刪除可能會在15天內被取消，',
            '若要取消刪除，請開啟遊戲並按下取消按鈕，請注意，15天後，我們將無法撤銷刪除操作，你的遊戲也無法恢復，另請注意，刪除期間的15天之內，你無法從頭開始遊戲。'
        ],
        'DeleteGameAccount': '刪除遊戲帳號',
        'DelTitle': '確認',
        'DelContent': '你確定要刪除你的遊戲帳號嗎？',
        'DelCancel': '取消',
        'DelConfirm': '是的，我確定',
        'DelCodeLabel': [
            '找不到此用戶的遊戲帳戶。', // 0
            'Success! ', // 1
            '遊戲帳戶已提交刪除。' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        '你的資料刪除請求現已成功申請。',
        '遊戲帳號將在你提出申請後的15天內被刪除。'
    ]
}
