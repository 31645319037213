import { http } from "@/api/http/index.js";

const isDecrypt = false
const headers = {
    'Content-Type': 'application/json',
    'testgg': 'testgg',
    isDecrypt
}
// 获取cms平台配置下载链接
export const getLinkApi = (params) => {
    return http({
        // keywords 关键字唯一
        url: `https://cms.gg.com/openApi/link?keywords=${params.keywords}`,
        method: 'get',
    })
}

export const loadPageEventApi = (data) => {
    return http({
        // 打点上报
        // url: `/billing-service/v1/webiste/loadPageEvent?event_time=${params.event_time}&os_type=${params.os_type}&page_id=${params.page_id}`,
        //  method: 'get',
        url: `/billing-service/v1/webiste/loadPageEvent`,
        method: 'post',
        data,
        headers
    })
}
