export default {
  'imageAlt': 'Garden Affairs',
  // 全局error Code Map
  'errorCode': {
    'default': 'خطأ في الخادم، يرجى المحاولة مرة أخرى لاحقًا.',
    20014: 'مُعرّف المستخدم غير موجود، يُرجى إعادة الإدخال.', //  游戏用户ID错误
    10002: 'خطأ في الخادم، يرجى المحاولة مرة أخرى لاحقًا. (10002)',// 参数无效
    10008: 'خطأ في الخادم، يرجى المحاولة مرة أخرى لاحقًا. (10008)', // 数据未找到
    30008: 'خطأ في الخادم، يرجى المحاولة مرة أخرى لاحقًا. (30008)', // 商品不存在
    30007: 'خطأ في الخادم، يرجى المحاولة مرة أخرى لاحقًا. (30007)', // 商品币种类型不正确
    10001: 'خطأ في الخادم، يرجى المحاولة مرة أخرى لاحقًا. (10001)', // 服务器开小差
    10000: 'خطأ في الشبكة، يرجى التحقق من إعدادات الشبكة الخاصة بك.', // 网络异常"
  },
  // code map 下单时 映射弹窗 内容
  'codeMap': {
    // 成功弹窗
    'SuccessTitle': 'عملية الشراء ناجحة!',
    'SuccessInfo': 'شكرًا لك على الشراء! أعد تشغيل اللعبة وتحقق من صندوق <br/>البريد الخاص بك لتجميع العنصر الخاص بك.<br/> (الزاوية اليمنى العليا من الشاشة -> صندوق البريد)',
    'SuccessBtn': '好的',
    // 失败弹窗
    'ErrorTitle': 'الشراء لم ينجح!',
    'ErrorInfo': 'الشراء فشل يرجى <br/> المحاولة مرة أخرى.',
    'ErrorBtn': 'موافق'
  },
  // 登录弹窗
  'login': {
    'title1': "متجر أليس",
    'title2': "ألعاب",
    'Or': 'أو',
    'placeholder': 'أدخل معرف المستخدم',
    'LogIn': 'تسجيل الدخول',
    'SignInWithFacebook': 'تسجيل الدخول باستخدام فيسبوك (Facebook).',
    'SignInWithGoogle': 'سجّل الدخول باستخدام غوغل',
    'LoginSuccess': 'تم تسجيل الدخول بنجاح، يمكنك البدء في التسوق الآن!',
    'LoginFailed': 'فشل تسجيل الدخول! يرجى التحقق من صحة معرف المستخدم الذي أدخلته.',
    'LogOut': 'تسجيل الخروج بنجاح!',
  },
  // cookies tips
  'cookiesTip': {
    'Content': 'يستخدم هذا الموقع ملفات تعريف الارتباط لضمان حصولك على أفضل تجربة على موقع الويب لدينا.',
    'Accept': 'قبول'
  },
  // header部分
  'header': {
    'Menu': [' متجر', 'اللعبة', 'دعم', 'انتبه إلينا'],
    'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
    'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
  },
  // 底部 footer
  'footer': {
    'Menu': ['دعم', 'شروط الخدمة', 'سياسة الخصوصية', 'حذف البيانات'],
    'MenuPath': [
      'Support',
      'https://policy.judiangames.com/termsofservice-ar.html',
      'https://policy.judiangames.com/privacypolicy-ar-index.html',
      '/deletion'
    ],
    'LanguagePlaceholder': 'اللغة',
  },
  // homePage
  'homePage': {
    'txt': [
      'بادر بكشف الأسرار، وانطلق لتصميم حلمك، وخوض غمار مغامرتك!',
      'أبحر في لعبة جاردن أفيرز، وهي لعبة ألغاز مطابقة ثلاثية مجانية <br/>ورائعة وسحرت الملايين.'
    ],
    'GardenAffairs': [
      'جاردن أفيرز',
      'انضم إلى ملايين اللاعبين في لعبة Garden Affairs<br/>، لعبة الألغاز المجانية! تعال <br/>وانضم إلى أليس في خلق الحياة التي طالما حلمت بها!',
    ],
    'Custom': [
      'Garden Affairs مليء بالميزات الجديدة المثيرة، بما في ذلك:',
      'عبر عن أسلوبك',
      'اختر الأثاث والديكورات التي تظهر ذوقك الشخصي؛ ثم قم بتخصيص كل ركن من أركان منزلك،<br/> وحوّل القصر القديم إلى تحفة فنية مذهلة.',
      'قصص مثيرة للاهتمام',
      'استكشف الأسرار المخفية في الحديقة. <br/>اتبع كل منعطف في الحبكة. كون أصدقاء جدد.',
      'مستويات ممتعة',
      'أتقن مستويات التحدي باستخدام التعزيزات الفريدة من نوعها <br/>وعناصر اللعبة والعبوات المتفجرة.\n' +
      'استمتع بتجربة التشويق في حل الألغاز ورضا المطابقة.'
    ],
    'GamePictures': 'صور اللعبة'
  },
  'homePageMobile': {
    'txt': [
      'بادر بكشف الأسرار،وانطلق لتصميم حلمك، وخوض غمار مغامرتك!',
      'أبحر في لعبة جاردن أفيرز، وهي لعبة ألغاز مطابقة ثلاثية مجانيةورائعة وسحرت الملايين.'
    ],
    'GardenAffairs': [
      'جاردن أفيرز',
      'انضم إلى ملايين اللاعبين في لعبة Garden Affairs<br/>، لعبة الألغاز المجانية! تعال<br/> وانضم إلى أليس في خلق الحياة التي طالما حلمت بها!',
    ],
    'Custom': [
      'Garden Affairs مليء بالميزات الجديدة المثيرة، بما في ذلك:',
      'عبر عن أسلوبك',
      'اختر الأثاث والديكورات التي تظهر ذوقك الشخصي؛<br/> ثم قم بتخصيص كل ركن من أركان منزلك،<br/> وحوّل القصر القديم إلى تحفة فنية مذهلة.',
      'قصص مثيرة للاهتمام',
      'استكشف الأسرار المخفية في الحديقة. <br/>اتبع كل منعطف في الحبكة. كون أصدقاء جدد.',
      'مستويات ممتعة',
      'أتقن مستويات التحدي باستخدام التعزيزات الفريدة من نوعها<br/> وعناصر اللعبة والعبوات المتفجرة.' +
      'استمتع بتجربة<br/> التشويق في حل الألغاز ورضا المطابقة.'
    ],
    'GamePictures': 'صور اللعبة'
  },
  // GAME页 pc端
  'games': {
    'GardenAffairs': 'games/logo.png',
    'txt1': 'بادر بكشف الأسرار، وانطلق لتصميم <br/>حلمك، وخوض غمار مغامرتك!',
    'extra': '5% إضافي',
    'extraFontSize': '1.2',
    'store': ' متجر',
    'storeFontSize': '2.2',
    'PeopleIcon': 'games/icon.png',
    'txt2': 'أبحر في لعبة جاردن أفيرز، <br/>وهي لعبة ألغاز مطابقة ثلاثية مجانية<br/> ورائعة وسحرت الملايين.',
    'txt3': 'لقد ورثت للتو قصرًا كبيرًا ولكن متهالكًا، ومن ثم تستعين بصديقة طفولتك «أليس» مُصممة الديكورات الداخلية الشهيرة،' +
        '<br/>' +
        ' لتحويله إلى منزل أحلامك. وبفضل تفانيك وإبداعك وقليل من خبرتك المؤثرة في التصميم،' +
        '<br/> شاهد القصر وهو يتحول إلى منزل أحلام مذهل!' ,
    'txt4': [
      'تعرف على قلب ماتشفيو',
      'تعرف على السكان المحليين النابضين بالحياة الذين أضفوا على ماتشفيو أجواء حياتية مميزة:'
    ],
    'PeopleName': [' أليس', 'جُوي', 'ستيفانو', 'نيللي', 'تايلور', 'ألان'],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people2.png',
      'games/people3.png',
      'games/people4.png',
      'games/people5.png',
      'games/people6.png',
    ],
    'PeopleDesc': [
      'المُصمم الداخلي صاحب الرؤية جاهز لبناء منزل أحلامك',
      'بائع الزهور الساحر لديه شغف بالزهور المتفتحة',
      'رئيس الطهاة يخفي سواعده أكثر من المكونات',
      'المصمم الشهير ذي قلب عامر بالحب',
      'أفضل نجار في المدينة وأفضل صديق أيضًا',
      'البستاني المتمرس ذو الجذور العميقة في تاريخ القصر'
    ],
    'txt5': [
      'مرئيات مذهلة',
      'استمتع بتجربة افتتاحية سينمائية مذهلة وتعجب من التفاصيل الدقيقة في كل اختيار تصميمي.',
    ],
    'txt6': [
      'عبر عن أسلوبك',
      'اختر الأثاث <br/>والديكورات التي تظهر ذوقك الشخصي؛ ثم قم بتخصيص كل ركن من أركان منزلك، <br/>وحوّل القصر القديم إلى تحفة فنية مذهلة.',
      'مستويات ممتعة',
      'أتقن مستويات التحدي باستخدام التعزيزات الفريدة من نوعها <br/>وعناصر اللعبة والعبوات المتفجرة.' +
      'استمتع بتجربة التشويق في حل الألغاز <br/>ورضا المطابقة.'
    ],
    'txt7': [
      'استمتع<br/> بالاكتشاف والتواصل',
      'بادر بكشف الألغاز المخفية داخل الحدائق الواسعة.<br/>' +
      'تواصل مع الجيران وكوّن صداقات دائمة خارج أسوار القصر.'
    ],
    'txt8': 'صور اللعبة',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': 'تحقق من وسائل التواصل الاجتماعي لدينا',
    'txt10': 'قم بالتنزيل الآن!',
  },
  // GAME页 移动端
  'gamesMobile': {
    'GardenAffairs': 'games/logo.png',
    'txt1': 'بادر بكشف الأسرار، وانطلق لتصميم <br/>حلمك، وخوض غمار مغامرتك!',
    'extra': '5% إضافي',
    'extraFontSize': '1.3',
    'store': ' متجر',
    'storeFontSize': '2',
    'PeopleIcon': 'games/icon.png',
    'txt2': 'أبحر في لعبة جاردن أفيرز، <br/>وهي لعبة ألغاز مطابقة ثلاثية مجانية<br/> ورائعة وسحرت الملايين.',
    'txt3': 'لقد ورثت للتو قصرًا كبيرًا ولكن متهالكًا، <br/>ومن ثم تستعين بصديقة طفولتك «أليس» <br/>مُصممة الديكورات الداخلية الشهيرة،<br/> لتحويله إلى منزل أحلامك. وبفضل تفانيك وإبداعك <br/>وقليل من خبرتك المؤثرة في التصميم، شاهد القصر <br/>وهو يتحول إلى منزل أحلام مذهل!',
    'txt4': [
      'تعرف على قلب ماتشفيو',
      'تعرف على السكان المحليين النابضين بالحياة <br/>الذين أضفوا على ماتشفيو أجواء حياتية مميزة:'
    ],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people4.png',
      'games/people2.png',
      'games/people5.png',
      'games/people3.png',
      'games/people6.png',
    ],
    'PeopleName': [' أليس', 'نيللي', 'جُوي', 'تايلور', 'ستيفانو', 'ألان'],
    'PeopleDesc': [
      'المُصمم الداخلي صاحب الرؤية جاهز لبناء منزل أحلامك',
      'المصمم الشهير ذي قلب عامر بالحب',
      'بائع الزهور الساحر لديه شغف بالزهور المتفتحة',
      'أفضل نجار في المدينة وأفضل صديق أيضًا',
      'رئيس الطهاة يخفي سواعده أكثر من المكونات',
      'البستاني المتمرس ذو الجذور العميقة في تاريخ القصر'
    ],
    'txt5': [
      'مرئيات مذهلة',
      'استمتع بتجربة افتتاحية سينمائية مذهلة<br/> وتعجب من التفاصيل الدقيقة في كل اختيار تصميمي.',
    ],
    'txt6': [
      'عبر عن أسلوبك',
      'اختر الأثاث والديكورات التي تظهر ذوقك الشخصي؛<br/> ثم قم بتخصيص كل ركن من أركان منزلك، <br/>وحوّل القصر القديم إلى تحفة فنية مذهلة.',
      'مستويات ممتعة',
      'أتقن مستويات التحدي باستخدام التعزيزات الفريدة من<br/> نوعها وعناصر اللعبة والعبوات المتفجرة.' +
      'استمتع بتجربة <br/> التشويق في حل الألغاز ورضا المطابقة.'
    ],

    'txt7': [
      'استمتع بالاكتشاف والتواصل',
      'بادر بكشف الألغاز المخفية<br/> داخل الحدائق الواسعةn' +
      'تواصل مع الجيران <br/>وكوّن صداقات دائمة خارج أسوار القصر.'
    ],
    'txt8': 'صور اللعبة',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': 'تحقق من وسائل التواصل<br/> الاجتماعي لدينا',
    'txt10': 'قم بالتنزيل الآن!'
  },
  // 商品列表
  'product': {
    'Coins': 'عملات نقدية',
    'STORE': ' متجر',
    'Extra': ' إضافي',
    'Title1': 'من الذي يستحق الهدية أكثر منك؟',
    'Title2': 'أبحر في إحدى حزمنا الرائعة للاستمتاع بالسعادة المطلقة<br/> والمرح الذي لا يقبل المنافسة!',
    'LOGIN': 'تسجيل الدخول',
    'LOGOUT': 'تسجيل الخروج',
    'UID': 'UID',
    'ShowDetails': 'إظهار التفاصيل',
    'PaymentMethods': 'طرق الدفع',
    'MyAccount': 'حسابي',
    'UserID': 'معرف المستخدم',
    'PurchaseProduct': 'شراء المنتج',
    'Price': 'السعر',
    'total': 'المجموع',
    'BuyNow': 'اشتر الآن',
    'ItemInBundle': 'عناصر الباقة',
    'PleaseSelectProduct': 'يرجى اختيار العنصر الذي ترغب في شرائه.',
    'PleaseSelectPay': 'يرجى اختيار طريقة الدفع الخاصة بك.'
  },
  'productMobile': {
    'STORE': ' متجر',
    'Extra': ' إضافي',
    'Title1': 'من الذي يستحق الهدية أكثر منك؟',
    'Title2': 'أبحر في إحدى حزمنا الرائعة<br/> للاستمتاع بالسعادة المطلقة<br/> والمرح الذي لا يقبل المنافسة!',
    'LOGIN': 'تسجيل الدخول',
    'LOGOUT': 'تسجيل الخروج',
    'UID': 'UID',
    'ShowDetails': 'إظهار التفاصيل',
    'PaymentMethods': 'طرق الدفع',
    'BuyPopTitle': 'تأكيد طلب الدفع',
    'MyAccount': 'حسابي',
    'UserID': 'معرف المستخدم',
    'PurchaseProduct': 'شراء المنتج',
    'Price': 'السعر',
    'total': 'المجموع',
    'BuyNow': 'اشتر الآن',
    'ItemInBundle': 'عناصر الباقة',
    'PleaseSelectProduct': 'يرجى اختيار العنصر الذي ترغب في شرائه.',
    'PleaseSelectPay': 'يرجى اختيار طريقة الدفع الخاصة بك.'
  },
  // deletion 页
  'deletion': {
    'Title': 'حذف حساب لعبة الأندرويد',
    'Logout': 'تسجيل الخروج',
    'Content': 'من فضلك، قم بالترخيص باستخدام حساب جوجل المرتبط بحساب اللعبة الخاص بك.',
    'AUTHORIZE': 'تصريح',
  },
  'deletionMobile': {
    'Title': 'حذف حساب لعبة الأندرويد',
    'Logout': 'تسجيل الخروج',
    'Content': 'من فضلك، قم بالترخيص باستخدام حساب جوجل المرتبط بحساب اللعبة الخاص بك.',
    'AUTHORIZE': 'تصريح',
  },
  // authSuccess 页
  'authSuccess': {
    'content': [
      'بموجب اللائحة العامة لحماية البيانات، لديك الحق في حذف جميع بياناتك الشخصية المستخدمة في تطبيقنا وخدمات شركائنا (مدفوعات الشراء، ومشاهدات الإعلانات داخل التطبيق، وما إلى ذلك). يُرجى الأخذ في الاعتبار أنه إذا قررت حذف البيانات، فسيتم حذف حساب المستخدم ولن يكون قابلاً للاستعادة. ومع ذلك، سيكون من الممكن بدء اللعبة من المستوى الأول.',
      '. يُرجى أيضًا مراعاة أنه خلال 15 يومًا خلال فترة الحذف، لا يمكنك إعادة تشغيل اللعبة من البداية.',
      'قد يتم إلغاء حذف الحساب خلال 15 يومًا. لإلغاء الحذف، افتح اللعبة واضغط على زر "الإلغاء". يُرجى ملاحظة أنه بعد 15 يومًا، يتعذر عكس عملية الحذف، ولا يمكن استعادة لعبتك'
    ],
    'DeleteGameAccount': 'حذف حساب لعبة الأندرويد',
    'DelTitle': 'تأكيد',
    'DelContent': 'هل أنت متأكد أنك تريد حذف حساب اللعبة الخاص بك؟',
    'DelCancel': 'إلغاء',
    'DelConfirm': 'نعم أنا متأكد',
    'DelCodeLabel': [
      'لم يتم العثور على حساب اللعبة لهذا المستخدم.', // 0
      'النجاح', // 1
      'تم تقديم حساب اللعبة لحذفه.' // 2
    ]
  },
  // Del Success 页
  'delSuccess': [
    'لقد تم تسجيل طلبك لحذف البيانات بنجاح.',
    'سيتم حذف حساب اللعبة خلال 15 يومًا من طلبك.'
  ]
}
