import '@/assets/less/index.less';

import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {install} from '@icon-park/vue-next/es/all';
import {ElMessage} from 'element-plus';
import 'element-plus/dist/index.css'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import YouTube from 'vue3-youtube'

import App from './App.vue'
import router from './router'
import '@/permission.js';
import '@/utils/rem.js'
import i18n from "@/lang/index.js";
import {openBodyScroll, stopBodyScroll} from '@/tools/DialogScroll';
import VPreventReClick from '@/tools/v-preventReClick';

const app = createApp(App)
install(app);

app.use(createPinia())
app.use(router)
app.use(i18n);
app.use(VueDOMPurifyHTML);
app.use(VueVideoPlayer);
app.use(VPreventReClick);
// app.use(VueYouTubeEmbed)
// app.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" })
app.use(YouTube);

app.mount('#app')
app.provide('$message', ElMessage)
app.provide('$t', i18n.global.t);
app.provide('openBodyScroll', openBodyScroll)
app.provide('stopBodyScroll', stopBodyScroll)
console.log('NODE_ENV:',process.env.VITE_USER_NODE_ENV);

