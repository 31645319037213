<script setup>
import {useRoute, useRouter} from 'vue-router'
import {ElBacktop} from "element-plus";
import CookiesTips from '@/components/cookiesTips';

import useStore from "@/stores/index.js";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";

const route = useRoute()
const router = useRouter();

const {userStore} = useStore()
const {language, country} = storeToRefs(userStore);
const {getCountry} = userStore;
const {locale} = useI18n()

const init = async () => {
  getCountry().then().finally(() => {
    locale.value = language.value;
  })
}

if (route.path !== '/store') {
  init()
}
</script>

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade-transform" mode="out-in">
      <div :key="route.path" style="width: 100%;height: 100%;">
        <component :is="Component"></component>
        <el-backtop class="custom_up" :right="20">
          <img src="@/assets/images/up.png"
               :alt="$t('imageAlt')"
               class="go_up">
        </el-backtop>
        <cookies-tips/>
      </div>
    </transition>
  </router-view>
</template>

<style scoped lang="less">
@import 'index.less';
</style>
