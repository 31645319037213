export default {
  'imageAlt': 'Garden Affairs',
  // 全局error Code Map
  'errorCode': {
    'default': '服务器异常，请稍后再试。',
    20014: '用户账号不存在，请重新输入。', //  游戏用户ID错误
    10002: '服务器异常，请稍后再试。 (10002)',// 参数无效
    10008: '服务器异常，请稍后再试。 (10008)', // 数据未找到
    30008: '服务器异常，请稍后再试。 (30008)', // 商品不存在
    30007: '服务器异常，请稍后再试。 (30007)', // 商品币种类型不正确
    10001: '服务器异常，请稍后再试。 (10001)', // 服务器开小差
    10000: '网络异常，请检查您的网络设置。', // 网络异常"
  },
  // code map 下单时 映射弹窗 内容
  'codeMap': {
    // 成功弹窗
    'SuccessTitle': '购买成功!',
    'SuccessInfo': '感谢您的购买！<br/>现在您可以重启游戏，在邮箱中查收您的商品了。祝您游戏愉快！<br/>（屏幕右上角->邮箱）',
    'SuccessBtn': '好的',
    // 失败弹窗
    'ErrorTitle': '购买失败!',
    'ErrorInfo': '购买失败！<br/>您可以稍后再进行尝试。',
    'ErrorBtn': '确认'
  },
  // 登录弹窗
  'login': {
    'title1': "爱丽丝的商店",
    'title2': "游戏",
    'Or': '或者',
    'placeholder': '输入账号',
    'LogIn': '登录',
    'SignInWithFacebook': '通过Facebook登录',
    'SignInWithGoogle': '通过Google登录',
    'LoginSuccess': '登录成功，您可以开始购物啦！',
    'LoginFailed': '登录失败！请检查您输入的用户ID是否有误。',
    'LogOut': '登出成功！',
  },
  // cookies tips
  'cookiesTip': {
    'Content': '本网站使用 Cookie 来确保您在本网站获得最佳体验。',
    'Accept': '接受'
  },
  // header部分
  'header': {
    'Menu': ['商店', '游戏', '支持', '关注我们'],
    'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
    'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
  },
  // 底部 footer
  'footer': {
    'Menu': ['支持', '用户协议', '隐私协议', '数据删除'],
    'MenuPath': [
      'Support',
      'https://policy.judiangames.com/termsofservice-sc.html',
      'https://policy.judiangames.com/privacypolicy-sc-index.html',
      '/deletion'
    ],
    'LanguagePlaceholder': '语言',
  },
  // homePage
  'homePage': {
    'txt': [
      '揭晓秘密，设计你的梦想 ——开始你的冒险！',
      '《我的花园》<br/>一款引人入胜的免费三消游戏，已经吸引了上千万玩家。'
    ],
    'GardenAffairs': [
      '我的花园',
      '加入上千万玩家的行列，<br/>体验免费益智游戏《我的花园》！<br/>来和爱丽丝一起创造你梦寐以求的生活吧！',
    ],
    'Custom': [
      '我可以在《我的花园》中拥有什么样的体验？',
      '展现您的风格',
      '选择体现个人风格的家具和装饰品。<br/>定制房屋的每个角落，将古老的庄园变成巧夺天工的杰作！',
      '引人入胜的故事',
      '探索花园中隐藏的秘密。<br />关注情节中的每一个转折，结识新朋友！',
      '趣味关卡',
      '掌握具有独特道具、游戏元素和爆炸组合的挑战性关卡。<br/>体验解谜的快感和配对的满足感！'
    ],
    'GamePictures': '游戏图片'
  },
  'homePageMobile': {
    'txt': [
      '揭晓秘密，设计你的梦想<br/>——开始你的冒险！',
      '《我的花园》，<br/>一款引人入胜的免费三消游戏，<br/>已经吸引了上千万玩家。'
    ],
    'GardenAffairs': [
      '我的花园',
      '加入上千万玩家的行列，<br/>体验免费益智游戏《我的花园》！<br/>来和爱丽丝一起创造你梦寐以求的生活吧！',
    ],
    'Custom': [
      '我可以在《我的花园》中拥有什么样的体验？',
      '展现您的风格',
      '选择体现个人风格的家具和装饰品。<br/>定制房屋的每个角落，<br/>将古老的庄园变成巧夺天工的杰作！',
      '引人入胜的故事',
      '探索花园中隐藏的秘密。<br />关注情节中的每一个转折，结识新朋友！',
      '趣味关卡',
      '掌握具有独特道具、游戏元素<br/>和爆炸组合的挑战性关卡。<br/>体验解谜的快感和配对的满足感！'
    ],
    'GamePictures': '游戏图片'
  },
  // GAME页 pc端
  'games': {
    'GardenAffairs': 'games/logo.png',
    'txt1': '揭晓秘密，设计你的梦想<br/>——开始你的冒险!',
    'extra': '5% 加量',
    'store': '商店',
    'PeopleIcon': 'games/icon.png',
    'txt2': '《我的花园》，<br/>一款引人入胜的免费三消游戏，<br/>已经吸引了上千万玩家。',
    'txt3': '你刚刚继承了一座宏伟但破旧的庄园，' +
      '<br/>因此你邀请了你的童年好友兼著名室内设计师——爱丽丝，来将这里改造成你理想中的家园。' +
      '<br/>通过你的奉献、创造力和设计专长，看看庄园如何变成一个令人惊叹的梦想之家！',
    'txt4': [
      '麻雀镇的核心人物',
      '来认识让麻雀镇充满活力的他们：'
    ],
    'PeopleName': ['ALICE', 'JOEY', 'STEFANO', 'NELLIE', 'TAYLOR', 'ALAN'],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people2.png',
      'games/people3.png',
      'games/people4.png',
      'games/people5.png',
      'games/people6.png',
    ],
    'PeopleDesc': [
      '一位富有远见的室内设计师<br/>她将为你打造属于你的梦想之家',
      '迷人的花店老板<br/>对盛开的花朵充满热情',
      '大厨的袖子里<br/>藏着的不仅仅是食材',
      '富有爱心的明星设计师<br/>时尚与善心兼备',
      '城里最好的木匠<br/>也是最好的伙伴',
      '与庄园历史渊源深厚的<br/>资深园丁'
    ],
    'txt5': [
      '令人惊叹的视觉效果',
      '体验震撼人心的开场电影，<br/>惊叹于每一个设计细节的精雕细琢。',
    ],
    'txt6': [
      '展现您的风格',
      '选择体现个人风格的家具和装饰品。<br/>定制房屋的每个角落，将古老的庄园变成巧夺天工的杰作。',
      '趣味关卡',
      '掌握具有独特道具、游戏元素和爆炸组合的挑战性关卡。<br/>体验解谜的快感和配对的满足感。'
    ],
    'txt7': [
      '探索与联络',
      '揭开隐藏在广阔花园中的神秘面纱。' +
      '<br/>在庄园的围墙之外，' +
      '<br/>与邻居们交流，建立恒久的友谊。'
    ],
    'txt8': '游戏图片',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': '查看我们的社交媒体',
    'txt10': '立即下载！',
  },
  // GAME页 移动端
  'gamesMobile': {
    'GardenAffairs': 'games/logo.png',
    'txt1': '揭晓秘密，设计你的梦想<br/>——开始你的冒险!',
    'extra': '5% 加量',
    'store': '商店',
    'PeopleIcon': 'games/icon.png',
    'txt2': '《我的花园》，<br/>一款引人入胜的免费三消游戏，<br/>已经吸引了上千万玩家。',
    'txt3': '你刚刚继承了一座宏伟但破旧的庄园，' +
      '<br/>因此你邀请了你的童年好友兼著名室内设计师' +
      '<br/>——爱丽丝，来将这里改造成你理想中的家园。' +
      '<br/>通过你的奉献、创造力和设计专长，' +
      '<br/>看看庄园如何变成一个令人惊叹的梦想之家！',
    'txt4': [
      '麻雀镇的核心人物',
      '来认识让麻雀镇充满活力的他们：'
    ],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people4.png',
      'games/people2.png',
      'games/people5.png',
      'games/people3.png',
      'games/people6.png',
    ],
    'PeopleName': ['ALICE', 'NELLIE', 'JOEY', 'TAYLOR', 'STEFANO', 'ALAN'],
    'PeopleDesc': [
      '一位富有远见的室内设计师<br/>她将为你打造属于你的梦想之家',
      '富有爱心的明星设计师<br/>时尚与善心兼备',
      '迷人的花店老板<br/>对盛开的花朵充满热情',
      '城里最好的木匠<br/>也是最好的伙伴',
      '大厨的袖子里<br/>藏着的不仅仅是食材',
      '与庄园历史渊源深厚的<br/>资深园丁'
    ],
    'txt5': [
      '令人惊叹的视觉效果',
      '体验震撼人心的开场电影，<br/>惊叹于每一个设计细节的精雕细琢。',
    ],
    'txt6': [
      '展现您的风格',
      '选择体现个人风格的家具和装饰品。' +
      '<br/>定制房屋的每个角落，' +
      '<br/>将古老的庄园变成巧夺天工的杰作。',
      '趣味关卡',
      '掌握具有独特道具、游戏元素' +
      '<br/>和爆炸组合的挑战性关卡。' +
      '<br/>体验解谜的快感和配对的满足感。'
    ],

    'txt7': [
      '探索与联络',
      '揭开隐藏在广阔花园中的神秘面纱。' +
      '<br/>在庄园的围墙之外，' +
      '<br/>与邻居们交流，建立恒久的友谊。'
    ],
    'txt8': '游戏图片',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': '查看我们的 <br/>社交媒体',
    'txt10': '立即下载！'
  },
  // 商品列表
  'product': {
    'Coins': '金币',
    'STORE': '商店',
    'Extra': '加量',
    'Title1': '惊喜加量，<br/>纵享欢乐！',
    'Title2': '为自己购买一份超值礼物，<br/>尽情享受美好游戏时光！',
    'LOGIN': '登录',
    'LOGOUT': '注销',
    'UID': 'UID',
    'ShowDetails': '更多信息',
    'PaymentMethods': '购买方式',
    'MyAccount': '我的账户',
    'UserID': 'UserID',
    'PurchaseProduct': '购买商品',
    'Price': '价格',
    'total': '总价',
    'BuyNow': '立刻购买',
    'ItemInBundle': '礼包内容',
    'PleaseSelectProduct': '请选择您要购买的商品。',
    'PleaseSelectPay': '请选择您的支付方式。'
  },
  'productMobile': {
    'STORE': '商店',
    'Extra': '加量',
    'Title1': '惊喜加量，纵享欢乐！',
    'Title2': '为自己购买一份<br/>超值礼物，<br/>尽情享受美好游戏时光！',
    'LOGIN': '登录',
    'LOGOUT': '注销',
    'UID': 'UID',
    'ShowDetails': '更多信息',
    'PaymentMethods': '购买方式',
    'BuyPopTitle': '确认支付订单',
    'MyAccount': '我的账户',
    'UserID': 'UserID',
    'PurchaseProduct': '购买商品',
    'Price': '价格',
    'total': '总价',
    'BuyNow': '立刻购买',
    'ItemInBundle': '礼包内容',
    'PleaseSelectProduct': '请选择您要购买的商品。',
    'PleaseSelectPay': '请选择您的支付方式。'
  },
  // deletion 页
  'deletion': {
    'Title': '删除游戏帐户',
    'Logout': '注销',
    'Content': '请授权使用与您的游戏帐户相连的 Google 帐户。',
    'AUTHORIZE': '授权',
  },
  'deletionMobile': {
    'Title': '删除游戏帐户',
    'Logout': '注销',
    'Content': '请授权使用与您的游戏帐户相连的 Google 帐户。',
    'AUTHORIZE': '授权',
  },
  // authSuccess 页
  'authSuccess': {
    'content': [
      '根据《通用数据保护条例》（General Data Protection Regulation），您有权删除在我们的应用程序和合作伙伴服务（购买支付、应用内广告浏览等）中使用的所有个人数据。请注意，如果您决定删除数据，用户账户将被删除，且无法恢复。不过，您可以从第 1 级重新开始游戏。',
      '账户删除可在 15 天内取消。',
      '要取消删除，请打开游戏并按 “取消 ”按钮。请注意，15 天后我们将无法撤销删除，您的游戏也无法恢复。同时请注意，在删除期间的 15 天内，您无法从头开始重新启动游戏。'
    ],
    'DeleteGameAccount': '删除游戏账号',
    'DelTitle': '确认',
    'DelContent': '您确定要删除游戏账户吗？',
    'DelCancel': '取消',
    'DelConfirm': '是的，我确定',
    'DelCodeLabel': [
      '未找到此用户的游戏账户。', // 0
      '成功! ', // 1
      '该游戏账户已经提交删除请求。' // 2
    ]
  },
  // Del Success 页
  'delSuccess': [
    '您的数据删除请求已成功注册。',
    '游戏账户将在您提出申请后 15 天内删除。'
  ]
}
