export default [
  {
    label: 'English',
    value: 'en',
    language: '英语'
  },
  {
    label: '简体中文',
    value: 'zh-Hans',
    language: '简体中文'
  },
  {
    label: '繁体中文',
    value: 'zh-Hant',
    language: '繁体中文'
  },
  {
    label: '日本語',
    value: 'ja',
    language: '日语'
  },
  {
    label: 'Русский',
    value: 'ru',
    language: '俄罗斯语'
  },
  {
    label: 'Français',
    value: 'fr',
    language: '法语'
  },
  {
    label: 'Deutsch',
    value: 'de',
    language: '德语'
  },
  {
    label: 'Italiano',
    value: 'it',
    language: '意大利语'
  },
  {
    label: 'Español',
    value: 'es',
    language: '西班牙语'
  },
  {
    label: '한국어',
    value: 'ko',
    language: '韩语'
  },
  {
    label: 'عربي',
    value: 'ar',
    language: '阿拉伯语'
  },
  {
    label: 'Bahasa Indonesia',
    value: 'id',
    language: '印尼语'
  },
  {
    label: 'ไทย',
    value: 'th',
    language: '泰语'
  },
  {
    label: 'Português',
    value: 'pt',
    language: '葡萄牙语'
  },
  {
    label: 'Türkçe',
    value: 'tr',
    language: '土耳其语'
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    language: '越南语'
  },
  {
    label: 'Nederlands',
    value: 'nl',
    language: '荷兰语'
  },
]
