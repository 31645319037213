export default {
  'imageAlt': 'Garden Affairs',
  // 全局error Code Map
  'errorCode': {
    'default': 'Ошибка сервера, попробуйте позже.',
    20014: 'ID пользователя не существует. Войдите заново.', //  游戏用户ID错误
    10002: 'Ошибка сервера, попробуйте позже. (10002)',// 参数无效
    10008: 'Ошибка сервера, попробуйте позже. (10008)', // 数据未找到
    30008: 'Ошибка сервера, попробуйте позже. (30008)', // 商品不存在
    30007: 'Ошибка сервера, попробуйте позже. (30007)', // 商品币种类型不正确
    10001: 'Ошибка сервера, попробуйте позже. (10001)', // 服务器开小差
    10000: 'Сетевая ошибка, пожалуйста, проверьте настройки сети.', // 网络异常"
  },
  // code map 下单时 映射弹窗 内容
  'codeMap': {
    // 成功弹窗
    'SuccessTitle': 'Покупка успешна!',
    'SuccessInfo': 'Благодарим за покупку!<br/>Перезапустите игру и проверьте почту, <br/>чтобы забрать предмет. <br/>(Правый верхний угол экрана -> Почта)!',
    'SuccessBtn': 'Отлично',
    // 失败弹窗
    'ErrorTitle': 'Не удалось купить!',
    'ErrorInfo': 'Покупка не удалась. <br/>Пожалуйста! попробуйте снова.',
    'ErrorBtn': 'OK'
  },
  // 登录弹窗
  'login': {
    'title1': "Магазин Алисы",
    'title2': "Игры",
    'Or': 'или',
    'placeholder': 'Введите идентификатор пользователя',
    'LogIn': 'Войти',
    'SignInWithFacebook': 'Войти с помощью Facebook',
    'SignInWithGoogle': 'Войдите в учетную запись Google',
    'LoginSuccess': 'Вход выполнен успешно, теперь вы можете начать покупки!',
    'LoginFailed': 'Ошибка входа! Пожалуйста, проверьте правильность введенного идентификатора пользователя.',
    'LogOut': 'Успешный выход!',
  },
  // cookies tips
  'cookiesTip': {
    'Content': 'На этом сайте используются файлы cookie, <br/>чтобы обеспечить наилучшие условия пользования нашим сайтом.',
    'Accept': 'Принять'
  },
  // header部分
  'header': {
    'Menu': ['МАГАЗИ', 'Игры', 'Поддержка', 'Подписаться'],
    'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
    'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
  },
  // 底部 footer
  'footer': {
    'Menu': [
      'Поддержка',
      'Условия использования',
      'Политика конфиденциальности',
      'Удаление данных'
    ],
    'MenuPath': [
      'Support',
      'https://policy.judiangames.com/termsofservice-ru.html',
      'https://policy.judiangames.com/privacypolicy-ru.html',
      '/deletion'
    ],
    'LanguagePlaceholder': 'язык',
  },
  // homePage
  'homePage': {
    'txt': [
      'Раскройте секреты, воплотите мечты — начните!',
      'Погрузитесь в Garden Affairs — бесплатную игру-головоломку «три в ряд», <br/>очаровавшую миллионы.'
    ],
    'GardenAffairs': [
      'Garden Affairs',
      'Присоединяйтесь к миллионам игроков в Garden Affairs<br/> и создайте жизнь мечты!'
    ],
    'Custom': [
      'Garden Affairs полон захватывающих новых функций, включая:',
      'Выразите свой стиль',
      'Выбирайте мебель и декор. Настраивайте каждый уголок, <br/>превращая поместье в искусство!',
      // 'Выбирайте мебель и декор, отражающие ваш стиль.Настраивайте каждый уголок дома,<br/> превращая старое поместье в произведение искусства!',
      'Захватывающие Истории',
      'Исследуйте тайны сада, следуйте сюжету, <br/>заводите новых друзей.',
      'Крутые уровни',
      'Проходите уровни с бустерами, элементами и комбинациями. <br/>Испытайте азарт и восторг от игры!'
    ],
    'GamePictures': 'Картинки из игры'
  },
  'homePageMobile': {
    'txt': [
      'Раскройте секреты и <br/>воплотите мечты <br/>— начните своё приключение!',
      'Погрузитесь в Garden Affairs<br/>— увлекательную бесплатную игру-головоломку <br/>в жанре «три в ряд», очаровавшую миллионы.',
    ],
    'GardenAffairs': [
      'Garden Affairs',
      'Присоединяйтесь к миллионам игроков в <br/> Garden Affairs<br/> и создайте жизнь мечты!'
    ],
    'Custom': [
      'Garden Affairs полон захватывающих<br/>новых функций, включая:',
      'Выразите свой стиль',
      'Выбирайте мебель и декор.<br/> Настраивайте каждый уголок, <br/>превращая поместье в искусство!',
      // 'Выбирайте мебель и декор, отражающие ваш стиль.<br/>Настраивайте каждый уголок дома,<br/> превращая старое поместье в произведение искусства!',
      'Захватывающие Истории',
      'Исследуйте тайны сада, следуйте сюжету, <br/>заводите новых друзей.',
      'Крутые уровни',
      'Проходите уровни с бустерами, элементами и<br/> комбинациями. Испытайте азарт и восторг от игры!'
    ],
    'GamePictures': 'Картинки из игры'
  },
  // GAME页 pc端
  'games': {
    'GardenAffairs': 'games/logo.png',
    'txt1': 'Раскройте секреты, воплотите мечты <br/>— начните!',
    'extra': '5% Доп.',
    'store': 'МАГАЗИ',
    'PeopleIcon': 'games/icon.png',
    'txt2': 'Погрузитесь в Garden Affairs<br/>бесплатную игру-головоломку «три в ряд»,<br/>очаровавшую миллионы.',
    'txt3': 'Вы унаследовали полуразрушенное поместье и поручили Алисе, <br/>дизайнеру, превратить его в дом мечты. <br/>Благодаря её таланту поместье станет завораживающим!',
    'txt4': [
      'Встречайте сердце Матчвью',
      'Познакомьтесь с жителями, возвращающими жизнь в Матчвью:'
    ],
    'PeopleName': ['Алиса', 'Джои', 'Стефано', 'Нэлли', 'Тейлор', 'Алан'],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people2.png',
      'games/people3.png',
      'games/people4.png',
      'games/people5.png',
      'games/people6.png',
    ],
    'PeopleDesc': [
      'Великолепный дизайнер интерьеров',
      'Очаровательный флорист, увлеченный цветами.',
      'Шеф-повар, скрывающий не только ингредиенты.',
      'Знаменитый дизайнер с заботливым сердцем',
      'Лучший плотник в городе и лучший друг',
      'Опытный садовник, чьи корни уходят глубоко в историю поместья'
    ],
    'txt5': [
      'Потрясающая графика',
      'Насладитесь потрясающим вступительным роликом и восхититесь тщательной проработкой нюансов дизайна.'
    ],
    'txt6': [
      'Выразите свой стиль',
      'Выбирайте мебель и декор. <br/>Настраивайте каждый уголок, превращая поместье в искусство!',
      'Крутые уровни',
      'Проходите уровни с бустерами,<br/> элементами и комбинациями. Испытайте азарт и восторг от игры!'
    ],
    'txt7': [
      'Открывайте и <br/>общайтесь',
      'Откройте тайны садов.<br/> Общайтесь с соседями <br/>и завязывайте дружбу в поместье.'
    ],
    'txt8': 'Картинки из игры',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': 'Загляните в наши соцсети',
    'txt10': 'Скачайте сейчас!',
  },
  // GAME页 移动端
  'gamesMobile': {
    'GardenAffairs': 'games/logo.png',
    'txt1': 'Раскройте секреты, воплотите мечты <br/>— начните!',
    'extra': '5% Доп.',
    'store': 'МАГАЗИ',
    'extraFontSize': '1.4',
    'storeFontSize': '2',
    'PeopleIcon': 'games/icon.png',
    'txt2': 'Погрузитесь в Garden Affairs<br/>бесплатную игру-головоломку <br/>«три в ряд», очаровавшую миллионы.',
    'txt3': 'Вы унаследовали полуразрушенное <br/>поместье и поручили Алисе, <br/>дизайнеру, превратить его в дом мечты. <br/>Благодаря её таланту поместье станет завораживающим!',
    'txt4': [
      'Встречайте сердце Матчвью',
      'Познакомьтесь с жителями, <br/>возвращающими жизнь в Матчвью:',
    ],
    'PeopleAvatar': [
      'games/people1.png',
      'games/people4.png',
      'games/people2.png',
      'games/people5.png',
      'games/people3.png',
      'games/people6.png',
    ],
    'PeopleName': [
      'Алиса',
      'Нэлли',
      'Джои',
      'Тейлор',
      'Стефано',
      'Алан'
    ],
    'PeopleDesc': [
      'Великолепный дизайнер интерьеров',
      'Знаменитый дизайнер с заботливым сердцем',
      'Очаровательный флорист<br/> увлеченный цветами',
      'Лучший плотник в городе и лучший друг',
      'Шеф-повар, скрывающий не <br/>только ингредиенты в рукаве',
      'Опытный садовник, чьи корни уходят<br/> глубоко в историю поместья'
    ],
    'txt5': [
      'Потрясающая графика',
      'Насладитесь потрясающим вступительным ' +
      '<br/>роликом и восхититесь тщательной ' +
      '<br/>проработкой нюансов дизайна.'
    ],
    'txt6': [
      'Выразите свой стиль',
      'Выбирайте мебель и декор. <br/>Настраивайте каждый уголок, <br/>превращая поместье в искусство!',
      'Крутые уровни',
      'Проходите уровни с бустерами,<br/> элементами и комбинациями.<br/> Испытайте азарт и восторг от игры!'
    ],
    'txt7': [
      'Открывайте и общайтесь',
      'Откройте тайны садов.<br/> Общайтесь с соседями <br/>и завязывайте дружбу в поместье.'
    ],
    'txt8': 'Картинки из игры',
    'SwiperList': [
      'games/en/wutu_1.jpg',
      'games/en/wutu_2.jpg',
      'games/en/wutu_3.jpg',
      'games/en/wutu_4.jpg',
      'games/en/wutu_5.jpg',
    ],
    'txt9': 'Загляните в <br/>наши соцсети',
    'txt10': 'Скачайте сейчас!'
  },
  // 商品列表
  'product': {
    'Coins': 'Монеты',
    'STORE': 'МАГАЗИ',
    'Extra': 'Доп.',
    'Title1': 'Кто заслуживает<br/> подарка больше вас?',
    'Title2': 'Побалуйте себя потрясающими <br/>наборами для счастья<br/> и веселья!',
    'LOGIN': 'ВОЙТИ',
    'LOGOUT': 'Выйти',
    'UID': 'UID',
    'ShowDetails': 'Подробнее',
    'PaymentMethods': 'Способ оплаты',
    'MyAccount': 'Мой аккаунт',
    'UserID': 'UserID',
    'PurchaseProduct': 'Купить продукт',
    'Price': 'Цена',
    'total': 'Всего',
    'BuyNow': 'Купить',
    'ItemInBundle': 'Предметы набора',
    'PleaseSelectProduct': 'Пожалуйста, выберите товар, который вы хотите купить.',
    'PleaseSelectPay': 'Пожалуйста, выберите способ оплаты.'
  },
  'productMobile': {
    'STORE': 'МАГАЗИ',
    'Extra': 'Доп.',
    'Title1': 'Кто заслуживает<br/> подарка больше вас?',
    'Title2': 'Побалуйте себя <br/>потрясающими наборами <br/>для счастья и веселья!',
    'LOGIN': 'ВОЙТИ',
    'LOGOUT': 'Выйти',
    'UID': 'UID',
    'ShowDetails': 'Подробнее',
    'PaymentMethods': 'Способ оплаты',
    'BuyPopTitle': 'Подтвердить платежный заказ',
    'MyAccount': 'Мой аккаунт',
    'UserID': 'UserID',
    'PurchaseProduct': 'Купить продукт',
    'Price': 'Цена',
    'total': 'Всего',
    'BuyNow': 'Купить',
    'ItemInBundle': 'Предметы набора',
    'PleaseSelectProduct': 'Пожалуйста, выберите товар, который вы хотите купить.',
    'PleaseSelectPay': 'Пожалуйста, выберите способ оплаты.'
  },
  // deletion 页
  'deletion': {
    'Title': 'Удаление игровой учетной записи',
    'Logout': 'Выйти',
    'Content': 'Авторизуйтесь с помощью учетной записи Google, связанной с вашей учетной записью в игре.',
    'AUTHORIZE': 'Авторизация',
  },
  'deletionMobile': {
    'Title': 'Удаление игровой учетной записи',
    'Logout': 'Выйти',
    'Content': 'Авторизуйтесь с помощью учетной записи Google,<br/>связанной с вашей учетной записью в игре.',
    'AUTHORIZE': 'Авторизация',
  },
  // authSuccess 页
  'authSuccess': {
    'content': [
      'В соответствии с Общим регламентом о защите данных вы имеете право удалить все свои личные данные, используемые в нашем приложении и сервисах партнеров (платежи за покупки, просмотры рекламы в приложении и т. д.). Помните, что если решите удалить данные, учетная запись пользователя будет удалена без возможности восстановления. Однако можно будет начать игру заново с 1-го уровня.',
      'Удаление учетной записи можно отменить в течение 15 дней.',
      'Для этого откройте игру и нажмите кнопку «Отмена». Обратите внимание, что по истечении 15 дней мы не сможем отменить удаление, а ваш прогресс в игре будет невозможно восстановить. Также учтите, что в течение 15 дней в период удаления вы не сможете перезапустить игру с самого начала.',
    ],
    'DeleteGameAccount': 'Удалить игровой аккаунт',
    'DelTitle': 'Подтвердить',
    'DelContent': 'Вы точно хотите удалить свою игровую учетную запись?',
    'DelCancel': 'Отмена',
    'DelConfirm': 'Да',
    'DelCodeLabel': [
      'Игровой аккаунт для этого пользователя не найден.', // 0
      'успешн! ', // 1
      'Игровой аккаунт отправлен на удаление.' // 2
    ]
  },
  // Del Success 页
  'delSuccess': [
    'Ваш запрос на удаление данных успешно зарегистрирован.',
    'Игровая учетная запись будет удалена через 15 дней с момента запроса.'
  ]
}
