export default {
    'imageAlt': 'Garden Affairs',
    // 全局error Code Map
    'errorCode': {
        'default': 'Kesalahan server, silakan coba lagi nanti.',
        20014: 'User ID tidak ada, mohon masukkan kembali.', //  游戏用户ID错误
        10002: 'Kesalahan server, silakan coba lagi nanti. (10002)',// 参数无效
        10008: 'Kesalahan server, silakan coba lagi nanti. (10008)', // 数据未找到
        30008: 'Kesalahan server, silakan coba lagi nanti. (30008)', // 商品不存在
        30007: 'Kesalahan server, silakan coba lagi nanti. (30007)', // 商品币种类型不正确
        10001: 'Kesalahan server, silakan coba lagi nanti. (10001)', // 服务器开小差
        10000: 'Kesalahan jaringan, silakan periksa pengaturan jaringan Anda.', // 网络异常"
    },
    // code map 下单时 映射弹窗 内容
    'codeMap': {
        // 成功弹窗
        'SuccessTitle': 'Pembelian Berhasil!',
        'SuccessInfo': 'Terima kasih atas pembeliannya! <br/>Mulai ulang game dan cek kotak masuk kamu untuk mengumpulkan item kamu. <br/>(Sudut kanan atas layar -> Kotak Masuk)',
        'SuccessBtn': 'Hebat',
        // 失败弹窗
        'ErrorTitle': 'Pembelian Gagal!',
        'ErrorInfo': 'Pembelian gagal! Mohon coba lagi.',
        'ErrorBtn': 'OK'
    },
    // 登录弹窗
    'login': {
        'title1': "Toko Alice",
        'title2': "Game",
        'Or': 'atau',
        'placeholder': 'Masukkan ID pengguna',
        'LogIn': 'Log in',
        'SignInWithFacebook': 'Masuk dengan Facebook',
        'SignInWithGoogle': 'Masuk dengan Google',
        'LoginSuccess': 'Login berhasil, Anda bisa mulai berbelanja sekarang!',
        'LoginFailed': 'Login gagal! Periksa apakah ID pengguna yang Anda masukkan benar.',
        'LogOut': 'Keluar berhasil!',
    },
    // cookies tips
    'cookiesTip': {
        'Content': 'Situs web ini menggunakan cookies untuk memastikan kamu  <br/>memperoleh pengalaman terbaik di situs web kami.',
        'Accept': 'Accept'
    },
    // header部分
    'header': {
        'Menu': ['TOKO', 'Game', 'Dukungan', 'Ikuti Kami'],
        'MenuPath': ['/store', '/games', 'Support', '/follow-us'],
        'FollowUs': ['Facebook', 'Instagram', 'Twitter'],
    },
    // 底部 footer
    'footer': {
        'Menu': [
            'Dukungan',
            'Ketentuan Layanan',
            'Kebijakan Privasi',
            'Penghapusan Data'
        ],
        'MenuPath': [
            'Support',
            'https://policy.judiangames.com/termsofservice-id.html',
            'https://policy.judiangames.com/privacypolicy-id-index.html',
            '/deletion'
        ],
        'LanguagePlaceholder': 'language',
    },
    // homePage
    'homePage': {
        'txt': [
            'Bongkar Rahasia, Rancang Impianmu—Mulai Petualanganmu!',
            'Masuk ke dalam Garden Affairs, game puzzle match-3 gratis yang telah <br/>mengambil hati jutaan pemain.'
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Bergabunglah dengan jutaan pemain di Garden Affairs, <br/>game puzzle gratis! Ayo bersama Alice ciptakan kehidupan <br/>impian Anda!'
        ],
        'Custom': [
            'Garden Affairs penuh dengan fitur-fitur baru yang menarik, termasuk:',
            'Ekspresikan Gayamu',
            'Pilih furnitur dan dekorasi sesuai gayamu. <br/>Sesuaikan setiap sudut dan ubah rumah tua menjadi menakjubkan.',
            'Cerita Menarik',
            'Jelajahi rahasia yang tersembunyi di taman. <br/>Ikuti setiap tikungan dalam plot. Buat teman baru.',
            'Level Seru',
            'Kuasai level menantang dengan kombinasi booster dan elemen game unik. <br/>Rasakan keseruan menyelesaikan puzzle dan mencocok-cocokkan.'
        ],
        'GamePictures': 'Gambar Game'
    },
    'homePageMobile': {
        'txt': [
            'Bongkar Rahasia, <br/>Rancang Impianmu<br/>—Mulai Petualanganmu!',
            'Masuk ke dalam Garden Affairs, <br/>game puzzle match-3 gratis yang telah <br/>mengambil hati jutaan pemain. '
        ],
        'GardenAffairs': [
            'Garden Affairs',
            'Bergabunglah dengan jutaan pemain di <br/>Garden Affairs, game puzzle gratis! <br/>Ayo bersama Alice ciptakan kehidupan <br/>impian Anda!'
        ],
        'Custom': [
            'Garden Affairs penuh dengan fitur-fitur <br/>baru yang menarik, termasuk:',
            'Ekspresikan Gayamu',
            'Pilih furnitur dan dekorasi sesuai gayamu. <br/>Sesuaikan setiap sudut dan ubah rumah tua <br/>menjadi menakjubkan.',
            'Cerita Menarik',
            'Jelajahi rahasia yang tersembunyi di taman. <br/>Ikuti setiap tikungan dalam plot. Buat teman baru.',
            'Level Seru',
            'Kuasai level menantang dengan kombinasi booster <br/>dan elemen game unik. Rasakan keseruan <br/>menyelesaikan puzzle dan mencocok-cocokkan.'
        ],
        'GamePictures': 'Gambar Game'
    },
    // GAME页 pc端
    'games': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Bongkar Rahasia, Rancang Impianmu<br/>—Mulai Petualanganmu!',
        'extra': '5% Tambahan',
        'extraFontSize': '1.2',
        'store': 'TOKO',
        'storeFontSize': '2.2',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Masuk ke dalam Garden Affairs, <br/>game puzzle match-3 gratis yang telah <br/>mengambil hati jutaan pemain.',
        'txt3': 'Kamu baru saja mewarisi properti besar yang tidak terawat. <br/>Dengan bantuan Alice, teman masa kecilmu, <br/>rumahmu berubah menjadi impianmu.',
        'txt4': [
            'Bertemulah dengan Jantung Matchview',
            'Berkenalanlah dengan penduduk lokal yang memberi kehidupan kepada Matchview:'
        ],
        'PeopleName': ['Alice', 'Joey', 'Stefano', 'Nellie', 'Taylor', 'Alan'],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people2.png',
            'games/people3.png',
            'games/people4.png',
            'games/people5.png',
            'games/people6.png',
        ],
        'PeopleDesc': [
            'Desainer membuat rumah <br/>impian anda menjadi kenyataan',
            'Florist tampan yang<br/> gemar bunga bermekaran',
            'Si koki menyembunyikan <br/>lebih dari bahan makanan',
            'Desainer selebriti berhati peduli',
            'Pengrajin kayu sekaligus <br/>sahabat terbaik di kota',
            'Tukang kebun dengan<br/> sejarah panjang'
        ],
        'txt5': [
            'Visual yang Indah',
            'Nikmati pengalaman sinematik pembuka yang cantik dan penuh detail yang teliti <br/>di tiap pilihan desain.'
        ],
        'txt6': [
            'Ekspresikan Gayamu',
            'Pilih furnitur dan dekorasi sesuai gayamu. <br/>Sesuaikan setiap sudut dan ubah rumah tua menjadi menakjubkan.',
            'Level Seru',
            'Kuasai level menantang dengan kombinasi booster dan elemen game unik. <br/>Rasakan keseruan menyelesaikan puzzle dan mencocok-cocokkan.'
        ],
        'txt7': [
            'Temukan dan <br/>Hubungkan',
            'Bongkar misteri di dalam taman yang luas. <br/>Hubungkan dengan tetangga dan bangun <br/>pertemanan di luar dinding rumah.'
        ],
        'txt8': 'Gambar Game',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Lihat Media Sosial kami',
        'txt10': 'Unduh sekarang!',
    },
    // GAME页 移动端
    'gamesMobile': {
        'GardenAffairs': 'games/logo.png',
        'txt1': 'Bongkar Rahasia, Rancang Impianmu<br/>—Mulai Petualanganmu!',
        'extra': '5% Tambahan',
        'extraFontSize': '1.2',
        'store': 'TOKO',
        'PeopleIcon': 'games/icon.png',
        'txt2': 'Masuk ke dalam Garden Affairs, <br/>game puzzle match-3 gratis <br/>yang telah mengambil hati <br/>jutaan pemain.',
        'txt3': 'Kamu baru saja mewarisi properti besar yang <br/>tidak terawat. Dengan bantuan Alice, <br/>teman masa kecilmu, rumahmu berubah <br/>menjadi impianmu.',
        'txt4': [
            'Bertemulah dengan <br/>Jantung Matchview',
            'Berkenalanlah dengan penduduk lokal <br/>yang memberi kehidupan kepada Matchview:'
        ],
        'PeopleAvatar': [
            'games/people1.png',
            'games/people4.png',
            'games/people2.png',
            'games/people5.png',
            'games/people3.png',
            'games/people6.png',
        ],
        'PeopleName': [
            'Alice',
            'Nellie',
            'Joey',
            'Taylor',
            'Stefano',
            'Alan'
        ],
        'PeopleDesc': [
            'Desainer membuat rumah impian<br/> anda menjadi kenyataan',
            'Desainer selebriti berhati peduli',
            'Florist tampan yang<br/> gemar bunga bermekaran',
            'Pengrajin kayu sekaligus <br/>sahabat terbaik di kota',
            'Si koki menyembunyikan <br/>lebih dari bahan makanan',
            'Tukang kebun dengan<br/> sejarah panjang'
        ],
        'txt5': [
            'Visual yang Indah',
            'Nikmati pengalaman sinematik pembuka <br/>yang cantik dan penuh detail yang teliti <br/>di tiap pilihan desain.'
        ],
        'txt6': [
            'Ekspresikan Gayamu',
            'Pilih furnitur dan dekorasi sesuai <br/>gayamu. Sesuaikan setiap sudut dan <br/>ubah rumah tua menjadi menakjubkan.',
            'Level Seru',
            'Kuasai level menantang dengan kombinasi <br/>booster dan elemen game unik. Rasakan <br/>keseruan menyelesaikan puzzle dan <br/>mencocok-cocokkan.'
        ],
        'txt7': [
            'Temukan dan Hubungkan',
            'Bongkar misteri di dalam taman yang luas. <br/>Hubungkan dengan tetangga dan bangun <br/>pertemanan di luar dinding rumah.'
        ],
        'txt8': 'Gambar Game',
        'SwiperList': [
            'games/en/wutu_1.jpg',
            'games/en/wutu_2.jpg',
            'games/en/wutu_3.jpg',
            'games/en/wutu_4.jpg',
            'games/en/wutu_5.jpg',
        ],
        'txt9': 'Lihat Media Sosial <br/>kami',
        'txt10': 'Unduh sekarang!'
    },
    // 商品列表
    'product': {
        'Coins': 'Koin',
        'STORE': 'TOKO',
        'Extra': 'Tambahan',
        'Title1': 'Siapa yang Pantas<br/> Selain Kamu?',
        'Title2': 'Nikmati paket-paket hemat kami demi <br/>keseruan dan kegembiraan puncak!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Keluar',
        'UID': 'UID',
        'ShowDetails': 'Tampilkan detail',
        'PaymentMethods': 'Metode Pembayaran',
        'MyAccount': 'Akun Saya',
        'UserID': 'UserID',
        'PurchaseProduct': 'Beli Produk',
        'Price': 'Harga',
        'total': 'Total',
        'BuyNow': 'Beli sekarang',
        'ItemInBundle': 'Bundle Item',
        'PleaseSelectProduct': 'Silakan pilih item yang ingin Anda beli.',
        'PleaseSelectPay': 'Silakan pilih metode pembayaran Anda.'
    },
    'productMobile': {
        'STORE': 'TOKO',
        'Extra': 'Tambahan',
        'Title1': 'Siapa yang Pantas<br/> Selain Kamu?',
        'Title2': 'Nikmati paket-paket <br/>hemat kami demi keseruan <br/>dan kegembiraan puncak!',
        'LOGIN': 'LOGIN',
        'LOGOUT': 'Keluar',
        'UID': 'UID',
        'ShowDetails': 'Tampilkan detail',
        'PaymentMethods': 'Metode Pembayaran',
        'BuyPopTitle': 'Konfirmasi pesanan pembayaran',
        'MyAccount': 'Akun Saya',
        'UserID': 'UserID',
        'PurchaseProduct': 'Beli Produk',
        'Price': 'Harga',
        'total': 'Total',
        'BuyNow': 'Beli sekarang',
        'ItemInBundle': 'Bundle Item',
        'PleaseSelectProduct': 'Silakan pilih item yang ingin Anda beli.',
        'PleaseSelectPay': 'Silakan pilih metode pembayaran Anda.'
    },
    // deletion 页
    'deletion': {
        'Title': 'Penghapusan Akun Game',
        'Logout': 'Keluar',
        'Content': 'Mohon lakukan otorisasi menggunakan Akun Google yang terhubung dengan akun game kamu.',
        'AUTHORIZE': 'Otorisasi',
    },
    'deletionMobile': {
        'Title': 'Penghapusan Akun Game',
        'Logout': 'Keluar',
        'Content': 'Mohon lakukan otorisasi menggunakan Akun Google <br/>yang terhubung dengan akun game kamu.',
        'AUTHORIZE': 'Otorisasi',
    },
    // authSuccess 页
    'authSuccess': {
        'content': [
            'Berdasarkan Peraturan Perlindungan Data Umum (GDPR), Anda berhak menghapus semua data pribadi Anda yang digunakan dalam aplikasi kami dan layanan mitra kami (pembayaran pembelian, tampilan iklan dalam aplikasi, dll.). Harap diingat bahwa apabila Anda memutuskan untuk menghapus data, akun pengguna akan dihapus dan tidak dapat dipulihkan. Namun, Anda dapat memulai game lagi dari Level 1.',
            'Penghapusan akun dapat dibatalkan dalam 15 hari.',
            'Agar penghapusan dibatalkan, buka game lalu ketuk tombol Batalkan. Mohon diingat bahwa setelah 15 hari, kami tidak dapat menarik penghapusan tersebut dan game Anda tidak bisa dikembalikan. Mohon pertimbangkan juga bahwa dalam periode penghapusan 15 hari, Anda tidak dapat memulai ulang game dari awal.'
        ],
        'DeleteGameAccount': 'Hapus Akun Game',
        'DelTitle': 'Setuju',
        'DelContent': 'Kamu yakin kamu mau menghapus akun game kamu?',
        'DelCancel': 'Batalkan',
        'DelConfirm': 'Ya, saya yakin',
        'DelCodeLabel': [
            'Akun game untuk pengguna ini tidak ditemukan.', // 0
            'Success! ', // 1
            'Akun game telah diajukan untuk dihapus.' // 2
        ]
    },
    // Del Success 页
    'delSuccess': [
        'Permintaan penghapusan data kamu telah berhasil diterima.',
        'Aku game akan dihapus dalam 15 hari sejak permintaan kamu.'
    ]
}
