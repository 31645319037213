import CryptoJS from 'crypto-js';

// let str = "123456"
// //md5加密
// console.log(CryptoJS.MD5(str).toString())
// var str2 = CryptoJS.enc.Utf8.parse(str);
// //可以把字符串转成UTF-8编码
//
// //base编码
// var base64str = CryptoJS.enc.Base64.stringify(str2)
// console.log(base64str)
//
// //base64解码
// var words = CryptoJS.enc.Base64.parse(base64str)
// var parseStr = CryptoJS.enc.Utf8.stringify(words)
// console.log(parseStr)
//
// //SHA256
// var enSHA256 = CryptoJS.SHA256(str).toString()
// console.log(enSHA256)
//
// //SHA512
// var enSHA512 = CryptoJS.SHA512(str).toString()
// console.log(enSHA512)

//对称加密
//aes对称加密

// ⼗六位⼗六进制数作为密钥
const key = CryptoJS.enc.Utf8.parse("17702cbfcc85f77a6f26d5638f5a3286")
// ⼗六位⼗六进制数作为密钥偏移量
const iv = CryptoJS.enc.Utf8.parse("1234567890abcdef")

// 解密方法
function Decrypt(cipherText) {
  const bytes = CryptoJS.AES.decrypt(cipherText, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  // 解密后将字符串转换为对象
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  // 尝试将解密后的字符串转换为对象
  try {
    return JSON.parse(decryptedString);
  } catch (error) {
    // 如果无法解析为对象，则返回原始字符串
    return decryptedString;
  }
}

//加密方法
function Encrypt(value) {
  let srcs = CryptoJS.enc.Utf8.parse(value);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  let encryptedHexStr = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return encryptedHexStr.toString();
}

export {
  Decrypt,
  Encrypt
}



